import { TextField, Tab, FormLabel, FormControlLabel, Box, Checkbox, FormGroup, FormControl } from "@mui/material"
import { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { TabContext, TabPanel, TabList } from '@mui/lab';
import FormSelect from "./FormSelect"
import { college_list, district_list, dseRank, education_level_list, education_status, exam_al_elective, exam_ce_elective, exam_dse_elective, expect_tutor_fee, genderList, gradeList, medium_choice, oldRank, tutorWays, tutor_exp } from "../Data"
import FormSelectGroup from "./FormSelectGroup"
import FormFileUpload from "./FormFileUpload"
import { auth, updateMemberInfo } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";


const textFieldStyles = ({
    textField: {

    },
    inputWrapper: {
        borderRadius: 20,
        bgcolor: '#000',

    },
    input: {
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16,

    },
    label: {
        top: -6
    }


});

const tabPanelStyle = ({
    backgroundColor: '#f4eadc',
    borderRadius: '20px',
    mb: 3
})
export default function StepForm({ userInfo }) {

    const [formInfo, setFormInfo] = useState([])
    const [detail, setDetail] = useState([])
    const getData = () => {
        fetch('form.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {

                return response.json();
            })
            .then(function (myJson) {

                setFormInfo(myJson)

            });
    }



    const [LoginEmail, setLoginEmail] = useState(null)
    const [LoginEmailValid, setLoginEmailValid] = useState(null)
    const [formStep, setFormStep] = useState(0)
    const getUserInfoInput = (result) => {
        setDetail(result)
    }

    useEffect(() => {
        getData()
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setLoginEmail(currentUser.email)
                setLoginEmailValid(currentUser.emailVerified)




            }


        })
        getUserInfoInput(userInfo)

    }, [])




    const { register, unregister, control, trigger, setError, formState: { errors, isSubmitSuccessful, isValid }, handleSubmit, watch, reset, resetField } = useForm({
        mode: 'all',
    });

    const onSubmit = async (data, e) => {
        e.preventDefault()
        Object.keys(data).forEach(function (key) {
            if (data[key] === undefined) {
                data[key] = ""
            }

        });
        const noError = await trigger();
        if (noError) {
            updateMemberInfo(data, auth?.currentUser.uid).then((result) => {
                if (result) {
                    completeFormStep()
                }
            })


        }
    }
    const completeFormStep = () => {
        setFormStep(step => step + 1)
    }
    const backFormStep = () => {
        setFormStep(step => step - 1)
    }
    const renderNextButton = () => {
        if (formStep > 3) {
            return undefined
        } else if (formStep === 3) {
            return (
                <button
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    className="mt-6 bg-[#053e68] text-white text-xl rounded-xl px-6 py-3 w-fit disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    儲存
                </button>
            )
        } else {
            return (
                <button
                    disabled={!isValid}
                    onClick={async () => {
                        const noError = await trigger();
                        if (noError) {
                            completeFormStep()

                        }

                    }}
                    type="button"
                    className="mt-6 bg-dark-blue text-xl text-white rounded-xl px-6 py-3 w-fit disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                    下一頁
                </button>
            )

        }
    }
    const renderPrevButton = () => {
        if (formStep > 3) {
            return undefined
        } else if (formStep === 0) {
            return (
                <button
                    /* onClick={completeFormStep} */
                    type="button"
                    disabled
                    className="mt-6 bg-[#053e68] text-xl text-white rounded-xl px-6 py-3 w-fit disabled:bg-gray-400 disabled:cursor-not-allowed disabled:opacity-25"
                >
                    上一頁
                </button>
            )
        } else {
            return (
                <button
                    onClick={backFormStep}
                    type="button"
                    className="mt-6 bg-white text-[#053e68] border-[#053e68]   border-2 text-xl rounded-xl px-6 py-3 w-fit disabled:bg-gray-400"
                >
                    上一頁
                </button>
            )

        }
    }

    const [tabValue, setTabValue] = useState('1');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };





    return (

        <div className="max-w-5xl mx-auto">



            <div className=" w-full mt-12 mb-24 px-[20px] mx-auto overflow-hidden z-10 min-h-[700px] ">

                        <div className='py-8 border-b-2 border-grey'>
                        <h2 className='font-normal text-3xl'>更改導師資料</h2>
                    </div>
                {formStep < 4 && (
                    <div className="">
                        <div className="mx-auto md:p-5 py-8 border-b-2 border-grey md:px-4 ">
                            <p className="text-base md:text-lg mb-3">為增加成功受聘的機會, 請盡量填寫下列各項資料以及確保所有資料準確。</p>
                            <p className="text-base md:text-lg">本平台十分注重個人私隱保護, 所有已登記的資料將會絕對保密。</p>
                            <span className="rounded-xl bg-[#053e68] px-4 py-2 mb-4  text-white text-xl inline-block font-bold">
                                {formStep === 0 && ('個人資料')}
                                {formStep === 1 && ('學術履歷')}
                                {formStep === 2 && ('補習資料')}
                                {formStep === 3 && ('其他資料')}
                            </span>
                            <span className="text-lg text-red font-bold ml-4">*為必須填寫</span>
                        </div>
                    </div>
                )}


                <div className="md:px-10 py-8 pb-4 bg-white">
                    <form noValidate method="post">
                        {formStep === 0 && (
                            <section>
                                <div className="grid md:grid-cols-2 gap-5">
                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <TextField placeholder={formInfo[0]?.placeholder } label={formInfo[0]?.label + `(必填)`} variant="outlined" defaultValue={detail[0]?.name_zh} size="small" fullWidth {...register(`${formInfo[0]?.name}`, formInfo[0]?.validation)} error={!!errors?.name_zh} helperText={errors?.name_zh ? errors.name_zh.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </div>

                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <TextField placeholder={formInfo[1]?.placeholder} label={formInfo[1]?.label + `(必填)`} variant="outlined" defaultValue={detail[0]?.name_en} size="small" fullWidth {...register(`${formInfo[1]?.name}`, formInfo[1]?.validation)} error={!!errors?.name_en} helperText={errors?.name_en ? errors.name_en.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={genderList}
                                            label={formInfo[2]?.label + `(必填)`}
                                            defaultValue={detail[0]?.gender}
                                            inputProps={register(`${formInfo[2]?.name}`, formInfo[2]?.validation)}
                                            value={watch(`${formInfo[2]?.name}`)}
                                            error={errors.gender}
                                            helperText={errors.gender?.message}
                                        />
                                    </div>

                                    <div>
                                        <TextField placeholder={formInfo[3]?.placeholder} label={formInfo[3]?.label} variant="outlined" defaultValue={detail[0]?.nickname} size="small" fullWidth {...register(`${formInfo[3]?.name}`)} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />

                                    </div>

                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <TextField placeholder={formInfo[4]?.placeholder} label={formInfo[4]?.label + `(必填)`} variant="outlined" defaultValue={detail[0]?.phone} size="small" fullWidth {...register(`${formInfo[4]?.name}`, { required: "需填寫", pattern: { value: /^[0-9]+$/, message: 'Number only' } })} error={!!errors?.phone} helperText={errors?.phone ? errors.phone.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />


                                    </div>
                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <FormSelectGroup
                                            itemList={district_list}
                                            name={`${formInfo[13]?.livingDistrict.name}`}
                                            control={control}
                                            defaultValue={detail[0]?.living_district}
                                            rules={formInfo[13]?.livingDistrict.validation}
                                            label={formInfo[13]?.livingDistrict.type + `(必填)`}

                                        />
                                    </div>





                                </div>
                                <div className="my-5">
                                    <TextField placeholder={formInfo[5]?.placeholder} label={formInfo[5]?.label} focused={true} variant="outlined" size="small" fullWidth {...register(`${formInfo[5]?.name}`)} value={LoginEmail} autoFocus={true} InputProps={{
                                        readOnly: true,
                                        style: textFieldStyles.inputWrapper
                                    }} inputProps={{ style: textFieldStyles.input }} />
                                    {auth?.currentUser?.emailVerified ?
                                        <div className="flex items-center mt-2 ml-3">
                                            <p className="mb-0 mr-1">已驗證</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#4ade80">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        :
                                        <div>
                                            <div className="flex items-center mt-2 ml-3">
                                                <p className="mb-0 mr-1">未驗證</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#E11D00">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>

                                            </div>
                                            <p className="mb-0 ml-3 text-red">請檢查郵箱驗證，包括垃圾郵件</p>
                                        </div>

                                    }
                                    <TextField placeholder={formInfo[5]?.placeholder} sx={{ display: "none" }} label={formInfo[5]?.label} focused={true} variant="outlined" size="small" fullWidth {...register(`validate`, {
                                        required: !LoginEmailValid,

                                    })} defaultValue="" autoFocus={false} InputProps={{
                                        readOnly: true,

                                    }} inputProps={{ style: textFieldStyles.input }} />
                                    {errors.validate && <p>{errors.validate.message}</p>}

                                </div>
                                <div className="flex justify-between items-center">
                                    <div className="w-32 sm:w-96 text-lg">個人頭像 (導師可選擇上載以增加曝光率)</div>
                                    <FormFileUpload
                                        type="member_icon"
                                        control={control}
                                        title="個人頭像 (導師可選擇上載以增加曝光率)"
                                        name={`${formInfo[13]?.uploadIcon.name}`}

                                    />
                                </div>


                            </section>
                        )}
                        {formStep === 1 && (
                            <section>

                                <TabContext value={tabValue}>
                                    <Box >
                                        <TabList selectionFollowsFocus={true} onChange={handleTabChange} centered>
                                            <Tab label="HKDSE" value="1" />
                                            <Tab label="HKCEE" value="2" />
                                            <Tab label="HKAL" value="3" />
                                            <Tab label="其他" value="4" />
                                        </TabList>
                                    </Box>
                                    <span className="text-xl font-bold my-4 block">公開試成績</span>
                                    <TabPanel value="1" sx={tabPanelStyle}>
                                        <div className="grid sm:grid-cols-2 gap-6">
                                            <div className="flex">
                                                <div className="flex w-full flex-col">
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[6]?.label + `(必填)`}
                                                            inputProps={register(`${formInfo[6]?.name_dse}`, formInfo[6]?.validation)}
                                                            value={watch(`${formInfo[6]?.name_dse}`)}
                                                            defaultValue={detail[0]?.ch_dse_total}
                                                            error={errors.ch_dse_total}
                                                            helperText={errors.ch_dse_total?.message}
                                                        />
                                                    </div>
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[6]?.subItem[0].label + `(必填)`}
                                                            inputProps={register(`${formInfo[6]?.subItem[0].name_dse}`, formInfo[6]?.validation)}
                                                            value={watch(`${formInfo[6]?.subItem[0].name_dse}`)}

                                                            error={errors.ch_dse_reading}
                                                            helperText={errors.ch_dse_reading?.message}
                                                        />
                                                    </div>
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[6]?.subItem[1].label + `(必填)`}
                                                            inputProps={register(`${formInfo[6]?.subItem[1].name_dse}`, formInfo[6]?.validation)}
                                                            value={watch(`${formInfo[6]?.subItem[1].name_dse}`)}

                                                            error={errors.ch_dse_writing}
                                                            helperText={errors.ch_dse_writing?.message}
                                                        />
                                                    </div>
                                                    <div className="relative">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[6]?.subItem[2].label + `(必填)`}
                                                            inputProps={register(`${formInfo[6]?.subItem[2].name_dse}`, formInfo[6]?.validation)}
                                                            value={watch(`${formInfo[6]?.subItem[2].name_dse}`)}

                                                            error={errors.ch_dse_listening}
                                                            helperText={errors.ch_dse_listening?.message}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[7]?.label + `(必填)`}
                                                            inputProps={register(`${formInfo[7]?.name_dse}`, formInfo[7]?.validation)}
                                                            defaultValue={detail[0]?.en_dse_total}
                                                            value={watch(`${formInfo[7]?.name_dse}`)}
                                                            error={errors.en_dse_total}
                                                            helperText={errors.en_dse_total?.message}
                                                        />
                                                    </div>
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[7]?.subItem[0].label + `(必填)`}
                                                            inputProps={register(`${formInfo[7]?.subItem[0].name_dse}`, formInfo[7]?.validation)}
                                                            value={watch(`${formInfo[7]?.subItem[0].name_dse}`)}

                                                            error={errors.en_dse_reading}
                                                            helperText={errors.en_dse_reading?.message}
                                                        />
                                                    </div>
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[7]?.subItem[1].label + `(必填)`}
                                                            inputProps={register(`${formInfo[7]?.subItem[1].name_dse}`, formInfo[7]?.validation)}
                                                            value={watch(`${formInfo[7]?.subItem[1].name_dse}`)}

                                                            error={errors.en_dse_writing}
                                                            helperText={errors.en_dse_listening?.message}
                                                        />
                                                    </div>
                                                    <div className="relative pb-4">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[7]?.subItem[2].label + `(必填)`}
                                                            inputProps={register(`${formInfo[7]?.subItem[2].name_dse}`, formInfo[7]?.validation)}
                                                            value={watch(`${formInfo[7]?.subItem[2].name_dse}`)}

                                                            error={errors.en_dse_listening}
                                                            helperText={errors.en_dse_listening?.message}
                                                        />
                                                    </div>
                                                    <div className="relative">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[7]?.subItem[3].label + `(必填)`}
                                                            inputProps={register(`${formInfo[7]?.subItem[3].name_dse}`, formInfo[7]?.validation)}
                                                            value={watch(`${formInfo[7]?.subItem[3].name_dse}`)}

                                                            error={errors.en_dse_listening}
                                                            helperText={errors.en_dse_listening?.message}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <div className="relative">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[8]?.label + `(必填)`}
                                                            inputProps={register(`${formInfo[8]?.name_dse}`, formInfo[8]?.validation)}
                                                            defaultValue={detail[0]?.math_dse_total}
                                                            value={watch(`${formInfo[8]?.name_dse}`)}
                                                            error={errors.math_dse_total}
                                                            helperText={errors.math_dse_total?.message}
                                                        />
                                                    </div>


                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <div className="relative">
                                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                        <FormSelect
                                                            itemList={dseRank}
                                                            label={formInfo[9]?.label + `(必填)`}
                                                            inputProps={register(`${formInfo[9]?.name_dse}`, formInfo[9]?.validation)}
                                                            defaultValue={detail[0]?.liberal_dse_total}
                                                            value={watch(`${formInfo[9]?.name_dse}`)}
                                                            error={errors.liberal_dse_total}
                                                            helperText={errors.liberal_dse_total?.message}
                                                        />
                                                    </div>


                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_dse_elective}
                                                        name="elective_1_dse"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_1_dse}
                                                        label="選修1(如適用)"
                                                    />
                                                </div>
                                                <div className="w-3/4">
                                                <FormSelect
                                                    itemList={dseRank}
                                                    label="請選擇成績"
                                                    defaultValue={detail[0]?.elective_1_dse_grade}
                                                    value={watch(`elective_1_dse_grade`)}
                                                    inputProps={register('elective_1_dse_grade')}
                                                    error={errors.elective_1_dse_grade}
                                                    helperText={errors.elective_1_dse_grade?.message}
                                                />
                                                </div>


                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_dse_elective}
                                                        name="elective_2_dse"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_2_dse}
                                                        label="選修2(如適用)"
                                                    />
                                                </div>
                                                <div className="w-3/4">
                                                <FormSelect
                                                    itemList={dseRank}
                                                    label="請選擇成績"
                                                    defaultValue={detail[0]?.elective_2_dse_grade}
                                                    inputProps={register('elective_2_dse_grade')}
                                                    value={watch(`elective_2_dse_grade`)}
                                                    error={errors.elective_2_dse_grade}
                                                    helperText={errors.elective_2_dse_grade?.message}
                                                />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_dse_elective}
                                                        name="elective_3_dse"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_3_dse}
                                                        label="選修3(如適用)"
                                                    />
                                                </div>
                                                <div className="w-3/4">
                                                <FormSelect
                                                    itemList={dseRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_3_dse_grade')}
                                                    value={watch(`elective_3_dse_grade`)}
                                                    defaultValue={detail[0]?.elective_3_dse_grade}
                                                    error={errors.elective_3_dse_grade}
                                                    helperText={errors.elective_3_dse_grade?.message}
                                                />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_dse_elective}
                                                        name="elective_4_dse"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_4_dse}
                                                        label="選修4(如適用)"
                                                    />
                                                </div>
                                                <div className="w-3/4">
                                                <FormSelect
                                                    itemList={dseRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_4_dse_grade')}
                                                    value={watch(`elective_4_dse_grade`)}
                                                    defaultValue={detail[0]?.elective_4_dse_grade}
                                                    error={errors.elective_4_dse_grade}
                                                    helperText={errors.elective_4_dse_grade?.message}
                                                />
                                                </div>
                                            </div>
                                            {/* {electiveList.dse} */}
                                        </div>


                                    </TabPanel>
                                    <TabPanel value="2" sx={tabPanelStyle}>
                                        <div className="grid sm:grid-cols-2 gap-6">

                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <FormSelect
                                                        itemList={oldRank}
                                                        label={formInfo[6]?.label}
                                                        inputProps={register(`${formInfo[6]?.name_ce}`)}
                                                        value={watch(`${formInfo[6]?.name_ce}`)}
                                                        defaultValue={detail[0]?.ch_ce_total}
                                                        error={errors.ch_ce_total}
                                                        helperText={errors.ch_ce_total?.message}
                                                    />

                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <FormSelect
                                                        itemList={oldRank}
                                                        label={formInfo[7]?.label}
                                                        inputProps={register(`${formInfo[7]?.name_ce}`)}
                                                        value={watch(`${formInfo[7]?.name_ce}`)}
                                                        defaultValue={detail[0]?.en_ce_total}
                                                        error={errors.en_ce_total}
                                                        helperText={errors.en_ce_total?.message}
                                                    />

                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <FormSelect
                                                        itemList={oldRank}
                                                        label={formInfo[8]?.label}
                                                        inputProps={register(`${formInfo[8]?.name_ce}`)}
                                                        value={watch(`${formInfo[8]?.name_ce}`)}
                                                        defaultValue={detail[0]?.math_ce_total}
                                                        error={errors.math_ce_total}
                                                        helperText={errors.math_ce_total?.message}
                                                    />

                                                </div>

                                            </div>

                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_ce_elective}
                                                        name="elective_1_ce"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_1_ce}
                                                        label="選修1(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_1_ce_grade')}
                                                    value={watch(`elective_1_ce_grade`)}
                                                    defaultValue={detail[0]?.elective_1_ce_grade}
                                                    error={errors.elective_1_ce_grade}
                                                    helperText={errors.elective_1_ce_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_ce_elective}
                                                        name="elective_2_ce"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_2_ce}
                                                        label="選修2(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_2_ce_grade')}
                                                    value={watch(`elective_2_ce_grade`)}
                                                    defaultValue={detail[0]?.elective_2_ce_grade}
                                                    error={errors.elective_2_ce_grade}
                                                    helperText={errors.elective_2_ce_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_ce_elective}
                                                        name="elective_3_ce"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_3_ce}
                                                        label="選修3(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    defaultValue={detail[0]?.elective_3_ce_grade}
                                                    inputProps={register('elective_3_ce_grade')}
                                                    value={watch(`elective_3_ce_grade`)}
                                                    error={errors.elective_3_ce_grade}
                                                    helperText={errors.elective_3_ce_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_ce_elective}
                                                        name="elective_4_ce"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_4_ce}
                                                        label="選修4(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_4_ce_grade')}
                                                    value={watch(`elective_4_ce_grade`)}
                                                    defaultValue={detail[0]?.elective_4_ce_grade}
                                                    error={errors.elective_4_ce_grade}
                                                    helperText={errors.elective_4_ce_grade?.message}
                                                />

                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3" sx={tabPanelStyle}>
                                        <div className="grid sm:grid-cols-2 gap-6">

                                            <div className="flex">
                                                <div className="flex flex-col w-full">
                                                    <FormSelect
                                                        itemList={oldRank}
                                                        label={formInfo[6]?.label}
                                                        defaultValue={detail[0]?.ch_al_total}
                                                        inputProps={register(`${formInfo[6]?.name_al}`)}
                                                        value={watch(`${formInfo[6]?.name_al}`)}
                                                        error={errors.ch_al_total}
                                                        helperText={errors.ch_al_total?.message}
                                                    />

                                                </div>

                                            </div>
                                            <div className="flex">

                                                <div className="flex flex-col w-full">
                                                    <FormSelect
                                                        itemList={oldRank}
                                                        label={formInfo[7]?.label}
                                                        defaultValue={detail[0]?.en_al_total}
                                                        inputProps={register(`${formInfo[7]?.name_al}`)}
                                                        value={watch(`${formInfo[7]?.name_al}`)}
                                                        error={errors.en_al_total}
                                                        helperText={errors.en_al_total?.message}
                                                    />

                                                </div>

                                            </div>


                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_al_elective}
                                                        name="elective_1_al"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_1_al}
                                                        label="選修1(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_1_al_grade')}
                                                    value={watch(`elective_1_al_grade`)}
                                                    defaultValue={detail[0]?.elective_1_al_grade}
                                                    error={errors.elective_1_al_grade}
                                                    helperText={errors.elective_1_al_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_al_elective}
                                                        name="elective_2_al"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_2_al}
                                                        label="選修2(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_2_al_grade')}
                                                    value={watch(`elective_2_al_grade`)}
                                                    defaultValue={detail[0]?.elective_2_al_grade}
                                                    error={errors.elective_2_al_grade}
                                                    helperText={errors.elective_2_al_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_al_elective}
                                                        name="elective_3_al"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_3_al}
                                                        label="選修3(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_3_al_grade')}
                                                    value={watch(`elective_3_al_grade`)}
                                                    defaultValue={detail[0]?.elective_3_al_grade}
                                                    error={errors.elective_3_al_grade}
                                                    helperText={errors.elective_3_al_grade?.message}
                                                />

                                            </div>
                                            <div className="flex">
                                                <div className="w-full">
                                                    <FormSelectGroup
                                                        itemList={exam_al_elective}
                                                        name="elective_4_al"
                                                        control={control}
                                                        defaultValue={detail[0]?.elective_4_al}
                                                        label="選修4(如適用)"
                                                    />
                                                </div>

                                                <FormSelect
                                                    itemList={oldRank}
                                                    label="請選擇成績"
                                                    inputProps={register('elective_4_al_grade')}
                                                    value={watch(`elective_4_al_grade`)}
                                                    defaultValue={detail[0]?.elective_4_al_grade}
                                                    error={errors.elective_4_al_grade}
                                                    helperText={errors.elective_4_al_grade?.message}
                                                />

                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="4" sx={{ px: '0' }}>
                                        <div className="bg-[#e1ecf6] py-[8px] px-[12px] mb-[10px]">{formInfo[10]?.field1.type}</div>
                                        <TextField placeholder={formInfo[10]?.field1.placeholder} label={formInfo[10]?.field1.label} defaultValue={detail[0]?.other_exam} variant="outlined" size="small" fullWidth {...register(`${formInfo[10]?.field1.name}`)} multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />

                                        <div className="bg-[#e1ecf6] py-[8px] px-[12px] mt-[30px] mb-[10px]">{formInfo[10]?.field2.type}</div>

                                        <TextField placeholder={formInfo[10]?.field2.placeholder} label={formInfo[10]?.field2.label} variant="outlined" defaultValue={detail[0]?.instrument} size="small" fullWidth {...register(`${formInfo[10]?.field2.name}`)} multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />

                                        <div className="bg-[#e1ecf6] py-[8px] px-[12px] mt-[30px] mb-[10px]">{formInfo[10]?.field3.type}</div>
                                        <TextField placeholder={formInfo[10]?.field3.placeholder} label={formInfo[10]?.field3.label} defaultValue={detail[0]?.language} variant="outlined" size="small" fullWidth {...register(`${formInfo[10]?.field3.name}`)} multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />

                                        <div className="bg-[#e1ecf6] py-[8px] px-[12px] mt-[30px] mb-[10px]">{formInfo[10]?.field4.type}</div>

                                        <TextField placeholder={formInfo[10]?.field4.placeholder} label={formInfo[10]?.field4.label} defaultValue={detail[0]?.other_qualification} variant="outlined" size="small" fullWidth {...register(`${formInfo[10]?.field4.name}`)} multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </TabPanel>
                                </TabContext>

                                <div className="flex justify-between items-center mb-5">
                                    <div className="w-36 sm:w-auto block sm:flex items-center">
                                        <div className="rounded-lg border-2 border-dark-blue text-dark-blue text-lg font-bold p-3 py-1 mr-3">
                                            公開試證書
                                        </div>
                                        <div className="text-red mr-2">未驗證</div>

                                        <div className="mb-0 text-sm">(上傳即同意提供給家長查看,個人資料會被遮蔽)</div>
                                    </div>
                                    <FormFileUpload
                                        type="dse_prove"
                                        control={control}
                                        name={`${formInfo[11]?.field1.name}`}
                                        title={formInfo[11]?.field1.type}
                                    />
                                </div>
                                <div className="grid sm:grid-cols-2 gap-6">


                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={education_level_list}
                                            label={formInfo[11]?.field2.label+ `(必填)`}
                                            inputProps={register(`${formInfo[11]?.field2.name}`, formInfo[11]?.field2.validation)}
                                            value={watch(`${formInfo[11]?.field2.name}`)}
                                            defaultValue={detail[0]?.education_level}
                                            error={errors.education_level}
                                            helperText={errors.education_level?.message}
                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={college_list}
                                            label={formInfo[11]?.field3.label+ `(必填)`}
                                            defaultValue={detail[0]?.college}
                                            inputProps={register(`${formInfo[11]?.field3.name}`, formInfo[11]?.field3.validation)}
                                            value={watch(`${formInfo[11]?.field3.name}`)}
                                            error={errors.college}
                                            helperText={errors.college?.message}
                                        />
                                    </div>

                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={education_status}
                                            label={formInfo[11]?.field4.label+ `(必填)`}
                                            defaultValue={detail[0]?.education_status}
                                            inputProps={register(`${formInfo[11]?.field4.name}`, formInfo[11]?.field4.validation)}
                                            value={watch(`${formInfo[11]?.field4.name}`)}
                                            error={errors.education_status}
                                            helperText={errors.education_status?.message}
                                        />
                                    </div>

                                    <div className="relative">
                                        <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                        <TextField placeholder={formInfo[11]?.field5.placeholder} label={formInfo[11]?.field5.label+ `(必填)`} defaultValue={detail[0]?.major_subject} variant="outlined" size="small" fullWidth {...register(`${formInfo[11]?.field5.name}`, formInfo[11]?.field5.validation)} error={!!errors?.major_subject} helperText={errors?.major_subject ? errors.major_subject.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </div>




                                </div>



                            </section>
                        )}

                        {formStep === 2 && (
                            <section>
                                <div className="grid md:grid-cols-2 gap-6 mb-8">
                                    <div className="flex">

                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelectGroup
                                                    itemList={district_list}
                                                    name={`${formInfo[13]?.availiableDistrict.name}`}
                                                    control={control}
                                                    defaultValue={detail[0]?.available_district}
                                                    rules={formInfo[13]?.availiableDistrict.validation}
                                                    isMulti="true"
                                                    label={formInfo[13]?.availiableDistrict.label+ `(必填)`}
                                                />
                                            </div>



                                        </div>

                                    </div>
                                    <div className="flex">

                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelectGroup
                                                    itemList={gradeList}
                                                    name={`${formInfo[13]?.availiableGrades.name}`}
                                                    control={control}
                                                    defaultValue={detail[0]?.tutor_target}
                                                    rules={formInfo[13]?.availiableGrades.validation}
                                                    isMulti="true"
                                                    label={formInfo[13]?.availiableGrades.label+ `(必填)`}
                                                />
                                            </div>



                                        </div>

                                    </div>
                                    <div className="flex">

                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelectGroup
                                                    itemList={exam_dse_elective}
                                                    name={`${formInfo[13]?.availiableSubjects.name}`}
                                                    control={control}
                                                    defaultValue={detail[0]?.available_subjects}
                                                    rules={formInfo[13]?.availiableSubjects.validation}
                                                    isMulti="true"
                                                    label={formInfo[13]?.availiableSubjects.label+ `(必填)`}
                                                />
                                            </div>



                                        </div>

                                    </div>
                                    <div className="flex">

                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelectGroup
                                                    itemList={tutorWays}
                                                    name={`${formInfo[13]?.tutorWays.name}`}
                                                    control={control}
                                                    defaultValue={detail[0]?.tutor_class_type}
                                                    rules={formInfo[13]?.tutorWays.validation}
                                                    isMulti="true"
                                                    label={formInfo[13]?.tutorWays.label+ `(必填)`}
                                                />
                                            </div>



                                        </div>

                                    </div>
                                    <div className="flex">
                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelect
                                                    itemList={tutor_exp}
                                                    label={formInfo[13]?.tutorExp.label+ `(必填)`}
                                                    inputProps={register(`${formInfo[13]?.tutorExp.name}`, formInfo[13]?.tutorExp.validation)}
                                                    value={watch(`${formInfo[13]?.tutorExp.name}`)}
                                                    defaultValue={detail[0]?.tutor_exp}
                                                />
                                            </div>



                                        </div>



                                    </div>
                                    <div className="flex">
                                        <div className="flex flex-col w-full">
                                            <div className="relative">
                                                <div className="absolute -top-2 -left-1 text-red font-bold text-lg">*</div>
                                                <FormSelect
                                                    itemList={expect_tutor_fee}
                                                    label={formInfo[13]?.expectTutorFee.label+ `(必填)`}
                                                    inputProps={register(`${formInfo[13]?.expectTutorFee.name } `, formInfo[13]?.expectTutorFee.validation)}
                                                    value={watch(`${formInfo[13]?.expectTutorFee?.name}`)}

                                                />
                                            </div>



                                        </div>



                                    </div>

                                </div>
                                <h2 className="font-semibold text-xl mb-3">自我簡介(可增加曝光率,提高配對機率)</h2>
                                <div className="relative">

                                    <Controller
                                        name={`${formInfo[12]?.name}`}
                                        control={control}
                                        defaultValue={detail[0]?.description}
                                        /* rules={formInfo[12]?.validation} */
                                        render={
                                            ({ field }) =>
                                                <TextField {...field} placeholder={formInfo[12]?.placeholder} label={formInfo[12]?.label} defaultValue={detail[0]?.description} variant="outlined" size="small" fullWidth multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        }
                                    />
                                </div>

                                {/* {errors.description?.type === 'required' && (
                                    <div className="text-red text-sm text-left mt-1">請輸入</div>
                                )}
                                {errors.description?.type === 'minLength' && (
                                    <div className="text-red text-sm text-left mt-1">請輸入不少於5個字</div>
                                )}
                                {errors.description?.type === 'maxLength' && (
                                    <div className="text-red text-sm text-left mt-1">請輸入不多於50個字</div>
                                )} */}
                            </section>
                        )}

                        {formStep === 3 && (
                            <div>
                                <div className="grid md:grid-cols-2 gap-6">
                                    <div className="flex">
                                        <TextField placeholder={formInfo[13]?.introducerName.placeholder} label={formInfo[13]?.introducerName.label} defaultValue={detail[0]?.introducer_name} variant="outlined" size="small" fullWidth {...register(`${formInfo[13]?.introducerName.name}`)} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </div>

                                    <div className="flex">
                                        <TextField placeholder={formInfo[13]?.introducerPhone.placeholder} label={formInfo[13]?.introducerPhone.label} defaultValue={detail[0]?.introducer_phone} variant="outlined" size="small" fullWidth {...register(`${formInfo[13]?.introducerPhone.name}`, { pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Number only' } })} error={!!errors?.introducer_phone} helperText={errors?.introducer_phone ? errors.introducer_phone.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                    </div>
                                </div>

                                <div className="block sm:flex mt-[50px]">
                                    <div className="relative mr-3">
                                        <div className="absolute -top-2 -left-3 text-red font-bold text-lg">*</div>
                                        <FormLabel sx={{ marginRight: 3 }}>{formInfo[13]?.mediumChoices.type}</FormLabel>
                                    </div>

                                    <FormControl fullWidth>
                                        <FormGroup row={true}>
                                            {medium_choice.map((item, index) => {
                                                return (
                                                    <FormControlLabel
                                                        control={<Checkbox name="checkbox"  {...register(`${formInfo[13]?.mediumChoices.name}`, formInfo[13]?.mediumChoices.validation)} /* defaultChecked={(watch('medium_choices'))?.includes(item)  } */  />}

                                                        value={item}
                                                        label={item}
                                                        error={errors.medium_choices}
                                                        helperText={errors.medium_choices?.message}
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                    </FormControl>

                                </div>
                                <div className="mt-[50px]">
                                    <p>條款及細則</p>
                                    <div className="max-h-[200px] overflow-scroll p-5 rounded-[3px] mb-[20px] border-[#e3e3e3] border-[1px] bg-[#f5f5f5]">

                                        歡迎您登記為Tutor Plus（香港首席導師協會）的導師，登記前請各導師參閱「導師收費」的說明，以下乃 閣下須就使用Tutor Plus（香港首席導師協會）網站及其提供之服務需要遵守之條款和規則。當完成導師登記後，即表示你同意接受這些條款和規則之約束。
                                        <br />
                                        「本站」是指Tutor Plus（香港首席導師協會）網站及同系之網站。 本站可隨時修訂合約內容，修訂條款會在此網站內張貼。新修訂條款將於張貼七天後自動生效。因此， 閣下應該定期檢視Tutor Plus（香港首席導師協會）網站留意條款所作出之更改。倘於該等條款作出任何更改後，閣下仍繼續使用本網站或本網站提供的服務，則表示接納該等更改。
                                        <br />
                                        1. 定義
                                        <br />
                                        1.1 詞彙之定義
                                        <br />
                                        以下詞彙在此合約中具下列定義：
                                        <br />
                                        「個案確認(confirm)／接受個案」指此網站職員如在電話對話中表明「個案已經confirm／確認」，如導師沒有 提出反對，從電話結束以後的一刻開始，個案已被視為「已確認／confirm／已接受／已接納」
                                        <br />
                                        「全科」指該級別之全級常規科目，小學科目則為「中文、英文、數學、常識、普通話」，中學科目則需按個別學校情況而定，另功課輔導即等同全科補習
                                        <br />
                                        「導師／家長/ 香港導師會三方關係 」指導師與家長之關係為服務合作關係，其間只為獨立合作聘約，並不存在僱傭關係；導師與此網站亦只存在服務提供者與服務使用者之關係，雙方沒有任何僱傭關係 ，服務亦與「職業介紹」完全無關
                                        <br />
                                        「會員合約 」指此網站的任何一部分所示指引、指南、內容、條款、說明，均為會員合約之一部分，此網站有權依據合約收取費用，成功登記即代表導師同意所有條款
                                        <br />
                                        「導師登記」指會員導師於此網站上進行登記，法律上與實體合約無異
                                        <br />
                                        「欺騙行為」指會員導師虛報資料登記／行騙／與學生或家長串聯行騙
                                        <br />
                                        1.2 標題
                                        <br />
                                        此合約中的標題只為方便閱讀而設，並非此合約內容的一部份，絕不會影響對此合約或其任何部份的解釋。
                                        <br />
                                        2. 提供服務
                                        <br />
                                        2.1 使用
                                        <br />
                                        任何人士欲以導師身份使用此網站的服務，必須先登記為本站導師會員。
                                        <br />
                                        2.2 登記成為本站導師
                                        <br />
                                        用戶向Tutor Plus（香港首席導師協會）提供所有需要資料及網上填妥表格，按下「確認」鍵後即成為本站導師會員。Tutor Plus（香港首席導師協會）保留拒絕任何導師會員申請而不作任何解釋的權利。
                                        <br />
                                        3. 登記成為本站導師資格
                                        <br />
                                        年滿十五歲或以上，具中四或以上程度學歷之自然人且擁有簽訂合約的心智和法律能力之人士，以及獲其憲章、附例及公司組織章程大綱批准其與Tutor Plus（香港首席導師協會）簽訂此合約的公司，方可申請成為會員。Tutor Plus（香港首席導師協會）保留拒絕任何人士申請成為會員或終止任何人士之會員資格而不作任何解釋之權利。不符合上述資格者不得使用服務。
                                        <br />
                                        4. 收費
                                        <br />
                                        4.1 導師收費說明
                                        <br />
                                        使用服務中收費（「收費」）列於「導師收費說明」（收費說明）中，而該收費說明乃巳納入本合約中，並視為構成合約的一部份。Tutor Plus（香港首席導師協會）保留隨時更改有關收費的權利，而新收費將於指定日期生效（生效日期將為網頁刊登經修訂收費表後的七天內）。所有用戶必須於每次使用服務前查核收費表。
                                        <br />
                                        4.2 收費模式
                                        <br />
                                        有關導師收費會由家長／學生直接向本平台支付。家長／學生在協議堂數完成後不會直接向導師支付學費，而會在協議堂數完成後48小時內，直接向本平台支付協議堂數之學費以作導師收費用途，因此導師毋需額外支付其他費用。在本平台確認收到有關費用後，往後家長／學生會直接向導師支付學費，不會再經由本平台負責。
                                        <br />
                                        4.3 貨幣
                                        <br />
                                        除非另有所指，所有收費均以港幣為單位。
                                        <br />
                                        4.4 其他雜費
                                        <br />
                                        所有適用之稅項，以及在閣下使用Tutor Plus（香港首席導師協會）所帶來的收入而衍生之稅務情況，一概由閣下負責。Tutor Plus（香港首席導師協會）可全權酌情決定隨時增加、刪減或更改部份或全部服務。
                                        <br />
                                        4.5 特別情況
                                        <br />
                                        假如在首兩周任何原因而沒有完成應有補習日數，亦會根據原定補習日數為準則收取費用。 註：原定補習日數是首兩周內應有之所有堂數，學生或導師因任何理由缺課導致首月上課日數減少，例如公眾假期，病假等，本公司將會順延繳費日期而不會減收費用。相反，學生與導師增加堂數的話，也不會增加費用。導師與本公司職員電話確認接受補習個案後，因任何事故決定取消補習，手續費為$200，亦必須於補習首堂開始48小時之前致電我們確認取消個案，否則本公司將收取原定之行政費。若家長於首兩星期內 *因私人理由而* 取消補習，我們會向導師收取已上課堂一半學費。 如有任何由家長提出之堂數更改或個案取消，必須於 *家長/學生提出後* 24小時內以電話聯絡我們，若未經本公司確認，導師即使已經離職，但仍雖繳付有關之行政費。
                                        <br />
                                        4.6 轉介第三方導師／學生資料
                                        <br />
                                        任何一方取消補習後，除非經Tutor Plus（香港首席導師協會）再次介紹，否則導師必須承諾在六個月之內不能向該學生提供相同或相似的補習服務。導師亦不能轉介該學生之資料予第三者及轉介其他導師之資料予該學生，若發現導師未有繳付行政費用而為該學生提供補習服務及上述轉介資料之情況，本公司必從正常之法律途徑追討其行政費及附加費$2000，另外亦保留收取文件送 遞、賬單、蒐集證據之所有費用。無論於Tutor Plus（香港首席導師協會）提供介紹前或後而經其他任何途徑獲得該學生之介紹或與學生直接接洽情況下，導師亦不能為該學生提供補習服務。而於任何情況下，若導師為該學生提供相同或相 似的補習服務而沒有經書面通知本公司，即時需要繳付上述之所有費用。 註: 該學生是指該學生及其家庭之任何學生或該學生相同地址之任何學生
                                        <br />
                                        當你表示同意及遵守這份合約，這代表你能夠理解這份合約的內容及有正常的判斷力去作出決定。若果你不同意本公司之守則，請不要登記使用該項服務。本站有權停止提供服務給任何會員。
                                        <br />
                                        4.7 相同學生分科補習的處理方法
                                        <br />
                                        如學生在找Tutor Plus（香港首席導師協會）時已表明需要分科補習時，行政費將會以科目為單位去收取。即如果學生需要兩科補習，將會分別收取各兩星期學費為行政費。如導師先接受了一科科目補習，期後再接受另一科科目補習，不論是經本公司或學生直接確認上課時間，也視作同意本公司之行政費用收取，導師必需繳付每一科個別補習之全數行政費。
                                        <br />
                                        5. Tutor Plus（香港首席導師協會）職責
                                        <br />
                                        5.1 Tutor Plus（香港首席導師協會）僅為教育平台
                                        <br />
                                        Tutor Plus（香港首席導師協會）作為一個教育平台，其責任僅為導師及家長或學生進行相互配對，以使導師可以向家長或學生提供補習服務, 其間只為獨立合作聘約，並不存在僱傭關係；導師與Tutor Plus（香港首席導師協會）亦只存在服務提供者與服務使用者之關係，雙方沒有任何僱傭關係，因此Tutor Plus（香港首席導師協會）有權依本公司與導師所訂定之「導師條款」收取行政費用，成功登記即代表導師同意條款全部內容。而「導師條款」所訂之內容等同Tutor Plus（香港首席導師協會）與導師之間所定之合約，而Tutor Plus（香港首席導師協會）網站內所列出的任何規則或指示都屬於會員合約的一部份，本站導師亦須格守。
                                        <br />
                                        5.2 不擔保家長或學生對導師要求之合理性
                                        <br />
                                        Tutor Plus（香港首席導師協會）明確聲明並沒有預先與家長或學生進行面談或家訪，而且家長或學生對導師在道德、學術、修養、教學質素及質量等之合法性、合適性或其他合理性和原則，並不在Tutor Plus（香港首席導師協會） 控制範圍之內。
                                        <br />
                                        5.3 不擔保導師人生安全
                                        <br />
                                        Tutor Plus（香港首席導師協會）明確聲明導師的人生安全或其他個人利益得失，亦非Tutor Plus（香港首席導師協會）控制範圍之內。Tutor Plus（香港首席導師協會）概不接納或承擔有關導師和家長或學生之間之任何法律責任。
                                        <br />
                                        5.3 概不擔保家長或學生長期使用導師
                                        <br />
                                        Tutor Plus（香港首席導師協會）概不擔保家長或學生長期使用導師，導師如使用此網站服務，須自行評估風險或其他個人利益得失。所有人士必須留意與未屆合法年齡人士、提供虛假理由或沒有訂約的行為能力的人士進行交易所涉及的風險。
                                        <br />
                                        5.4 概不擔保家長或學生或導師身份
                                        <br />
                                        Tutor Plus（香港首席導師協會）不能亦不會確認每一家長或學生或導師之身份。因此，閣下接受hkta所示與家長或學生進行交易時，務須小心防範欺騙行為。Tutor Plus（香港首席導師協會）概不就任何用戶之任何行為或遺漏負責。
                                        <br />
                                        5.5 概不擔保資料的準確性
                                        <br />
                                        Tutor Plus（香港首席導師協會）不能控制家長或學生透過本網站所提供之資料，有關保證項目已列於收費說明或服務簡介中，如前所述，該收費說明乃巳納入本合約中，並視為構成合約的一部份。因此，Tutor Plus（香港首席導師協會）概不擔保任何人士透過使用服務所一提供之任何資料之準確性。
                                        <br />
                                        5.7 免除Tutor Plus（香港首席導師協會)系統服務器故障與漏洞的相關責任
                                        <br />
                                        本公司提供之服務涉及到互聯網服務和線上系統，可能會受到各個環節不穩定因素的影響。因此服務存在不可抗力、系統不穩定、電腦病毒或黑客攻擊以及其他任何技術操作、互聯網絡等原因造成的服務中斷或不能滿足用戶要求的風險。有關系統服務器故障和漏洞已造成或可能造成之潛在損失，Tutor Plus（香港首席導師協會) 一概毋須負上有關賠償和法律責任。
                                        <br />
                                        5.8 免除Tutor Plus（香港首席導師協會) 的法律責任
                                        <br />
                                        若家長或學生與導師之間出現任何糾紛或因使用服務而引起任何糾紛，與該等糾紛有關或因而引起的所有責任、索償、索求、賠償金（實質或間接），不論屬何性質，已知及未知、懷疑與否、已公開及未公開者，Tutor Plus（香港首席導師協會) 一概毋須負上有關法律責任。
                                        <br />
                                        5.9 Tutor Plus（香港首席導師協會) 的支出
                                        <br />
                                        倘Tutor Plus（香港首席導師協會) 因應閣下之要求或閣下所涉及的任何訴訟中作出的指令（不論通過傳票或任何具司法管轄權的法院所頒法令，包括遵守任何披露文件之法令或出席法院作證）而引起 的所有費用，包括Tutor Plus（香港首席導師協會) 尋求法律或其他專業意見或陳述時的支出，一概由閣下負責。（在所有此等情況下，一切費用按實際損失計算）
                                        <br />
                                        6. 有關補習中介服務之條款
                                        <br />
                                        6.1 導師之基本責任
                                        <br />
                                        倘閣下，作為導師，確認接納Tutor Plus（香港首席導師協會)之個案後，必須盡力依照Tutor Plus（香港首席導師協會) 所發出的指示，上課務必準時到達及依時完成，不可在沒有家長或學生同意的情況下刪減時間。你同意授權本站利用你的「登記資料」作為本站的宣傳之用，而不受任何法例所限制。你亦同意本站在必要時候局部或全部顯示「登記資料」，而不受任何版權法例及個人資料（私隱）條例所限制。你同意不會使用任何工具、軟件或程序干擾本站伺服器的正常運作，更不會故意、有計劃地令本站的系統負荷過重。你不可以傳播任何電腦病毒或有破壞性的程式給本站和本站的所有會員。你同意不會追究因本站系統失靈而造成的任何損失。本站會盡力維持系統的可靠性，並保持良好的效率。你不可利用任何本站的服務作出非法或不道德的行為。
                                        <br />
                                        6.2 導師提供正確資料之責任
                                        <br />
                                        導師如沒有提供正確資料，或因沒有提供正確資料而導師個案服務被終止，Tutor Plus（香港首席導師協會) 可以拒絕為其提供服務，亦有可以收取所衍生之行政費用。
                                        <br />
                                        6.3 導師撤回個案所衍生之責任
                                        <br />
                                        在個案已確認後，如導師決定取消向相關家長或學生提供補習，導師絕不能把其家長或學生之資料轉交予任何人仕。
                                        <br />
                                        6.4 導師進行欺騙行為之責任
                                        <br />
                                        導師如被證實，或Tutor Plus（香港首席導師協會) 認為或發現導師極有可能與家長或學生串聯進行任何欺騙行為，包括導師聲稱終止但仍然為家長或學生提供有關服務、導師和家長其任何一方要求對方配合及向Tutor Plus（香港首席導師協會）謊報服務經已終止，Tutor Plus（香港首席導師協會）會立即終止有關導師的資格，並要求有關人士於七天內清繳有關全部費用；即使其後家長或學生決定終止有關人士之服務，導師仍須繳付全數服務費用。Tutor Plus（香港首席導師協會) 會保留一切刑事及民事追究之權利，並不會容忍任何欺騙行為出現。
                                        <br />
                                        7. Tutor Circle 的權利
                                        <br />
                                        7.1 暫停或終止用戶資格的權利
                                        <br />
                                        倘發現用戶出現以下行為，則Tutor Plus（香港首席導師協會）有權暫停或終止用戶資格而毋須支付賠償：
                                        <br />
                                        (a) 違反本合約所列出之條件及條款，或
                                        <br />
                                        (b) 透過定罪、和解、保險或託管調查或其他方式而從事與此網站有關之欺騙行為。
                                        <br />
                                        7.2 處理由用戶提供的資料的權利
                                        <br />
                                        Tutor Plus（香港首席導師協會）擁有絕對及不受約束之權力，拒絕或刪除用戶所提供之任何資料，而毋須向任何一方發出通知或作出賠償。在無損此項權利下，倘導師所提供之任何資料一旦刊登或 繼續刊登，會導致Tutor Plus（香港首席導師協會）須為第三者負上任何法律責任，或令Tutor Plus（香港首席導師協會）觸犯任何有效司法管轄區的任何法律或規則或第三者的權益，則Tutor Plus（香港首席導師協會）擁有權拒絕或修改任何所提供之資料。
                                        <br />
                                        7.3 撤回提供中介服務之權利
                                        <br />
                                        Tutor Plus（香港首席導師協會）擁有絕對及不受約束的權利，撤回任何服務條件而不向導師發出任何通知或作出任何賠償。在無減損此權利下，倘發生下列情況，Tutor Plus（香港首席導師協會）有權撤回服務：
                                        <br />
                                        (a) 該導師提供有個人資料有極大可能不全正確；
                                        <br />
                                        (b) 導師被發現違反本合約列出的條件及條款，或其戶口被Tutor Plus（香港首席導師協會）根據此合約的條款勒令暫停；
                                        <br />
                                        (c) Tutor Plus（香港首席導師協會）因任何原因無法核實或證實導師或家長或學生提供的任何資料。
                                        <br />
                                        7.4 拒絕提供服務之權利
                                        <br />
                                        Tutor Plus（香港首席導師協會）有權停止提供服務給任何會員並保留拒絕任何人士申請成為會員或終止任何人士之會員資格而不作任何解釋之權利。
                                        <br />
                                        7.5 更改有關收費的權利
                                        <br />
                                        使用服務中收費列於「導師收費說明」中，而該收費說明乃已納入本合約中，視為構成合約的一部份。Tutor Plus（香港首席導師協會）保留隨時更改有關收費的權利。所 有用戶必須於每次使用服務前查核收費表
                                        <br />
                                        7.6 增刪本合約的權利
                                        <br />
                                        Tutor Plus（香港首席導師協會）保留隨時增刪本合約的一切權利，而不會事先通知會員；會員必須隨時翻看本合約資料，以確保清楚最新的會員約束條款。
                                        <br />
                                        8. 不保證
                                        <br />
                                        8.1 按「現狀」基準提供服務
                                        <br />
                                        Tutor Plus（香港首席導師協會）按「現狀」基準提供此網站服務。Tutor Plus（香港首席導師協會）之僅有責任已在此合約中列出。除在此合約中清楚列出之事項外，Tutor Plus（香港首席導師協會）不會作出任何明確或隱含之保證或條件。Tutor Plus（香港首席導師協會）不能保證此網站所設之功能以及所提供之服務將會不受干擾或無誤，或問題將獲修正或此網站或其伺服器將無病毒或其他有害元素。Tutor Plus（香港首席導師協會）並不就用戶或用戶使用此網站之後果作保證或任何聲明。
                                        <br />
                                        8.2 提供付款機制所屬責任
                                        <br />
                                        提供予用戶之付款機制以及輔助性處理付款設施，乃僅便於用戶使用，而Tutor Plus（香港首席導師協會）概不就該等設施提供任何明確或隱含之保證。在任何情況下，Tutor Plus（香港首席導師協會）概不就用戶來自或因任何誤差及／或錯誤及／或錯誤扣除或計入信用卡賬戶之錯誤月結單以及任何故障、失靈、中斷、停工時間、干擾、計算錯誤、延誤、失準、損 失或數據訛誤或任何其他有關付款渠道設施失靈而蒙受或產生之任何損失、賠償、成本或開支負上責任。
                                        <br />
                                        9. 不法行為之追究
                                        <br />
                                        9.1 虛報資料／行騙責任
                                        <br />
                                        跟據香港特別行政區法例對綱上行業／電貿（e-commerce）的規管及法律行駛，網際商業與其他實業皆受到相同法律之約束及保障。因此，導師於網上登記，在法律上與實體合約無異，導師虛報資料登記／行騙／與學生或家長串聯行騙，將會被視作犯罪行為；Tutor Plus（香港首席導師協會）決不會因為導師年輕或對法律認知不足而不作追究。本公司絕不容忍導師此等違法行為，導師登記時的IP及日後每一個動作的IP亦將被紀錄下 來，Tutor Plus（香港首席導師協會）亦會在有需要時把曾與導師交談的內容錄音備份，有需要時會以該等資訊舉證。導師請勿因一時貪念致前途盡毀。
                                        <br />
                                        9.2 口頭承諾責任
                                        <br />
                                        本公司職員與導師間之談話間，其對口頭承諾之任何部分，均可視為一個正式協議或合約，並具法律效力，本公司亦可能把部分或全部之對話錄音。
                                        <br />
                                        9.3「登記資料」責任
                                        <br />
                                        你必須對你的「登記資料」負責，而本站只是一個被動的資料收集及傳送中介。「登記資料」必須合乎道德，並須保證沒有觸犯任何法律，不含有暴力、色情成份及粗言穢語，以及不可包含任何侵犯私隱權或帶有歧視的成份的內容。若本站認為你的「登記資料」會對任何人或機構造成負面影響，本站將會保留採取法律行動的權 利。本站更有權停止顯示你的「登記資料」予所有有權限之查閱者（包括其本人）和因此而永久刪除你的會籍。
                                        <br />
                                        10. 其他事項
                                        <br />
                                        10.1 用戶不得：
                                        <br />
                                        (a) 使用任何裝置、軟件或程式干預或企圖干預此網站之正常運作或任何在此網站進行的交易；
                                        <br />
                                        (b) 進行任何令此網站的基礎設施承受不合理或不合比例的巨大負荷的活動；
                                        <br />
                                        (c) 把其密碼向第三者透露或與第三者使用同一密碼或將其密碼用作未經許可的用途。
                                        <br />
                                        10.2 遵守法律
                                        <br />
                                        所有用戶在使用此網站服務時，須遵守所有適用法律、規程、條例和守則。
                                        <br />
                                        10.3 概無代理關係
                                        <br />
                                        本文件任何內容均不會令Tutor Plus（香港首席導師協會）與任何人士或公司或其他使用此網站或服務之用戶之間構成或建立任何代理、合伙、聯營、僱員／僱主或專營商／專營承辦商之關係。
                                        <br />
                                        10.4 通知
                                        <br />
                                        除非特別聲明，所有通知均以電子郵件或電話形式發出，及就用戶而言，倘通知已透過用戶於登記時向Tutor Plus（香港首席導師協會）提供的電郵地址或其他由該用戶指定的電郵地址或透過在此網站張貼通知，則已被視作發出通知。Tutor Plus（香港首席導師協會）有權以各種形式，包括電郵、郵寄等會訊，如希望不再接收，必須之前電郵通知我們，我們在處理會回覆確實；日後會員如需要再次接收，必須再行電郵通知Tutor Plus（香港首席導師協會）。Tutor Plus（香港首席導師協會）擁有這份會員合約的最終解釋權。
                                        <br />
                                        10.5 條款的獨立性
                                        <br />
                                        倘根據任何具司法管轄權的法院所實際引用的適用法例，此合約中的任何條款屬違法或無法實施，此等條款將與合約分開及視作無效論，而絕不影響此合約的其餘條款。然而，在有關法律容許的情況下，若有關法律條文可獲寬免，各方須同意其獲寬免，以便此合約的有關條款能夠有效、具約束力及得以實施。
                                        <br />
                                        10.6 網頁之完整性
                                        <br />
                                        倘閣下利用Tutor Plus（香港首席導師協會）系統或任何程序或伺服器漏動，竊取任何資料，閣下須負上一切法律責任。
                                        <br />
                                        10.7 報行管轄的法律及司法機構
                                        <br />
                                        (a) 本合約受香港特別行政區法律管轄，並依香港特別行政區法律詮釋。
                                        <br />
                                        (b) 與此網站之使用有關或因此而起的任何法律行動或訴訟，任何有關一方須願受香港特別行政區法院的司法管轄權管轄；任何一方不得以有關訴訟被帶到任何不相稱的法院為理由而提出反對。
                                        <br />
                                        (c) 上文所述願受香港特別行政區法院司法管轄權的管轄並不會影響其他任何一方於任何司法範圍提起訴訟的權利；而於任何司法範圍提起訴訟亦不表示其他任何一方不能於其他任何司法範圍提起訴訟。

                                        <br />
                                        <br />

                                    </div>
                                    <div className="relative">
                                        <div className="absolute -top-0 -left-3 text-red font-bold text-lg">*</div>
                                        <FormControlLabel
                                            control={<Checkbox name="checkbox" {...register(`${formInfo[13]?.agreeTnc.name}`, formInfo[13]?.agreeTnc.validation)} defaultChecked={watch(`${formInfo[13]?.agreeTnc.name}`)} />}
                                            value={true}
                                            label={formInfo[13]?.agreeTnc.label}
                                            error={errors.agree_tnc}
                                            helperText={errors.agree_tnc?.message}
                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute -top-0 -left-3 text-red font-bold text-lg">*</div>
                                        <FormControlLabel
                                            control={<Checkbox name="checkbox" {...register(`${formInfo[13]?.agreeFee.name}`, formInfo[13]?.agreeFee.validation)}  defaultChecked={watch(`${formInfo[13]?.agreeFee.name}`)} />}
                                            value={true}
                                            label={formInfo[13]?.agreeFee.label}
                                            error={errors.agree_fee}
                                            helperText={errors.agree_fee?.message}
                                        />
                                    </div>


                                </div>
                            </div>

                        )}

                        {formStep === 4 && (
                            <section className="text-center">
                                <h2 className="font-semibold text-3xl mt-8 mb-8">會員資料已更新</h2>
                                <a className="mx-auto block w-fit text-white font-bold bg-[#053e68] rounded-[10px] p-4 mt-8 mb-3" href="/" >回到主頁</a>

                            </section>
                        )}
                        <div className="flex justify-between">
                            {renderPrevButton()}
                            {renderNextButton()}
                        </div>





                    </form>
                </div>
                {/*<pre className="text-black mt-3">{JSON.stringify(watch(), null, 2)}</pre>*/}
            </div>

        </div>

    )
}

