import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: "loginItems",
    initialState: {
        auth : null
        
    },
    reducers: {
        putAuthState: (state, action) => {
            state.auth = action.payload.auth;
        },
        
    }
})

export const {putAuthState} = loginSlice.actions;

export const selectItems = (state) => state.loginItems.loginItems;

export default loginSlice.reducer;