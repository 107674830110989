import React, { useState, useEffect } from 'react'
import Accordion from '../components/Accordion';
import { auth, getAllInnerDoc, getSpecCase } from '../firebase'
import ReactPaginate from 'react-paginate'
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from "react-helmet";

export default function Profile() {
    const [cases, setCases] = useState([]);
    const [favoriteList, setFavoriteList] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(0)
    const casesPerPage = 10
    const pagesVisited = pageNumber * casesPerPage

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                getAllInnerDoc('users', currentUser?.uid, 'favorite-list', 'list').then((result) => {
                    setFavoriteList(result[0]?.value)


                    result[0]?.value.map((item, index) => (
                        getSpecCase('cases', item).then((result) => {
                            setCases(prev => [...prev, result[0]]);
                        })
                    ))
                    setIsLoading(false)

                })
            }
        })

    }, []);

    const pageCount = Math.ceil(cases.length / casesPerPage)

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }
    const displayCases = cases.slice(pagesVisited, pagesVisited + casesPerPage).map(({ icon_link, case_num, grade, type, gender, subject, location, fee, time, period, tutor, tutor_edu, post_date, num, remarks, id }, index) => (
        <Accordion key={index} verifiedStatus={true} favoriteList={favoriteList} icon_link={icon_link} case_num={case_num} grade={grade} type={type} gender={gender} subject={subject} location={location} fee={fee} time={time} period={period} tutor={tutor} tutor_edu={tutor_edu} post_date={post_date} num={num} remarks={remarks} id={id} />
    ))


    return (
        <div className='min-h-[800px]'>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/profile" />
            </Helmet>
            <section className=' max-w-5xl mx-auto px-7 py-14'>
                <div className='p-8 px-10 border-b-4 border-grey'>
                    <h2 className='font-normal text-3xl'>收藏個案</h2>

                </div>
                <div className='mt-7'>
                    {isLoading &&
                        <div className='flex justify-center items-center h-[500px]'>
                            <div className='loader'></div>
                        </div>
                    }
                    {!isLoading &&
                        <div className='grid grid-cols-1 md:gap-[20px]'>
                            {displayCases}
                            {displayCases.length === 0 && <div className='text-base text-center h-[300px] flex justify-center items-center'>暫無收藏個案</div>}


                        </div>
                    }


                    <div className='mt-[50px]'>
                        {displayCases.length !== 0 && (
                            <ReactPaginate
                                breakLabel="..."
                                previousLabel={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>}
                                nextLabel={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"relative flex justify-center items-center flex-wrap w-fit mx-auto"}
                                breakClassName={"mx-[10px] mt-[10px]"}
                                breakLinkClassName={"pointer-events-none"}
                                pageClassName={"mx-[5px] rounded-[5px] text-[15px]  shadow-3xl min-w-[34px] bg-white hover:bg-[#053e58] hover:text-white mt-[10px]"}
                                pageLinkClassName={"block w-full p-[5px] text-center"}
                                previousClassName={"absolute -left-[35px] mx-[10px] mt-[10px]"}
                                nextClassName={"absolute -right-[35px] mx-[10px] mt-[10px]"}
                                nextLinkClassName={"top-auto"}
                                disabledClassName={""}
                                activeClassName={"bg-[#053e58] text-white"}
                            />
                        )}

                    </div>


                </div>
            </section>
        </div>
    )
}
