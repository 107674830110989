import { onAuthStateChanged } from 'firebase/auth'
import React, {useState, useEffect} from 'react'
import { auth, db, getAll } from '../firebase'
import TutorForm from '../components/StepForm/Form'
import { collection } from 'firebase/firestore'
import { Helmet } from "react-helmet";

export default function Stepform() {
    const [detail, setDetail] = useState([])
    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                getAll(collection(db, 'users', currentUser.uid, 'detail')).then((result) => {
                    setDetail(result)
                });
            }
        })
    }, [])

    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/tutorinfo" />
            </Helmet>
            {auth ? <TutorForm userInfo={detail} /> : 'isloading'}
        </div>
    )
}
