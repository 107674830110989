import { TextField, MenuItem} from "@mui/material"

const textFieldStyles = ({
    textField: {

    },
    inputWrapper: {
        borderRadius: 20,
        bgcolor: '#000',

    },
    input: {
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16
    },
    label: {
        top: -6
    }


});

const FormSelect = ({
    itemList,
    label,
    inputProps,
    defaultValue,
    error,
    helperText,
    children,
    value,
    ...props
}) => {

    return (
        <TextField
            InputProps={{ style: textFieldStyles.inputWrapper }}
            fullWidth
            size="small"
            variant="outlined"
            select
            defaultValue={defaultValue}
            label={label}
            inputProps={inputProps}
            error={error}
            value={value}
            helperText={helperText}
        >
            {itemList.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};
export default FormSelect;