import { Controller } from "react-hook-form";
import Select from 'react-select';

const Styles = {
    control: (provided, state) => ({ ...provided, borderRadius: '20px', padding: state.isFocused? '3px': '3px'}),

    menuPortal: base => ({ ...base, zIndex: 99 })
  }

const FormSelectGroup = ({
    defaultValue,
    itemList,
    label,
    name,
    control,
    rules,
    isMulti,
    isDisabled,
    onchange,
    ...props
}) => {

    return (

        <Controller
            control={control}

            name={name}
            rules={rules}
            render={({
                field
            }) => (
                <Select
                    {...field}
                    isMulti={isMulti}
                    defaultValue={defaultValue}
                    options={itemList[0].selection}
                    placeholder={label}
                    isDisabled={isDisabled}

                    menuPortalTarget={document.body}
                    styles={Styles}
                />

            )} />

    );
};
export default FormSelectGroup;