import React, { useState,useEffect } from 'react';
import {Button} from '@mui/material';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Controller } from 'react-hook-form';
import { auth, uploadMemberImg,getMemberImg,uploadDSEImg,getDSEImg  } from "../../firebase";

const uploadButtonStyles = {
  borderRadius: 2,
  backgroundColor: 'white !important',
  border: '1px solid grey !important',
  color: '#727272 !important',
  padding: '50px !important',
  py: '3px !important',
  '&:hover': {
    backgroundColor: 'white',
    border: '1px solid grey',
  },
  '&:focus': {
    backgroundColor: 'white',
    border: '1px solid grey',
  }
}
export default function FormFileUpload({type, control, name, title }) {
  const [open, setOpen] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [uid, setuid] = useState(0);

  useEffect(() => {
    setuid(auth?.currentUser?.uid)
  },[auth?.currentUser?.uid]);

  useEffect(() => {
    setHasImage(hasImage)
  },[hasImage]);

  if(type === "dse_prove"){
    getDSEImg(uid).then((result) =>{
      if(result != null){
        setHasImage(result)
      }
    })
  }else{
    getMemberImg(uid).then((result) =>{
      if(result != null){
        setHasImage(result)
      }
    })
  }

  const saveAndGetImg = async (uid, files) => {
    if(type === "dse_prove"){
      uploadDSEImg(uid, files).then(()=>{
        getDSEImg(uid).then((result) =>{
          if(result != null){
            setHasImage(result)
          }
        })
      })
    }else{
      uploadMemberImg(uid, files).then((value)=>{
       if(value){
        getMemberImg(uid).then((result) =>{
          if(result != null){
            setHasImage(result)
          }
        })
       }
      })

    }
  };

  return (

    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref }
      }) => (
        <div className='block md:flex'>
          {hasImage && (
             <div className='flex border mr-4 border-grey'><span className='ml-2 my-auto'>你上載的相片:</span> <img alt="Tutor Plus 香港首席導師協會" className="max-w-[80px] w-auto h-auto max-h-16 mr-2" src={hasImage} /></div>
          )}
          <Button sx={uploadButtonStyles} variant="contained" color="primary" onClick={() => setOpen(true)}>
          請上載
          </Button>




          <DropzoneDialog
            onDrop={onChange}
            acceptedFiles={['image/*']}
            dialogTitle={title}
            cancelButtonText={"取消"}
            submitButtonText={"確定"}
            maxFileSize={10000000}
            open={open}
            onClose={() => setOpen(false)}
            onSave={(files) => {
              setOpen(false);
              value={files}
              {saveAndGetImg(uid, files)}
            }}

            showPreviews={true}
            showFileNamesInPreview={true}
            filesLimit={1}
          />
        </div>


      )} />

  )
}
