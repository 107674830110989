import { configureStore } from "@reduxjs/toolkit"
import filterItemsReducer from '../features/filterSlice';
import loginReducer from '../features/loginSlice';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: 'persist-key',
    storage
}
const persistedLoginReducer = persistReducer(persistConfig, loginReducer)

export default configureStore({
    reducer: {
        filterItems: filterItemsReducer,
        loginItems: persistedLoginReducer
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
