import React, { useState, useEffect } from 'react'
import ScrollToTopBtn from '../components/ScrollToTopBtn'
import Accordion from '../components/Accordion'
import ReactPaginate from 'react-paginate'
import { auth, getAllCases, getAllInnerDoc, getSpecCase } from '../firebase'
import {chargeList, gradeListSearch, showcaseDropdownDetail, tutorGenderList } from '../components/Data'
import DropDown from '../components/DropDown'
import store from '../app/store'
import { onAuthStateChanged } from 'firebase/auth'
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";

export default function Showcase() {
    const [cases, setCases] = useState([]);
    const [targetId, setTargetId] =useState(null);
    const [applyList, setApplyList] = useState([]);
    const [favoriteList, setFavoriteList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [verifiedStatus, setVerifiedStatus] = useState(null)
    const casesPerPage = 10
    const pagesVisited = pageNumber * casesPerPage

    useEffect(() => {
        getAllCases().then((result) => {
            if (result.length <= 0) return
            setCases(result)
            setIsLoading(false)

            let target_id = (window.location.href).split('#')[1]

            if (!target_id) return
            setTimeout(() => {
                scroller.scrollTo(`${target_id}`, {
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                    offset: -80
                  });
            }, 100);
            setTargetId(target_id)



        });

        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                getAllInnerDoc('users', currentUser?.uid, 'apply-list').then((result) => {

                    setApplyList(result[0]?.value)
                })
                getAllInnerDoc('users', currentUser?.uid, 'favorite-list').then((result) => {

                    setFavoriteList(result[0]?.value)
                })
                getSpecCase('users', currentUser?.uid).then((result) => {
                    setVerifiedStatus(result[0].verified)
                })
            }

        })

    }, []);



    const displayCases = cases.slice(pagesVisited, pagesVisited + casesPerPage).map(({ icon_link, case_num, grade, type, gender, subject, district, location, fee, time, period, tutor, tutor_edu, post_date, num, remarks, id }, index) => (
        <Accordion key={index} icon_link={icon_link} case_num={case_num} grade={grade} type={type} gender={gender} subject={subject} district={district} location={location} fee={fee} time={time} period={period} tutor={tutor} tutor_edu={tutor_edu} post_date={post_date} num={num} remarks={remarks} id={id} applyList={applyList} favoriteList={favoriteList} verifiedStatus={verifiedStatus} targetId={targetId} />
    ))

    const pageCount = Math.ceil(cases.length / casesPerPage)

    const changePage = ({ selected }) => {
        setPageNumber(selected)
    }

    const handleSearch = (e) => {
        e.preventDefault()
        let district_arr = []
        let subject_arr = []

        let tutor_fee = store.getState().filterItems.slider1
        let grade_arr = store.getState().filterItems.slider2

        let district_item = store.getState().filterItems.choice1
        district_item.map((item) => (
            district_arr.push(item.value)
        ))

        let subject_item = store.getState().filterItems.choice2
        subject_item.map((item) => (
            subject_arr.push(item.value)
        ))

        let tutor_gender_arr = store.getState().filterItems.choice3


        getAllCases().then((result) => {

            let list = result

            result.forEach((item) => {
                if ((Number(tutor_fee.value) > Number(item.fee))) {

                    list = list.filter(function (filter) { return filter !== item })
                }
                if ((Number(grade_arr.value) > Number(item.grade_index))) {

                    list = list.filter(function (filter) { return filter !== item })
                }
                if (!((district_arr).includes(item.district)) && (district_arr.length !== 0)) {

                    list = list.filter(function (filter) { return filter !== item })

                }
                if (!(item.subject.some(r=> subject_arr.includes(r))) && (subject_arr.length !== 0)) {

                    list = list.filter(function (filter) { return filter !== item })
                }
                if (!(tutor_gender_arr.value === item.tutor) && (tutor_gender_arr.length !== 0)) {

                    list = list.filter(function (filter) { return filter !== item })
                }

            })

            setCases(list)

        })
    }

    return (
        <div className='relative'>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/showcase" />
            </Helmet>
            <ScrollToTopBtn />
            {/*<section className="flex items-center bg-[url('https://www.hkta.edu.hk/images/hkta/banner/case.jpg')] bg-center bg-cover bg-no-repeat h-[230px] ">
                <div className='section-container'>
                    <h3 className=''>最新個案 | 每天超過1000條個案更新，等你來配對</h3>
                </div>

    </section>*/}
            <section className='pb-[70px] py-14'>
                <div className='md-section-container'>
                    <div className='py-8 border-b-2 border-grey mb-7'>
                        <h2 className='font-normal text-3xl'>補習個案</h2>
                    </div>
                    <div className='py-3 md:mb-3 pb-0 grid md:grid-cols-2 gap-x-[20px]'>
                        <div className=''>

                            {/*<RangeSlider tutorFee={true} />*/}
                            <DropDown  key={4} title="每小時學費 " selection={chargeList[0].selection} index={4} />
                        </div>
                        <div className='md:-ml-3'>

                            <DropDown key={3} title="學生年級" selection={gradeListSearch[0].selection} index={3} />
                        </div>


                    </div>
                    <form>
                        <div className='grid grid-cols-1 md:grid-cols-4 md:gap-x-[10px]'>
                            <div className='flex flex-wrap md:flex-nowrap md:col-span-3 col-span-4'>

                                {showcaseDropdownDetail.map(({ title, selection }, index) => (
                                    <DropDown key={index} title={title} selection={selection} innertitle index={index} isMulti/>
                                ))}
                                <DropDown key={2} title="導師性別" selection={tutorGenderList[0].selection} innertitle index={2} />



                            </div>
                            <div className='mt-[10px] md:mt-0 flex md:col-span-1'>
                                <button className='h-[40px] relative flex justify-center items-center py-[7px] px-[12px] w-full rounded-[5px] border-[1px] bg-[#053e68] text-white  cursor-pointer transition-all duration-350 ' type='button' onClick={handleSearch}>搜尋</button>
                            </div>
                        </div>
                    </form>



                    <div className='mt-[30px]'>
                        {isLoading &&
                            <div className='flex justify-center items-center h-[500px]'>
                                <div className='loader'></div>
                            </div>
                        }
                        {!isLoading &&
                            <div className='grid grid-cols-1 md:gap-[20px]'>

                            {displayCases}
                            {displayCases.length === 0 && <div className='text-base text-center h-[300px] flex justify-center items-center'>暫無收藏個案</div>}

                            </div>
                        }


                        <div className='mt-[50px]'>
                            {displayCases.length !== 0 &&
                                <ReactPaginate
                                breakLabel="..."
                                previousLabel={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>}
                                nextLabel={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"relative flex justify-center items-center flex-wrap w-fit mx-auto"}
                                breakClassName={"mx-[10px] mt-[10px]"}
                                breakLinkClassName={"pointer-events-none"}
                                pageClassName={"mx-[5px] rounded-[5px] text-[15px]  shadow-3xl min-w-[34px] bg-white hover:bg-[#053e58] hover:text-white mt-[10px]"}
                                pageLinkClassName={"block w-full p-[5px] text-center"}
                                previousClassName={"absolute -left-[35px] mx-[10px] mt-[10px]"}
                                nextClassName={"absolute -right-[35px] mx-[10px] mt-[10px]"}
                                nextLinkClassName={"top-auto"}
                                disabledClassName={""}
                                activeClassName={"bg-[#053e58] text-white"}
                            />
                            }

                        </div>


                    </div>

                </div>
            </section>
        </div>
    )
}
