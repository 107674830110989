import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
    name: "filterItems",
    initialState: {
        slider1: 10,
        slider2: [],
        choice1: [],
        choice2: [],
        choice3: []
        
    },
    reducers: {
        putSlider1: (state, action) => {
            state.slider1 = action.payload.slider1;
        },
        putSlider2: (state, action) => {
            state.slider2 = action.payload.slider2;
        },
        putChoice1: (state, action) => {
            state.choice1 = action.payload.choice1;
        },
        putChoice2: (state, action) => {
            state.choice2 = action.payload.choice2;
        },
        putChoice3: (state, action) => {
            state.choice3 = action.payload.choice3;
        },
        remove: (state) => {
            state = null
        }
    }
})

export const {putSlider1, putSlider2, putChoice1, putChoice2, putChoice3, remove} = filterSlice.actions;

export const selectItems = (state) => state.filterItems.filterItems;

export default filterSlice.reducer;