import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from "react-hook-form";
import brandLogo from './../images/logo_3.png'

import toast from 'react-hot-toast';
import floatingIcon from './../images/floating-btn.png'
import FormSelect from './StepForm/FormSelect';
import { appellation } from './Data';
import { addVisitor } from '../firebase';

const btn = {

    zIndex: '900',
    textColor: 'transparent',
    "&:hover": {
        'background-color': 'transparent'
    },
    ['@media (max-width: 767px)']: { // eslint-disable-line no-useless-computed-key
        width: '150px',
        left: '10px',
        bottom: '10px',
    }

}

const textFieldStyles = ({
    textField: {

    },
    inputWrapper: {
        borderRadius: 20,
        bgcolor: '#000',

    },
    input: {
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16
    },
    label: {
        top: -6
    }

});
const stylebox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    paddingLeft: '0',
    paddingRight: '0'
};

export default function FloatingBtnForm() {
    const [hidden, setHidden] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setPage(0)
        reset();

    };
    const [page, setPage] = useState(0);

    const { register, unregister, control, trigger, setError, formState: { errors, isSubmitSuccessful, isValid }, handleSubmit, watch, reset, resetField } = useForm({
        mode: 'all',

    });
    const onSubmit = async (data, event) => {
        const noError = await trigger();
        if (noError) {
            addVisitor('visitors', data).then((result) => {
                setPage(1)
                reset()
            })
        }

    }
    return (
        <div className='relative'>

            {!hidden && (
                <div className='fixed left-[20px] bottom-[20px] z-[999]'>
                    <button onClick={() => setHidden(!hidden)} className='rounded-full bg-[#396e90] transform scale-50 origin-top-left p-2 text-white absolute -top-[10px] -left-[4px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <svg aria-hidden="true" className="w-2 h-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </svg>
                        <span className="hidden">close</span>
                    </button>
                    <Button sx={btn} onClick={handleOpen}>

                        <img className="w-48 ml-2" src={floatingIcon} alt="Tutor Plus 香港首席導師協會" />
                    </Button>
                </div>

            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={stylebox}>

                    <div className='cursor-pointer absolute right-[10px] top-[10px]' onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#aaa" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    {page === 0 && (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className='text-center px-14'>
                                <img className="w-64 mx-auto mb-4" src={brandLogo} alt="logo"></img>
                                <div className='text-xl mb-3 font-bold'>三秒找導師</div>
                                <p className='mb-10'>留下簡單聯絡資料<br />等我地幫你搵最適合嘅導師啦!</p>
                                <div className='grid gap-y-4 mb-10'>
                                    <FormSelect
                                        itemList={appellation}
                                        label={`稱謂`}
                                        inputProps={register(`appellation`, { "required": "需填寫" })}

                                        error={errors.appellation}
                                        helperText={errors.appellation?.message}
                                    />
                                    <TextField label="稱呼" variant="outlined" size="small" fullWidth {...register(`name`, {
                                        required: "需填寫",

                                        "maxLength": {
                                            "value": 20,
                                            "message": "請輸入不多於20個字"
                                        }
                                    })} error={!!errors?.name} helperText={errors?.name ? errors.name.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />

                                    <TextField label="手提電話" variant="outlined" size="small" fullWidth {...register(`phone`, {
                                        required: "需填寫", pattern: { value: /^[0-9]+$/, message: '只接受數字' }, "maxLength": {
                                            "value": 20,
                                            "message": "請輸入不多於20個數字"
                                        }
                                    })} error={!!errors?.phone} helperText={errors?.phone ? errors.phone.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                </div>

                                <button className='modal-btn text-dark-blue border-dark-blue  hover:text-white hover:bg-dark-blue mb-4 disabled:opacity-25 disabled:cursor-not-allowed disabled:hover:bg-white disabled:hover:text-dark-blue' disabled={!isValid} >提交</button>


                            </div>
                        </form>

                    )}

                    {page === 1 && (
                        <div className='text-center'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto w-40 aspect-[1/1] text-dark-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <h2 className='text-dark-blue'>提交完成</h2>
                            <h5 className='text-dark-blue mb-10'>我們會盡快聯絡你!</h5>

                            <h5 className=''>負責職員:黃小姐</h5>
                            <h5 className=''>熱線電話:5938 1383</h5>

                        </div>
                    )}

                </Box>
            </Modal>


        </div>
    )
}
