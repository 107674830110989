import React, {useState} from 'react'
import './Slider.css'
/* import BtnSlider from './BtnSlider' */
import { v4 as uuidv4 } from "uuid";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
/* import dataSlider from './dataSlider' */

export default function Slider() {
    const dataSlider = [
        {
          id: uuidv4(),
          title: "slide1",
          subTitle: "subTitle1",
          button1: '家長登記',
          button2: "導師登記"

        },

      ];


    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    const openRegister = () => {
        document.getElementsByClassName('tutor-register')[0].click()
        setTimeout(() => {
            document.getElementById('tutor-register').click()

        }, 500);

    }


    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img
                        fetchpriority="high"
                        className='w-full'
                        src={require(`./Imgs/img${index + 1}.jpg`)}
                        alt="Tutor Plus 香港首席導師協會"
                        />
                    </div>
                )
            })}
             {/*
            <button
            onClick={nextSlide}
            className="btn-slide next"
            >
            <img src={rightArrow} />
            </button>
            <button
            onClick={prevSlide}
            className="btn-slide prev"
            >
            <img src={leftArrow} />
            </button>
            */}
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

            <div className="container-dots">
                {dataSlider.map((item, index) => (
                    <div
                    key={index}
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    >

                    </div>
                ))}
            </div>

            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className="absolute left-1/2 -translate-x-1/2 md:left-[8%] md:translate-x-0 top-1/2 "
                    >
                    <div className="flex items-center gap-7">
                        <a href='/registerform' className='home-cta hover:bg-[#053e68] text-sm md:text-xl px-2 py-2 md:py-4 md:px-10 md:mt-12' >{obj.button1}</a>
                        <button  onClick={ openRegister } className='home-cta hover:bg-[#053e68] text-sm md:text-xl px-2 py-2 md:py-4 md:px-10 md:mt-12' >{obj.button2}</button>
                    </div>



                    </div>
                )
            })}
        </div>
    )
}
