import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import store from '../app/store';
import { putChoice1, putChoice2, putChoice3,putSlider1, putSlider2 } from '../features/filterSlice';


 
export default function DropDown({ title, selection, index, isMulti }) {
    
    const [selectedOption, setSelectedOption] = useState([]);
    const dispatch = useDispatch();
    const handleOnChange = (value) => {
        
        if (index === 0) {
            dispatch(putChoice1({
                choice1: value
                
            }))
        }else if (index === 1) {
            dispatch(putChoice2({
                choice2: value
                
            }))
        }else if (index === 2) {
            dispatch(putChoice3({
                choice3: value
                
            }))
        }else if (index === 3) {
            dispatch(putSlider2({
                slider2: value
                
            }))
        }
        else if (index === 4) {
            dispatch(putSlider1({
                slider1: value
                
            }))
        }
        
    }
    
    return (
        <div className="w-full mb-3 md:mr-2 md:mb-0">
            <Select
                placeholder={title}
                isMulti={isMulti}
                onChange={handleOnChange}
                options={selection}
                
                
            />
        </div>
    );
}