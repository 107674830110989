import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { updateInnerDoc, auth } from '../firebase';


const Accordion = ({ case_num, grade, type, gender, subject, district, location, fee, time, period, tutor, tutor_edu, post_date, num, remarks, id, applyList, favoriteList, verifiedStatus, targetId }) => {

  const [isApply, setIsApply] = useState(null)
  const [isFavorite, setIsFavorite] = useState(null)
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (applyList) {
      if (!applyList.includes(id)) {
        setIsApply(true)

      } else {
        setIsApply(false)
      }
    }
    if (favoriteList) {
      if (!favoriteList.includes(id)) {
        setIsFavorite(true)
      } else {
        setIsFavorite(false)
      }
    }
    if (targetId === case_num) {
      setIsActive(true)
    }

  }, [])





  const applyCase = (id) => {

    if (isApply) {

      if (!applyList.includes(id)) {
        applyList.push(id)
      }


      updateInnerDoc('users', auth?.currentUser.uid, 'apply-list', 'list', applyList)
      setIsApply(false)
      toast.success('已申請')


    } else {
      for (var i = 0; i < applyList.length; i++) {
        if (applyList[i] === id) {
          applyList.splice(i, 1);
        }
      }

      updateInnerDoc('users', auth?.currentUser.uid, 'apply-list', 'list', applyList)
      setIsApply(true)
      toast.success('已取消申請')


    }
  }

  const favoriteCase = (id) => {
    if (isFavorite) {
      if (!favoriteList.includes(id)) {
        favoriteList.push(id)
      }

      updateInnerDoc('users', auth?.currentUser.uid, 'favorite-list', 'list', favoriteList)

      setIsFavorite(false)
      toast.success('已加入收藏個案')


    } else {
      for (var i = 0; i < favoriteList.length; i++) {
        if (favoriteList[i] === id) {
          favoriteList.splice(i, 1);
        }
      }
      updateInnerDoc('users', auth?.currentUser.uid, 'favorite-list', 'list', favoriteList)

      setIsFavorite(true)
      toast.success('已取消加入收藏個案')

    }
  }
  return (
    <div className={isActive ? `mb-[20px] bg-white shadow-[0_2px_6px_0_rgba(177,174,174,0.5)] ${case_num}` : `border-[1px] md:border-b-[1px] border-[#e7e7e7] border-b-0 last:border-b-[1px] ${case_num}`}>
      <div className="cursor-pointer text-[1rem] text-[#707070] tracking-[1px] relative flex items-center flex-col md:flex-row" onClick={() => setIsActive(!isActive)} >
        <div className='p-4 md:p-0 flex w-full md:w-auto '>
          <div className='min-w-[105px]'>
            <img className="w-[130px]  md:w-[105px] aspect-[1/1] rounded-[4px] md:rounded-none object-cover" src={`/${subject[0].trim()}.jpg`} alt="Tutor Plus 香港首席導師協會" ></img>
          </div>
          <div className='pl-[10px] md:pl-[30px] flex justify-center md:items-center'>
            <div className='md:w-[100px] lg:w-[280px] md:py-[10px] md:border-r-[1px] border-[#ccc] pr-[20px] md:pr-0'>
              <div className='text-[16px] font-bold hidden md:block'>個案編號：{case_num}</div>
              <div className='text-[16px] font-bold text-showcase-theme'>
                {grade}，
                {subject?.map(item => {
                  if (subject.at(-1) === item) {
                    return item
                  } else {
                    return `${item}, `
                  }

                })}
              </div>
              <div className='block md:hidden'>
                <p className='mb-[5px] text-[14px]'>學生人數：{gender} - {num}名</p>
                <p className='mb-[5px] text-[14px]'>補習地點：{district} {location}</p>
                <p className='mb-[5px] text-[14px]'>家長要求：{tutor}</p>
                <p className='text-showcase-theme mb-0'>個案編號：{case_num}</p>
              </div>
            </div>
          </div>

        </div>

        <div className='flex justify-between w-full px-4 pb-[10px] md:hidden'>
          <span className='text-[13px] text-[#999]'>登記時間：{post_date}</span>
          <span className='text-[15px] font-bold tracking-[1px]'>${fee}/hr</span>
        </div>

        <div className='hidden md:flex flex-col md:flex-row mt-[20px] md:mt-0 px-[20px] pb-[20px] md:pb-0 justify-around items-center w-full'>



          <div className='text-[16px] text-center flex-1 self-start md:self-auto'>{district} {location}</div>
          <div className='text-[16px] text-center flex-1 self-start md:self-auto'>${fee}/hr</div>
          <div className='text-[16px] text-center flex-1 self-start md:self-auto'>一星期{period}堂</div>
          <div className={`text-[16px] text-center flex-1 self-start md:self-auto ${tutor === '男導師' && 'text-light-blue'} ${tutor === '女導師' && 'text-[#ec59c3]'} ${tutor === '男女導師均可' && 'text-[#3aba43]'}`}>{tutor}</div>
          <div className={`transition-all duration-350 mt-[20px] md:mt-0 ${isActive && "rotate-180"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 stroke-showcase-theme`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
        <div className='block md:hidden absolute top-1/2 -translate-y-1/2 right-[20px]'>
          <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 transition-transform ${isActive && "rotate-180"}`} viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>


      </div>
      {isActive &&
        <div className="relative overflow-hidden py-[20px] px-[30px] bg-[rgb(241,241,241)]">
          {/* <div className='text-[20px] font-bold text-showcase-theme mb-[20px]'>{grade} {subject}</div> */}
          <div className=''>
            <div>
              <div className='inline-block md:pr-2'>年級：</div>
              <div className='inline-block text-[15px] text-[rgb(238,148,0)]'>{grade} ({gender})</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>科目：</div>
              <div className='inline-block text-[15px]'>
                {subject?.map(item => {
                  if (subject.at(-1) === item) {
                    return item
                  } else {
                    return `${item}, `
                  }

                })}</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>學費：</div>
              <div className='inline-block text-[15px]'>${fee}/1hr</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>堂數：</div>
              <div className='inline-block text-[15px]'>每星期{period}堂</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>時數：</div>
              <div className='inline-block text-[15px]'>{time}hr</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>交通：</div>
              <div className='inline-block text-[15px]'></div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>轉乘：</div>
              <div className='inline-block text-[15px]'></div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>地點：</div>
              <div className='inline-block text-[15px]'>{district}，{location} （{type}）</div>
            </div>
            <div>
              <div className='inline-block md:pr-2'>要求：</div>
              <div className='inline-block text-[15px]'>{tutor_edu}，{tutor}</div>
            </div>
          </div>
          <div className='mt-[30px] w-[60%] md:w-3/4'>
            備註：{remarks}<br />
            刊登日期：{post_date}
          </div>
          {verifiedStatus && (
            <div className='absolute right-[15px] md:right-[30px] bottom-[20px] flex flex-col md:flex-row items-end justify-end md:items-center w-1/3 text-right'>
              {favoriteList && (
                <button onClick={() => favoriteCase(id)} className='bg-[#f4cccc] rounded-[5px] w-[25px] h-[25px] flex justify-center items-center md:mr-[30px] mb-[10px] md:mb-0'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill={`${isFavorite ? "grey" : "#f878aa"}`}>
                    <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                  </svg>
                </button>
              )}

              {applyList && (
                <button onClick={() => applyCase(id)} className={` rounded-[5px] text-[15px] font-bold px-[15px] py-[5px] ${isApply ? 'bg-[#f9cb9c] text-[#b45f06]' : 'bg-red text-white'}`}>{isApply ? '申請補習' : '取消申請'}</button>
              )}


              {/* {isApply && (
            <button onClick={() => removeApply(id)} className={`bg-[#f9cb9c] rounded-[5px] text-[#b45f06] text-[15px] font-bold px-[15px] py-[5px] `}>取消申請</button>
          )} */}

            </div>
          )}
          {verifiedStatus == false && (
            <div className='absolute right-[15px] md:right-[30px] bottom-[20px] flex flex-col md:flex-row items-end justify-end md:items-center w-1/5 text-right'>
              <p className='mb-0 text-red'>請等侯驗證以申請及收藏個案</p>
            </div>
          )}

        </div>
      }
    </div>

  );
};

export default Accordion;