
export const DigitalMarketingSwiperList = [
  {
    thumbnail: "https://www.dbs.com.hk/iwov-resources/images/FPS-HKID-Registration_Digital-token_en.gif",
    heading: "網上填表",
    description: "於網站上方的目錄按“學生尋補”，填上網上表格中，填寫補習表格後，會有客服專員聯絡您。"

  },
  {
    thumbnail: "https://www.kaspersky.com.cn/content/zh-cn/images/repository/isc/2019-images/holding-iphone-3.jpg",
    heading: "等待配對",
    description: "於網站上方的目錄按“學生尋補”，填上網上表格中，填寫補習表格後，會有客服專員聯絡您。"
  },
  {
    thumbnail: "https://www.tutorcircle.hk/img/ReadyClassG.png",
    heading: "準備上課",
    description: "於網站上方的目錄按“學生尋補”，填上網上表格中，填寫補習表格後，會有客服專員聯絡您。"
  },


];
export const showcaseDropdownDetail = [
  {
    title: "補習地區(可多選)",
    selection: [
      {
        label: "港島",
        options: [
          { label: "上環", value: "上環" },
          { label: "中環", value: "中環" },
          { label: "利東", value: "利東" },
          { label: "北角", value: "北角" },
          { label: "半山", value: "半山" },
          { label: "堅尼地城", value: "堅尼地城" },
          { label: "天后", value: "天后" },
          { label: "數碼港", value: "數碼港" },
          { label: "杏花邨", value: "杏花邨" },
          { label: "太古城", value: "太古城" },
          { label: "小西灣", value: "小西灣" },
          { label: "柴灣", value: "柴灣" },
          { label: "海怡半島", value: "海怡半島" },
          { label: "深水灣", value: "深水灣" },
          { label: "淺水灣", value: "淺水灣" },
          { label: "灣仔", value: "灣仔" },
          { label: "炮台山", value: "炮台山" },
          { label: "田灣", value: "田灣" },
          { label: "石排灣", value: "石排灣" },
          { label: "石澳", value: "石澳" },
          { label: "筲箕灣", value: "筲箕灣" },
          { label: "華富", value: "華富" },
          { label: "薄扶林", value: "薄扶林" },
          { label: "西灣河", value: "西灣河" },
          { label: "西營盤", value: "西營盤" },
          { label: "西環", value: "西環" },
          { label: "赤柱", value: "赤柱" },
          { label: "跑馬地", value: "跑馬地" },
          { label: "金鐘", value: "金鐘" },
          { label: "銅鑼灣", value: "銅鑼灣" },
          { label: "鋼綫灣", value: "鋼綫灣" },
          { label: "香港仔", value: "香港仔" },
          { label: "鰂魚涌", value: "鰂魚涌" },
          { label: "鴨脷洲", value: "鴨脷洲" },
          { label: "黃竹坑", value: "黃竹坑" },

        ]

      },
      {
        label: "九龍",
        options: [
          { label: "九龍城", value: "九龍城" },
          { label: "九龍塘", value: "九龍塘" },
          { label: "九龍灣", value: "九龍灣" },
          { label: "佐敦", value: "佐敦" },
          { label: "何文田", value: "何文田" },
          { label: "南昌", value: "南昌" },
          { label: "又一村", value: "又一村" },
          { label: "啟德", value: "啟德" },
          { label: "土瓜灣", value: "土瓜灣" },
          { label: "大窩坪", value: "大窩坪" },
          { label: "大角咀", value: "大角咀" },
          { label: "太子", value: "太子" },
          { label: "奧運", value: "奧運" },
          { label: "尖沙咀", value: "尖沙咀" },
          { label: "彩虹", value: "彩虹" },
          { label: "慈雲山", value: "慈雲山" },
          { label: "斧山", value: "斧山" },
          { label: "新蒲崗", value: "新蒲崗" },
          { label: "旺角", value: "旺角" },
          { label: "柯士甸", value: "柯士甸" },
          { label: "樂富", value: "樂富" },
          { label: "橫頭磡", value: "橫頭磡" },
          { label: "油麻地", value: "油麻地" },
          { label: "深水埗", value: "深水埗" },
          { label: "牛池灣", value: "牛池灣" },
          { label: "牛頭角", value: "牛頭角" },
          { label: "石硤尾", value: "石硤尾" },
          { label: "秀茂坪", value: "秀茂坪" },
          { label: "竹園", value: "竹園" },
          { label: "紅磡", value: "紅磡" },
          { label: "美孚", value: "美孚" },
          { label: "荔枝角", value: "荔枝角" },
          { label: "蘇屋", value: "蘇屋" },
          { label: "觀塘", value: "觀塘" },
          { label: "鑽石山", value: "鑽石山" },
          { label: "長沙灣", value: "長沙灣" },
          { label: "馬頭圍", value: "馬頭圍" },
          { label: "鯉魚門", value: "鯉魚門" },
          { label: "黃埔", value: "黃埔" },
          { label: "黃大仙", value: "黃大仙" },

        ]

      },
      {
        label: "新界",
        options: [
          { label: "上水", value: "上水" },
          { label: "元朗", value: "元朗" },
          { label: "兆康", value: "兆康" },
          { label: "南丫島", value: "南丫島" },
          { label: "坑口", value: "坑口" },
          { label: "坪洲", value: "坪洲" },
          { label: "大圍", value: "大圍" },
          { label: "大埔", value: "大埔" },
          { label: "大嶼山", value: "大嶼山" },
          { label: "大水坑", value: "大水坑" },
          { label: "大窩口", value: "大窩口" },
          { label: "天水圍", value: "天水圍" },
          { label: "太和", value: "太和" },
          { label: "寶琳", value: "寶琳" },
          { label: "將軍澳", value: "將軍澳" },
          { label: "小瀝源", value: "小瀝源" },
          { label: "屯門", value: "屯門" },
          { label: "康城", value: "康城" },
          { label: "恆安", value: "恆安" },
          { label: "愉景灣", value: "愉景灣" },
          { label: "打鼓嶺", value: "打鼓嶺" },
          { label: "朗屏", value: "朗屏" },
          { label: "東涌", value: "東涌" },
          { label: "梨木樹", value: "梨木樹" },
          { label: "欣澳", value: "欣澳" },
          { label: "沙田", value: "沙田" },
          { label: "沙田圍", value: "沙田圍" },
          { label: "沙頭角", value: "沙頭角" },
          { label: "油塘", value: "油塘" },
          { label: "洪水橋", value: "洪水橋" },
          { label: "深井", value: "深井" },
          { label: "清水灣", value: "清水灣" },
          { label: "火炭", value: "火炭" },
          { label: "烏溪沙", value: "烏溪沙" },
          { label: "石籬", value: "石籬" },
          { label: "石蔭", value: "石蔭" },
          { label: "石門", value: "石門" },
          { label: "禾輋", value: "禾輋" },
          { label: "第一城", value: "第一城" },
          { label: "粉嶺", value: "粉嶺" },
          { label: "荃灣", value: "荃灣" },
          { label: "荔景", value: "荔景" },
          { label: "葵涌", value: "葵涌" },
          { label: "葵盛", value: "葵盛" },
          { label: "葵興", value: "葵興" },
          { label: "葵芳", value: "葵芳" },
          { label: "藍田", value: "藍田" },
          { label: "西貢", value: "西貢" },
          { label: "調景嶺", value: "調景嶺" },
          { label: "車公廟", value: "車公廟" },
          { label: "錦上路", value: "錦上路" },
          { label: "長洲", value: "長洲" },
          { label: "青衣", value: "青衣" },
          { label: "青龍頭", value: "青龍頭" },
          { label: "顯徑", value: "顯徑" },
          { label: "馬料水", value: "馬料水" },
          { label: "馬灣", value: "馬灣" },
          { label: "馬鞍山", value: "馬鞍山" },



        ]

      },
    ]
  },
  {
    title: "補習科目(可多選)",
    selection: [
      {
        options: [
          { label: "全科", value: "全科" },
        ]
      },
      {
        label: "主科",
        options: [
          { label: "中文", value: "中文" },
          { label: "英文", value: "英文" },
          { label: "數學", value: "數學" },
          { label: "通識", value: "通識" },
          { label: "公民與社會發展", value: "公民與社會發展" },
          { label: "綜合人文科", value: "綜合人文科" },
          { label: "常識", value: "常識" },
        ]
      },
      {
        label: "文科",
        options: [
          { label: "中國文學", value: "中國文學" },
          { label: "中國歷史", value: "中國歷史" },
          { label: "英語文學", value: "英語文學" },
          { label: "歷史（世史）", value: "歷史（世史）" },
          { label: "地理", value: "地理" },
          { label: "倫理與宗教（ERS）", value: "倫理與宗教（ERS）" },
        ]
      },
      {
        label: "理科",
        options: [
          { label: "生物", value: "生物" },
          { label: "化學", value: "化學" },
          { label: "物理", value: "物理" },
          { label: "組合科學", value: "組合科學" },
          { label: "綜合科學", value: "綜合科學" },
          { label: "資訊及通訊科技（ICT)", value: "資訊及通訊科技（ICT)" },
          { label: "M1", value: "M1" },
          { label: "M2", value: "M2" },
        ]

      },
      {
        label: "商科",
        options: [
          { label: "經濟", value: "經濟" },
          { label: "企業、會計與財務概論（BAFS)", value: "企業、會計與財務概論（BAFS)" },
          { label: "旅遊與款待", value: "旅遊與款待" },
        ]

      },
      {
        label: "其他",
        options: [
          { label: "設計與應用科技（DAT）", value: "設計與應用科技（DAT）" },
          { label: "健康管理與社會關懷（HMSC）", value: "康管理與社會關懷（HMSC）" },
          { label: "科技與生活（TL）", value: "科技與生活（TL）" },
          { label: "音樂", value: "音樂" },
          { label: "視覺藝術", value: "視覺藝術" },
          { label: "體育", value: "體育" },
        ]
      },
    ]

  },


];

export const EducationRangeArray = [

  {
    value: 0,
    label: 'K1',
  },
  {
    value: 6,
    label: 'K2',
  },
  {
    value: 12,
    label: 'K3',
  },
  {
    value: 18,
    label: '小一',
  },
  {
    value: 24,
    label: '小二',
  },
  {
    value: 30,
    label: '小三',
  },
  {
    value: 36,
    label: '小四',
  },

  {
    value: 42,
    label: '小五',
  },
  {
    value: 48,
    label: '小六',
  },
  {
    value: 54,
    label: '中一',
  },
  {
    value: 60,
    label: '中二',
  },
  {
    value: 66,
    label: '中三',
  },
  {
    value: 72,
    label: '中四',
  },

  {
    value: 78,
    label: '中五',
  },
  {
    value: 84,
    label: '中六',
  },
  {
    value: 90,
    label: '大專或以上',
  },
];



export const appellation = ['先生', '太太', '小姐'];
export const dseRank = ['1', '2', '3', '4', '5', '5*', '5**']
export const oldRank = ['A', 'B', 'C', 'D', 'E', 'F', 'U']
export const exam_dse_elective = [

  {
    selection: [
      {
        options: [
          { label: "全科", value: "全科" },
        ]
      },
      {
        label: "主科",
        options: [
          { label: "中文", value: "中文" },
          { label: "英文", value: "英文" },
          { label: "數學", value: "數學" },
          { label: "通識", value: "通識" },
          { label: "公民與社會發展", value: "公民與社會發展" },
          { label: "綜合人文科", value: "綜合人文科" },
          { label: "常識", value: "常識" },
        ]
      },
      {
        label: "文科",
        options: [
          { label: "中國文學", value: "中國文學" },
          { label: "中國歷史", value: "中國歷史" },
          { label: "英語文學", value: "英語文學" },
          { label: "歷史（世史）", value: "歷史（世史）" },
          { label: "地理", value: "地理" },
          { label: "倫理與宗教（ERS）", value: "倫理與宗教（ERS）" },
        ]
      },
      {
        label: "理科",
        options: [
          { label: "生物", value: "生物" },
          { label: "化學", value: "化學" },
          { label: "物理", value: "物理" },
          { label: "組合科學", value: "組合科學" },
          { label: "綜合科學", value: "綜合科學" },
          { label: "資訊及通訊科技（ICT)", value: "資訊及通訊科技（ICT)" },
          { label: "M1", value: "M1" },
          { label: "M2", value: "M2" },
        ]

      },
      {
        label: "商科",
        options: [
          { label: "經濟", value: "經濟" },
          { label: "企業、會計與財務概論（BAFS)", value: "企業、會計與財務概論（BAFS)" },
          { label: "旅遊與款待", value: "旅遊與款待" },
        ]

      },
      {
        label: "其他",
        options: [
          { label: "設計與應用科技（DAT）", value: "設計與應用科技（DAT）" },
          { label: "健康管理與社會關懷（HMSC）", value: "康管理與社會關懷（HMSC）" },
          { label: "科技與生活（TL）", value: "科技與生活（TL）" },
          { label: "音樂", value: "音樂" },
          { label: "視覺藝術", value: "視覺藝術" },
          { label: "體育", value: "體育" },
        ]
      },
    ]
  }


]
export const all_subject = [

  {
    selection: [
      {
        options: [
          { label: "全科", value: "全科" },
        ]
      },
      {
        label: "主科",
        options: [
          { label: "中文", value: "中文" },
          { label: "英文", value: "英文" },
          { label: "數學", value: "數學" },
          { label: "通識", value: "通識" },
          { label: "公民與社會發展", value: "公民與社會發展" },
          { label: "綜合人文科", value: "綜合人文科" },
          { label: "常識", value: "常識" },
        ]
      },
      {
        label: "文科",
        options: [
          { label: "中國文學", value: "中國文學" },
          { label: "中國歷史", value: "中國歷史" },
          { label: "英語文學", value: "英語文學" },
          { label: "歷史（世史）", value: "歷史（世史）" },
          { label: "地理", value: "地理" },
          { label: "倫理與宗教（ERS）", value: "倫理與宗教（ERS）" },
        ]
      },
      {
        label: "理科",
        options: [
          { label: "生物", value: "生物" },
          { label: "化學", value: "化學" },
          { label: "物理", value: "物理" },
          { label: "組合科學", value: "組合科學" },
          { label: "綜合科學", value: "綜合科學" },
          { label: "資訊及通訊科技（ICT)", value: "資訊及通訊科技（ICT)" },
          { label: "M1", value: "M1" },
          { label: "M2", value: "M2" },
        ]

      },
      {
        label: "商科",
        options: [
          { label: "經濟", value: "經濟" },
          { label: "企業、會計與財務概論（BAFS)", value: "企業、會計與財務概論（BAFS)" },
          { label: "旅遊與款待", value: "旅遊與款待" },
        ]

      },
      {
        label: "其他",
        options: [
          { label: "設計與應用科技（DAT）", value: "設計與應用科技（DAT）" },
          { label: "健康管理與社會關懷（HMSC）", value: "康管理與社會關懷（HMSC）" },
          { label: "科技與生活（TL）", value: "科技與生活（TL）" },
          { label: "音樂", value: "音樂" },
          { label: "視覺藝術", value: "視覺藝術" },
          { label: "體育", value: "體育" },
        ]
      },
    ]
  }


]
export const exam_ce_elective = [

  {
    selection: [
      {
        label: "理科",
        options: [
          { label: "物理", value: "物理" },
          { label: "化學", value: "化學" },
          { label: "生物", value: "生物" },
          { label: "附加數學", value: "附加數學" },

        ]

      },
      {
        label: "文科",
        options: [
          { label: "中國文學", value: "中國文學" },
          { label: "英國文學", value: "英國文學" },
          { label: "中國歷史", value: "中國歷史" },
          { label: "歷史", value: "歷史" },
          { label: "地理", value: "地理" },
        ]

      },
      {
        label: "商科科目",
        options: [
          { label: "經濟", value: "經濟" },
          { label: "商業", value: "商業" },
          { label: "會計學原理", value: "會計學原理" },
          { label: "經濟及公共事務", value: "經濟及公共事務" },
          { label: "旅遊及旅遊業", value: "旅遊及旅遊業" },
        ]

      },
      {
        label: "其他科目",
        options: [
          { label: "普通話", value: "普通話" },
          { label: "政府與公共事務", value: "政府與公共事務" },
          { label: "電腦與資訊科技", value: "電腦與資訊科技" },
          { label: "綜合人文科", value: "綜合人文科" },
          { label: "科技概論", value: "科技概論" },
          { label: "宗教(基督教)", value: "宗教(基督教)" },
          { label: "體育", value: "體育" },
          { label: "音樂", value: "音樂" },
        ]

      },


    ]
  }


]

export const exam_al_elective = [

  {
    selection: [
      {
        label: "理科",
        options: [
          { label: "物理", value: "物理" },
          { label: "化學", value: "化學" },
          { label: "生物", value: "生物" },
          { label: "純粹數學", value: "純粹數學" },
          { label: "應用數學", value: "應用數學" },
          { label: "數學及統計學", value: "數學及統計學" },

        ]

      },
      {
        label: "文科",
        options: [
          { label: "中國文學", value: "中國文學" },
          { label: "英國文學", value: "英國文學" },
          { label: "中國歷史", value: "中國歷史" },
          { label: "歷史", value: "歷史" },
          { label: "地理", value: "地理" },
        ]

      },
      {
        label: "商科科目",
        options: [
          { label: "經濟", value: "經濟" },
          { label: "企業概論", value: "企業概論" },
          { label: "會計學原理", value: "會計學原理" },

        ]

      },
      {
        label: "其他科目",
        options: [
          { label: "通識教育", value: "通識教育" },
          { label: "心理學", value: "心理學" },
          { label: "電腦與資訊科技", value: "電腦與資訊科技" },
          { label: "政府與公共事務", value: "政府與公共事務" },
          { label: "倫理及宗教", value: "倫理及宗教" },
          { label: "電腦", value: "電腦" },
          { label: "電腦應用", value: "電腦應用" },
        ]

      },


    ]
  }


]

export const genderList = [
  '男',
  '女'
]

export const education_level_list = [
  '高級文憑',
  '學士',
  '副學士',
  '碩士',
  '博士',
  '教育文憑',
  'DSE剛畢業',
  '其他',

]

export const college_list = [
  '香港大學',
  '香港中文大學',
  '香港科技大學',
  '香港理工大學',
  '香港城市大學',
  '香港浸會大學',
  '嶺南大學',
  '香港樹仁大學',
  '香港教育大學',
  '香港都會大學',
  '香港恒生大學',
  '香港專業教育學院',
  '香港高等教育科技學院',
  '珠海學院',
  '明愛專上學院',
  '東華學院',
  '其他',
]

export const education_status = [
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  '已畢業'
]

export const public_exam_list = [
  'SAT',
  'International Baccalaureate (IB)',
  'GCE (A-Levels)',
  'IGCSE',
  'ACT',
  'Advanced Placement (AP)',
  '其他'
]


export const district_list = [

  {
    selection: [
      {
        label: "港島",
        options: [
          { label: "上環", value: "上環" },
          { label: "中環", value: "中環" },
          { label: "利東", value: "利東" },
          { label: "北角", value: "北角" },
          { label: "半山", value: "半山" },
          { label: "堅尼地城", value: "堅尼地城" },
          { label: "天后", value: "天后" },
          { label: "數碼港", value: "數碼港" },
          { label: "杏花邨", value: "杏花邨" },
          { label: "太古城", value: "太古城" },
          { label: "小西灣", value: "小西灣" },
          { label: "柴灣", value: "柴灣" },
          { label: "海怡半島", value: "海怡半島" },
          { label: "深水灣", value: "深水灣" },
          { label: "淺水灣", value: "淺水灣" },
          { label: "灣仔", value: "灣仔" },
          { label: "炮台山", value: "炮台山" },
          { label: "田灣", value: "田灣" },
          { label: "石排灣", value: "石排灣" },
          { label: "石澳", value: "石澳" },
          { label: "筲箕灣", value: "筲箕灣" },
          { label: "華富", value: "華富" },
          { label: "薄扶林", value: "薄扶林" },
          { label: "西灣河", value: "西灣河" },
          { label: "西營盤", value: "西營盤" },
          { label: "西環", value: "西環" },
          { label: "赤柱", value: "赤柱" },
          { label: "跑馬地", value: "跑馬地" },
          { label: "金鐘", value: "金鐘" },
          { label: "銅鑼灣", value: "銅鑼灣" },
          { label: "鋼綫灣", value: "鋼綫灣" },
          { label: "香港仔", value: "香港仔" },
          { label: "鰂魚涌", value: "鰂魚涌" },
          { label: "鴨脷洲", value: "鴨脷洲" },
          { label: "黃竹坑", value: "黃竹坑" },

        ]

      },
      {
        label: "九龍",
        options: [
          { label: "九龍城", value: "九龍城" },
          { label: "九龍塘", value: "九龍塘" },
          { label: "九龍灣", value: "九龍灣" },
          { label: "佐敦", value: "佐敦" },
          { label: "何文田", value: "何文田" },
          { label: "南昌", value: "南昌" },
          { label: "又一村", value: "又一村" },
          { label: "啟德", value: "啟德" },
          { label: "土瓜灣", value: "土瓜灣" },
          { label: "大窩坪", value: "大窩坪" },
          { label: "大角咀", value: "大角咀" },
          { label: "太子", value: "太子" },
          { label: "奧運", value: "奧運" },
          { label: "尖沙咀", value: "尖沙咀" },
          { label: "彩虹", value: "彩虹" },
          { label: "慈雲山", value: "慈雲山" },
          { label: "斧山", value: "斧山" },
          { label: "新蒲崗", value: "新蒲崗" },
          { label: "旺角", value: "旺角" },
          { label: "柯士甸", value: "柯士甸" },
          { label: "樂富", value: "樂富" },
          { label: "橫頭磡", value: "橫頭磡" },
          { label: "油麻地", value: "油麻地" },
          { label: "深水埗", value: "深水埗" },
          { label: "牛池灣", value: "牛池灣" },
          { label: "牛頭角", value: "牛頭角" },
          { label: "石硤尾", value: "石硤尾" },
          { label: "秀茂坪", value: "秀茂坪" },
          { label: "竹園", value: "竹園" },
          { label: "紅磡", value: "紅磡" },
          { label: "美孚", value: "美孚" },
          { label: "荔枝角", value: "荔枝角" },
          { label: "蘇屋", value: "蘇屋" },
          { label: "觀塘", value: "觀塘" },
          { label: "鑽石山", value: "鑽石山" },
          { label: "長沙灣", value: "長沙灣" },
          { label: "馬頭圍", value: "馬頭圍" },
          { label: "鯉魚門", value: "鯉魚門" },
          { label: "黃埔", value: "黃埔" },
          { label: "黃大仙", value: "黃大仙" },

        ]

      },
      {
        label: "新界",
        options: [
          { label: "上水", value: "上水" },
          { label: "元朗", value: "元朗" },
          { label: "兆康", value: "兆康" },
          { label: "南丫島", value: "南丫島" },
          { label: "坑口", value: "坑口" },
          { label: "坪洲", value: "坪洲" },
          { label: "大圍", value: "大圍" },
          { label: "大埔", value: "大埔" },
          { label: "大嶼山", value: "大嶼山" },
          { label: "大水坑", value: "大水坑" },
          { label: "大窩口", value: "大窩口" },
          { label: "天水圍", value: "天水圍" },
          { label: "太和", value: "太和" },
          { label: "寶琳", value: "寶琳" },
          { label: "將軍澳", value: "將軍澳" },
          { label: "小瀝源", value: "小瀝源" },
          { label: "屯門", value: "屯門" },
          { label: "康城", value: "康城" },
          { label: "恆安", value: "恆安" },
          { label: "愉景灣", value: "愉景灣" },
          { label: "打鼓嶺", value: "打鼓嶺" },
          { label: "朗屏", value: "朗屏" },
          { label: "東涌", value: "東涌" },
          { label: "梨木樹", value: "梨木樹" },
          { label: "欣澳", value: "欣澳" },
          { label: "沙田", value: "沙田" },
          { label: "沙田圍", value: "沙田圍" },
          { label: "沙頭角", value: "沙頭角" },
          { label: "油塘", value: "油塘" },
          { label: "洪水橋", value: "洪水橋" },
          { label: "深井", value: "深井" },
          { label: "清水灣", value: "清水灣" },
          { label: "火炭", value: "火炭" },
          { label: "烏溪沙", value: "烏溪沙" },
          { label: "石籬", value: "石籬" },
          { label: "石蔭", value: "石蔭" },
          { label: "石門", value: "石門" },
          { label: "禾輋", value: "禾輋" },
          { label: "第一城", value: "第一城" },
          { label: "粉嶺", value: "粉嶺" },
          { label: "荃灣", value: "荃灣" },
          { label: "荔景", value: "荔景" },
          { label: "葵涌", value: "葵涌" },
          { label: "葵盛", value: "葵盛" },
          { label: "葵興", value: "葵興" },
          { label: "葵芳", value: "葵芳" },
          { label: "藍田", value: "藍田" },
          { label: "西貢", value: "西貢" },
          { label: "調景嶺", value: "調景嶺" },
          { label: "車公廟", value: "車公廟" },
          { label: "錦上路", value: "錦上路" },
          { label: "長洲", value: "長洲" },
          { label: "青衣", value: "青衣" },
          { label: "青龍頭", value: "青龍頭" },
          { label: "顯徑", value: "顯徑" },
          { label: "馬料水", value: "馬料水" },
          { label: "馬灣", value: "馬灣" },
          { label: "馬鞍山", value: "馬鞍山" },



        ]

      },
    ]
  }


]
export const tutor_gender = [
  '男導師',
  '女導師',
  '男女導師均可'
]
export const tutorLabel = [
  {
    selection: [
      {
        label: "個⼈標籤",
        options: [
          { label: "全職補習導師", value: "全職補習導師" },
          { label: "中學名校", value: "中學名校" },
          { label: "在職教師", value: "在職教師" },
          { label: "曾任教師", value: "曾任教師" },
          { label: "退休教師", value: "退休教師" },
          { label: "補習社老師", value: "補習社老師" },
          { label: "專⾨補底", value: "專⾨補底" },
          { label: "專⾨拔尖", value: "專⾨拔尖" },
          { label: "⾃制筆記", value: "⾃制筆記" },
          { label: "外國回流", value: "外國回流" },
          { label: "Native English Speaker (NET)", value: "Native English Speaker (NET)" },
          { label: "教學助理", value: "教學助理" },
          { label: "已獲証書", value: "已獲証書" },
          { label: "嚴格", value: "嚴格" },
          { label: "有耐性", value: "有耐性" },

        ]

      },



    ]
  }
]

export const chargeList = [
  {
    selection: [
      {
        options: [
          { label: "$80或以上", value: "80" },
          { label: "$100或以上", value: "100" },
          { label: "$110或以上", value: "110" },
          { label: "$120或以上", value: "120" },
          { label: "$130或以上", value: "130" },
          { label: "$140或以上", value: "140" },
          { label: "$150或以上", value: "150" },
          { label: "$160或以上", value: "160" },
          { label: "$170或以上", value: "170" },
          { label: "$180或以上", value: "180" },
          { label: "$190或以上", value: "190" },
          { label: "$200或以上", value: "200" },
          { label: "$250或以上", value: "250" },
          { label: "$300或以上", value: "300" },
          { label: "$350或以上", value: "350" },
          { label: "$400或以上", value: "400" },
        ]

      },



    ]
  }
]
export const tutorGenderList = [
  {
    selection: [
      {
        options: [
          { label: "男導師", value: "男導師" },
          { label: "女導師", value: "女導師" },
          { label: "男女導師均可", value: "男女導師均可" },

        ]

      },



    ]
  }
]
export const gradeList = [
  {
    selection: [
      {
        label: "幼稚園",
        options: [
          { label: "K1", value: 1 },
          { label: "K2", value: 2 },
          { label: "K3", value: 3 },

        ]

      },
      {
        label: "小學",
        options: [
          { label: "小一", value: 4 },
          { label: "小二", value: 5 },
          { label: "小三", value: 6 },
          { label: "小四", value: 7 },
          { label: "小五", value: 8 },
          { label: "小六", value: 9 },

        ]

      },
      {
        label: "中學",
        options: [
          { label: "中一", value: 10 },
          { label: "中二", value: 11 },
          { label: "中三", value: 12 },
          { label: "中四", value: 13 },
          { label: "中五", value: 14 },
          { label: "中六", value: 15 },

        ]

      },



    ]
  }
]

export const gradeListSearch = [
  {
    selection: [
      {
        label: "幼稚園",
        options: [
          { label: "K1或以上", value: 1 },
          { label: "K2或以上", value: 2 },
          { label: "K3或以上", value: 3 },

        ]

      },
      {
        label: "小學",
        options: [
          { label: "小一或以上", value: 4 },
          { label: "小二或以上", value: 5 },
          { label: "小三或以上", value: 6 },
          { label: "小四或以上", value: 7 },
          { label: "小五或以上", value: 8 },
          { label: "小六或以上", value: 9 },

        ]

      },
      {
        label: "中學",
        options: [
          { label: "中一或以上", value: 10 },
          { label: "中二或以上", value: 11 },
          { label: "中三或以上", value: 12 },
          { label: "中四或以上", value: 13 },
          { label: "中五或以上", value: 14 },
          { label: "中六或以上", value: 15 },

        ]

      },



    ]
  }
]

export const tutorWays = [
  {
    selection: [
      { label: "上門", value: "上門" },
      { label: "公共地方", value: "公共地方" },
      { label: "視像", value: "視像" },
      { label: "先視像後上門", value: "先視像後上門" },
    ]
  }
]
export const yes_no = ['是', '否']
export const tutor_requirement = ['大學生', '全職導師', '現職教師', 'Level 5或以上', 'Level 5*或以上', 'Level 5**']
export const lesson_per_week = [1,2,3,4,5,6,7]
export const hour_per_lesson = [1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6]
export const tutor_exp = [
  '少於1年',
  '1年',
  '2年',
  '3年',
  '4年',
  '5年',
  '6年或以上',
]
export const expect_tutor_fee = [
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200,
  210,
  220,
  230,
  240,
  250,
  300,
  350,
  400,
  500,
]
export const tutor_target = [
  '小學',
  '初中 (F.1 - F.3)',
  '高中 (F.4 - F.6)',
  '國際學生'
]

export const tutor_class_type = [
  '上門',
  '不上門',
  '視像補習',

]
export const traffic_way = [
  '地鐵站步行可到達',
  '地鐵站轉乘其他交通工具',
  '不適用'
]
export const medium_choice = [
  '搜尋引擎',
  'Instagram',
  'Facebook',
  '廣告',
  '宣傳單張',
  '朋友介紹',
  '學校推廣',
  'Email',
  '其他',
]

export const seconday_school_list = [
  {
    selection: [
      {
        label: "中學",
        options: [
          { label: "ABERDEEN BAPTIST LUI MING CHOI COLLEGE 香港仔浸信會呂明才書院", value: "ABERDEEN BAPTIST LUI MING CHOI COLLEGE 香港仔浸信會呂明才書院" },
          { label: "ABERDEEN TECHNICAL SCHOOL 香港仔工業學校", value: "ABERDEEN TECHNICAL SCHOOL 香港仔工業學校" },
          { label: "ABRAHAM COLLEGE 學信書院", value: "ABRAHAM COLLEGE 學信書院" },
          { label: "AD&FDPOHL LEUNG SING TAK COLLEGE 博愛醫院歷屆總理聯誼會梁省德中學", value: "AD&FDPOHL LEUNG SING TAK COLLEGE 博愛醫院歷屆總理聯誼會梁省德中學" },
          { label: "AMERICAN INTERNATIONAL SCHOOL 美國國際學校", value: "AMERICAN INTERNATIONAL SCHOOL 美國國際學校" },
          { label: "AMERICAN SCHOOL HONG KONG https://ashk.edu.hk", value: "AMERICAN SCHOOL HONG KONG https://ashk.edu.hk" },
          { label: "AOG HEBRON SECONDARY SCHOOL 神召會康樂中學", value: "AOG HEBRON SECONDARY SCHOOL 神召會康樂中學" },
          { label: "ARTS & TECHNOLOGY EDUCATION CENTRE 藝術與科技教育中心", value: "ARTS & TECHNOLOGY EDUCATION CENTRE 藝術與科技教育中心" },
          { label: "ASSEMBLY OF GOD MORRISON COLLEGE 神召會馬理信書院", value: "ASSEMBLY OF GOD MORRISON COLLEGE 神召會馬理信書院" },
          { label: "AUSTRALIAN INTERNATIONAL SCH HK 香港澳洲國際學校", value: "AUSTRALIAN INTERNATIONAL SCH HK 香港澳洲國際學校" },
          { label: "BAPTIST LUI MING CHOI SECONDARY SCHOOL 浸信會呂明才中學", value: "BAPTIST LUI MING CHOI SECONDARY SCHOOL 浸信會呂明才中學" },
          { label: "BAPTIST WING LUNG SECONDARY SCHOOL 浸信會永隆中學", value: "BAPTIST WING LUNG SECONDARY SCHOOL 浸信會永隆中學" },
          { label: "BEACON COLLEGE 遵理學校", value: "BEACON COLLEGE 遵理學校" },
          { label: "BELILIOS PUBLIC SCHOOL 庇理羅士女子中學", value: "BELILIOS PUBLIC SCHOOL 庇理羅士女子中學" },
          { label: "BETHEL HIGH SCHOOL 伯特利中學", value: "BETHEL HIGH SCHOOL 伯特利中學" },
          { label: "BISHOP HALL JUBILEE SCHOOL 何明華會督銀禧中學", value: "BISHOP HALL JUBILEE SCHOOL 何明華會督銀禧中學" },
          { label: "BUDDHIST FAT HO MEMORIAL COLLEGE 佛教筏可紀念中學", value: "BUDDHIST FAT HO MEMORIAL COLLEGE 佛教筏可紀念中學" },
          { label: "BUDDHIST HO NAM KAM COLLEGE 佛教何南金中學", value: "BUDDHIST HO NAM KAM COLLEGE 佛教何南金中學" },
          { label: "BUDDHIST HUNG SEAN CHAU MEMORIAL COLLEGE 佛教孔仙洲紀念中學", value: "BUDDHIST HUNG SEAN CHAU MEMORIAL COLLEGE 佛教孔仙洲紀念中學" },
          { label: "BUDDHIST KOK KWONG SECONDARY SCHOOL 佛教覺光法師中學", value: "BUDDHIST KOK KWONG SECONDARY SCHOOL 佛教覺光法師中學" },
          { label: "BUDDHIST MAU FUNG MEMORIAL COLLEGE 佛教茂峰法師紀念中學", value: "BUDDHIST MAU FUNG MEMORIAL COLLEGE 佛教茂峰法師紀念中學" },
          { label: "BUDDHIST SIN TAK COLLEGE 佛教善德英文中學", value: "BUDDHIST SIN TAK COLLEGE 佛教善德英文中學" },
          { label: "BUDDHIST SUM HEUNG LAM MEMORIAL COLLEGE 佛教沈香林紀念中學", value: "BUDDHIST SUM HEUNG LAM MEMORIAL COLLEGE 佛教沈香林紀念中學" },
          { label: "BUDDHIST TAI HUNG COLLEGE 佛教大雄中學", value: "BUDDHIST TAI HUNG COLLEGE 佛教大雄中學" },
          { label: "BUDDHIST TAI KWONG CHI HONG COLLEGE 佛教大光慈航中學", value: "BUDDHIST TAI KWONG CHI HONG COLLEGE 佛教大光慈航中學" },
          { label: "BUDDHIST TCFS YEUNG YAT LAM MEMORIAL SCH 道慈佛社楊日霖紀念學校", value: "BUDDHIST TCFS YEUNG YAT LAM MEMORIAL SCH 道慈佛社楊日霖紀念學校" },
          { label: "BUDDHIST WAI YAN MEMORIAL COLLEGE 佛教慧因法師紀念中學", value: "BUDDHIST WAI YAN MEMORIAL COLLEGE 佛教慧因法師紀念中學" },
          { label: "BUDDHIST WONG FUNG LING COLLEGE 佛教黃鳳翎中學", value: "BUDDHIST WONG FUNG LING COLLEGE 佛教黃鳳翎中學" },
          { label: "BUDDHIST WONG WAN TIN COLLEGE 佛教黃允畋中學", value: "BUDDHIST WONG WAN TIN COLLEGE 佛教黃允畋中學" },
          { label: "BUDDHIST YIP KEI NAM MEMORIAL COLLEGE 佛教葉紀南紀念中學", value: "BUDDHIST YIP KEI NAM MEMORIAL COLLEGE 佛教葉紀南紀念中學" },
          { label: "C&MA SUN KEI SECONDARY SCHOOL 基督教宣道會宣基中學", value: "C&MA SUN KEI SECONDARY SCHOOL 基督教宣道會宣基中學" },
          { label: "CANADIAN INTERNATIONAL SCHOOL 加拿大國際學校", value: "CANADIAN INTERNATIONAL SCHOOL 加拿大國際學校" },
          { label: "CANOSSA COLLEGE 嘉諾撒書院", value: "CANOSSA COLLEGE 嘉諾撒書院" },
          { label: "CARITAS CHAI WAN MARDEN FDN SEC SCH 明愛柴灣馬登基金中學", value: "CARITAS CHAI WAN MARDEN FDN SEC SCH 明愛柴灣馬登基金中學" },
          { label: "CARITAS CHAN CHUN HA FIELD STUDIES CTR 明愛陳震夏郊野學園", value: "CARITAS CHAN CHUN HA FIELD STUDIES CTR 明愛陳震夏郊野學園" },
          { label: "CARITAS CHARLES VATH COLLEGE 明愛華德中書院", value: "CARITAS CHARLES VATH COLLEGE 明愛華德中書院" },
          { label: "CARITAS CHONG YUET MING SEC SCH 明愛莊月明中學", value: "CARITAS CHONG YUET MING SEC SCH 明愛莊月明中學" },
          { label: "CARITAS FANLING CHAN CHUN HA SEC SCH 明愛粉嶺陳震夏中學", value: "CARITAS FANLING CHAN CHUN HA SEC SCH 明愛粉嶺陳震夏中學" },
          { label: "CARITAS INST OF COMMUNITY EDU - ABERDEEN 明愛社區書院－香港仔", value: "CARITAS INST OF COMMUNITY EDU - ABERDEEN 明愛社區書院－香港仔" },
          { label: "CARITAS JOCKEY CLUB LOK YAN SCHOOL 明愛賽馬會樂仁學校", value: "CARITAS JOCKEY CLUB LOK YAN SCHOOL 明愛賽馬會樂仁學校" },
          { label: "CARITAS LOK JUN SCHOOL 明愛樂進學校", value: "CARITAS LOK JUN SCHOOL 明愛樂進學校" },
          { label: "CARITAS LOK YI SCHOOL 明愛樂義學校", value: "CARITAS LOK YI SCHOOL 明愛樂義學校" },
          { label: "CARITAS MA ON SHAN SECONDARY SCHOOL 明愛馬鞍山中學", value: "CARITAS MA ON SHAN SECONDARY SCHOOL 明愛馬鞍山中學" },
          { label: "CARITAS MOTHER TERESA SCHOOL 明愛樂恩學校", value: "CARITAS MOTHER TERESA SCHOOL 明愛樂恩學校" },
          { label: "CARITAS PELLETIER SCHOOL 明愛培立學校", value: "CARITAS PELLETIER SCHOOL 明愛培立學校" },
          { label: "CARITAS RESURRECTION SCHOOL 明愛樂群學校", value: "CARITAS RESURRECTION SCHOOL 明愛樂群學校" },
          { label: "CARITAS ST JOSEPH SEC SCH 明愛聖若瑟中學", value: "CARITAS ST JOSEPH SEC SCH 明愛聖若瑟中學" },
          { label: "CARITAS TUEN MUN MARDEN FDN SEC SCH 明愛屯門馬登基金中學", value: "CARITAS TUEN MUN MARDEN FDN SEC SCH 明愛屯門馬登基金中學" },
          { label: "CARITAS WU CHENG-CHUNG SECONDARY SCHOOL 明愛胡振中中學", value: "CARITAS WU CHENG-CHUNG SECONDARY SCHOOL 明愛胡振中中學" },
          { label: "CARITAS YL CHAN CHUN HA SEC SCH 明愛元朗陳震夏中學", value: "CARITAS YL CHAN CHUN HA SEC SCH 明愛元朗陳震夏中學" },
          { label: "CARMEL ALISON LAM FOUNDATION SEC SCH 迦密愛禮信中學", value: "CARMEL ALISON LAM FOUNDATION SEC SCH 迦密愛禮信中學" },
          { label: "CARMEL BUNNAN TONG MEMORIAL SEC SCH 迦密唐賓南紀念中學", value: "CARMEL BUNNAN TONG MEMORIAL SEC SCH 迦密唐賓南紀念中學" },
          { label: "CARMEL DIVINE GRACE FOUNDATION SEC SCH 迦密主恩中學", value: "CARMEL DIVINE GRACE FOUNDATION SEC SCH 迦密主恩中學" },
          { label: "CARMEL HOLY WORD SECONDARY SCHOOL 迦密聖道中學", value: "CARMEL HOLY WORD SECONDARY SCHOOL 迦密聖道中學" },
          { label: "CARMEL PAK U SECONDARY SCHOOL 迦密柏雨中學", value: "CARMEL PAK U SECONDARY SCHOOL 迦密柏雨中學" },
          { label: "CARMEL SCHOOL", value: "CARMEL SCHOOL" },
          { label: "CARMEL SECONDARY SCHOOL 迦密中學", value: "CARMEL SECONDARY SCHOOL 迦密中學" },
          { label: "CATHOLIC MING YUEN SECONDARY SCHOOL 天主教鳴遠中學", value: "CATHOLIC MING YUEN SECONDARY SCHOOL 天主教鳴遠中學" },
          { label: "CCC KEI SHUN SPECIAL SCHOOL 中華基督教會基順學校", value: "CCC KEI SHUN SPECIAL SCHOOL 中華基督教會基順學校" },
          { label: "CCC CHUEN YUEN COLLEGE 中華基督教會全完中學", value: "CCC CHUEN YUEN COLLEGE 中華基督教會全完中學" },
          { label: "CCC FONG YUN WAH SECONDARY SCHOOL 中華基督教會方潤華中學", value: "CCC FONG YUN WAH SECONDARY SCHOOL 中華基督教會方潤華中學" },
          { label: "CCC FUNG LEUNG KIT MEMORIAL SEC SCH 中華基督教會馮梁結紀念中學", value: "CCC FUNG LEUNG KIT MEMORIAL SEC SCH 中華基督教會馮梁結紀念中學" },
          { label: "CCC HEEP WOH COLLEGE 中華基督教會協和書院", value: "CCC HEEP WOH COLLEGE 中華基督教會協和書院" },
          { label: "CCC HOH FUK TONG COLLEGE 中華基督教會何福堂書院", value: "CCC HOH FUK TONG COLLEGE 中華基督教會何福堂書院" },
          { label: "CCC KEI CHI SECONDARY SCHOOL 中華基督教會基智中學", value: "CCC KEI CHI SECONDARY SCHOOL 中華基督教會基智中學" },
          { label: "CCC KEI HEEP SECONDARY SCHOOL 中華基督教會基協中學", value: "CCC KEI HEEP SECONDARY SCHOOL 中華基督教會基協中學" },
          { label: "CCC KEI LONG COLLEGE 中華基督教會基朗中學", value: "CCC KEI LONG COLLEGE 中華基督教會基朗中學" },
          { label: "CCC KEI SAN SECONDARY SCHOOL 中華基督教會基新中學", value: "CCC KEI SAN SECONDARY SCHOOL 中華基督教會基新中學" },
          { label: "CCC KEI TO SEC SCH 中華基督教會基道中學", value: "CCC KEI TO SEC SCH 中華基督教會基道中學" },
          { label: "CCC KEI YUEN COLLEGE 中華基督教會基元中學", value: "CCC KEI YUEN COLLEGE 中華基督教會基元中學" },
          { label: "CCC KUNG LEE COLLEGE 中華基督教會公理高中書院", value: "CCC KUNG LEE COLLEGE 中華基督教會公理高中書院" },
          { label: "CCC KWEI WAH SHAN COLLEGE 中華基督教會桂華山中學", value: "CCC KWEI WAH SHAN COLLEGE 中華基督教會桂華山中學" },
          { label: "CCC MING KEI COLLEGE 中華基督教會銘基書院", value: "CCC MING KEI COLLEGE 中華基督教會銘基書院" },
          { label: "CCC MONG MAN WAI COLLEGE 中華基督教會蒙民偉書院", value: "CCC MONG MAN WAI COLLEGE 中華基督教會蒙民偉書院" },
          { label: "CCC MONGKOK CHURCH KAI OI SCHOOL 中華基督教會望覺堂啓愛學校", value: "CCC MONGKOK CHURCH KAI OI SCHOOL 中華基督教會望覺堂啓愛學校" },
          { label: "CCC ROTARY SECONDARY SCHOOL 中華基督教會扶輪中學", value: "CCC ROTARY SECONDARY SCHOOL 中華基督教會扶輪中學" },
          { label: "CCC TAM LEE LAI FUN MEMORIAL SEC SCH 中華基督教會譚李麗芬紀念中學", value: "CCC TAM LEE LAI FUN MEMORIAL SEC SCH 中華基督教會譚李麗芬紀念中學" },
          { label: "CCC YENCHING COLLEGE 中華基督教會燕京書院", value: "CCC YENCHING COLLEGE 中華基督教會燕京書院" },
          { label: "CHAN SHU KUI MEMORIAL SCHOOL 陳樹渠紀念中學", value: "CHAN SHU KUI MEMORIAL SCHOOL 陳樹渠紀念中學" },
          { label: "CHAN SUI KI (LA SALLE) COLLEGE 陳瑞祺（喇沙）書院", value: "CHAN SUI KI (LA SALLE) COLLEGE 陳瑞祺（喇沙）書院" },
          { label: "CHENG CHEK CHEE SEC SCH OF SK&HH DIST NT 新界西貢坑口區鄭植之中學", value: "CHENG CHEK CHEE SEC SCH OF SK&HH DIST NT 新界西貢坑口區鄭植之中學" },
          { label: "CHEUNG CHAU GOVERNMENT SECONDARY SCHOOL 長洲官立中學", value: "CHEUNG CHAU GOVERNMENT SECONDARY SCHOOL 長洲官立中學" },
          { label: "CHEUNG CHUK SHAN COLLEGE 張祝珊英文中學", value: "CHEUNG CHUK SHAN COLLEGE 張祝珊英文中學" },
          { label: "CHEUNG SHA WAN CATHOLIC SECONDARY SCHOOL 長沙灣天主教英文中學", value: "CHEUNG SHA WAN CATHOLIC SECONDARY SCHOOL 長沙灣天主教英文中學" },
          { label: "CHI LIN BUDDHIST SECONDARY SCHOOL 佛教志蓮中學", value: "CHI LIN BUDDHIST SECONDARY SCHOOL 佛教志蓮中學" },
          { label: "CHI YUN SCHOOL 慈恩學校", value: "CHI YUN SCHOOL 慈恩學校" },
          { label: "CHINA HOLINESS CHURCH LIVING SPIRIT COLL 中華聖潔會靈風中學", value: "CHINA HOLINESS CHURCH LIVING SPIRIT COLL 中華聖潔會靈風中學" },
          { label: "CHINA HOLINESS COLLEGE 中聖書院", value: "CHINA HOLINESS COLLEGE 中聖書院" },
          { label: "CHINESE INTERNATIONAL SCHOOL 漢基國際學校", value: "CHINESE INTERNATIONAL SCHOOL 漢基國際學校" },
          { label: "CHINESE YMCA COLLEGE 青年會書院", value: "CHINESE YMCA COLLEGE 青年會書院" },
          { label: "CHINESE YMCA SECONDARY SCHOOL 中華基督教青年會中學", value: "CHINESE YMCA SECONDARY SCHOOL 中華基督教青年會中學" },
          { label: "CHING CHUNG HAU PO WOON SEC SCH 青松侯寶垣中學", value: "CHING CHUNG HAU PO WOON SEC SCH 青松侯寶垣中學" },
          { label: "CHIU CHOW ASSOCIATION SECONDARY SCHOOL 潮州會館中學", value: "CHIU CHOW ASSOCIATION SECONDARY SCHOOL 潮州會館中學" },
          { label: "CHIU LUT SAU MEMORIAL SECONDARY SCHOOL 趙聿修紀念中學", value: "CHIU LUT SAU MEMORIAL SECONDARY SCHOOL 趙聿修紀念中學" },
          { label: "CHOI HUNG ESTATE CATHOLIC SEC SCH 彩虹邨天主教英文中學", value: "CHOI HUNG ESTATE CATHOLIC SEC SCH 彩虹邨天主教英文中學" },
          { label: "CHOI JUN SCHOOL 才俊學校", value: "CHOI JUN SCHOOL 才俊學校" },
          { label: "CHONG GENE HANG COLLEGE 張振興伉儷書院", value: "CHONG GENE HANG COLLEGE 張振興伉儷書院" },
          { label: "CHRIST COLLEGE 基督書院", value: "CHRIST COLLEGE 基督書院" },
          { label: "CHRISTIAN ALLIANCE CHENG WING GEE COLL 香港九龍塘基督教中華宣道會鄭榮之中學", value: "CHRISTIAN ALLIANCE CHENG WING GEE COLL 香港九龍塘基督教中華宣道會鄭榮之中學" },
          { label: "CHRISTIAN ALLIANCE COLLEGE 宣道中學", value: "CHRISTIAN ALLIANCE COLLEGE 宣道中學" },
          { label: "CHRISTIAN ALLIANCE INTERNATIONAL SCHOOL 宣道國際學校", value: "CHRISTIAN ALLIANCE INTERNATIONAL SCHOOL 宣道國際學校" },
          { label: "CHRISTIAN ALLIANCE S C CHAN MEM COLL 香港九龍塘基督教中華宣道會陳瑞芝紀念中學", value: "CHRISTIAN ALLIANCE S C CHAN MEM COLL 香港九龍塘基督教中華宣道會陳瑞芝紀念中學" },
          { label: "CHRISTIAN ALLIANCE S W CHAN MEM COLL 宣道會陳朱素華紀念中學", value: "CHRISTIAN ALLIANCE S W CHAN MEM COLL 宣道會陳朱素華紀念中學" },
          { label: "CHRISTIAN ZHENG SHENG COLLEGE 基督教正生書院", value: "CHRISTIAN ZHENG SHENG COLLEGE 基督教正生書院" },
          { label: "CHUN TOK SCHOOL 真鐸學校", value: "CHUN TOK SCHOOL 真鐸學校" },
          { label: "CLEMENTI SECONDARY SCHOOL 金文泰中學", value: "CLEMENTI SECONDARY SCHOOL 金文泰中學" },
          { label: "CMA CHOI CHEUNG KOK SECONDARY SCHOOL 廠商會蔡章閣中學", value: "CMA CHOI CHEUNG KOK SECONDARY SCHOOL 廠商會蔡章閣中學" },
          { label: "CMA SECONDARY SCHOOL 廠商會中學", value: "CMA SECONDARY SCHOOL 廠商會中學" },
          { label: "CNEC CHRISTIAN COLLEGE 中華傳道會安柱中學", value: "CNEC CHRISTIAN COLLEGE 中華傳道會安柱中學" },
          { label: "CNEC LAU WING SANG SECONDARY SCH 中華傳道會劉永生中學", value: "CNEC LAU WING SANG SECONDARY SCH 中華傳道會劉永生中學" },
          { label: "CNEC LEE I YAO MEMORIAL SECONDARY SCHOOL 中華傳道會李賢堯紀念中學", value: "CNEC LEE I YAO MEMORIAL SECONDARY SCHOOL 中華傳道會李賢堯紀念中學" },
          { label: "COGNITIO COLLEGE (HONG KONG) 文理書院（香港）", value: "COGNITIO COLLEGE (HONG KONG) 文理書院（香港）" },
          { label: "COGNITIO COLLEGE (KOWLOON) 文理書院（九龍）", value: "COGNITIO COLLEGE (KOWLOON) 文理書院（九龍）" },
          { label: "CONCORDIA INTERNATIONAL SCHOOL 協同國際學校", value: "CONCORDIA INTERNATIONAL SCHOOL 協同國際學校" },
          { label: "CONCORDIA LUTHERAN SCHOOL 路德會協同中學", value: "CONCORDIA LUTHERAN SCHOOL 路德會協同中學" },
          { label: "CONCORDIA LUTHERAN SCHOOL - NORTH POINT 北角協同中學", value: "CONCORDIA LUTHERAN SCHOOL - NORTH POINT 北角協同中學" },
          { label: "CONFUCIAN TAI SHING HO KWOK PUI CHUN COL 孔教學院大成何郭佩珍中學", value: "CONFUCIAN TAI SHING HO KWOK PUI CHUN COL 孔教學院大成何郭佩珍中學" },
          { label: "CONFUCIUS HALL SECONDARY SCHOOL 孔聖堂中學", value: "CONFUCIUS HALL SECONDARY SCHOOL 孔聖堂中學" },
          { label: "COTTON SPINNERS ASSOCIATION SEC SCHOOL 棉紡會中學", value: "COTTON SPINNERS ASSOCIATION SEC SCHOOL 棉紡會中學" },
          { label: "CREATIVE SECONDARY SCHOOL 啓思中學", value: "CREATIVE SECONDARY SCHOOL 啓思中學" },
          { label: "CSBS MRS AW BOON HAW SEC SCH 鐘聲慈善社胡陳金枝中學", value: "CSBS MRS AW BOON HAW SEC SCH 鐘聲慈善社胡陳金枝中學" },
          { label: "CUHKFAA CHAN CHUN HA SECONDARY SCHOOL 香港中文大學校友會聯會陳震夏中學", value: "CUHKFAA CHAN CHUN HA SECONDARY SCHOOL 香港中文大學校友會聯會陳震夏中學" },
          { label: "CUHKFAA THOMAS CHEUNG SECONDARY SCHOOL 香港中文大學校友會聯會張煊昌中學", value: "CUHKFAA THOMAS CHEUNG SECONDARY SCHOOL 香港中文大學校友會聯會張煊昌中學" },
          { label: "CUMBERLAND PRESBYTN CH YAO DAO SEC SCH 金巴崙長老會耀道中學", value: "CUMBERLAND PRESBYTN CH YAO DAO SEC SCH 金巴崙長老會耀道中學" },
          { label: "DE LA SALLE SECONDARY SCHOOL N T 新界喇沙中學", value: "DE LA SALLE SECONDARY SCHOOL N T 新界喇沙中學" },
          { label: "DELIA MEMORIAL SCHOOL (BROADWAY) 地利亞修女紀念學校﹝百老匯﹞", value: "DELIA MEMORIAL SCHOOL (BROADWAY) 地利亞修女紀念學校﹝百老匯﹞" },
          { label: "DELIA MEMORIAL SCHOOL (GLEE PATH) 地利亞修女紀念學校（吉利徑）", value: "DELIA MEMORIAL SCHOOL (GLEE PATH) 地利亞修女紀念學校（吉利徑）" },
          { label: "DELIA MEMORIAL SCHOOL (HIP WO) 地利亞修女紀念學校（協和）", value: "DELIA MEMORIAL SCHOOL (HIP WO) 地利亞修女紀念學校（協和）" },
          { label: "DELIA MEMORIAL SCHOOL (MATTEO RICCI) 地利亞修女紀念學校（利瑪竇）", value: "DELIA MEMORIAL SCHOOL (MATTEO RICCI) 地利亞修女紀念學校（利瑪竇）" },
          { label: "DELIA MEMORIAL SCHOOL (YUET WAH) 地利亞修女紀念學校（月華）", value: "DELIA MEMORIAL SCHOOL (YUET WAH) 地利亞修女紀念學校（月華）" },
          { label: "DELIA SCHOOL OF CANADA 地利亞（加拿大）學校", value: "DELIA SCHOOL OF CANADA 地利亞（加拿大）學校" },
          { label: "DIOCESAN BOYS' SCHOOL 拔萃男書院", value: "DIOCESAN BOYS' SCHOOL 拔萃男書院" },
          { label: "DIOCESAN GIRLS' SCHOOL 拔萃女書院", value: "DIOCESAN GIRLS' SCHOOL 拔萃女書院" },
          { label: "DISCOVERY BAY INTERNATIONAL SCHOOL https://www.dbis.edu.hk/", value: "DISCOVERY BAY INTERNATIONAL SCHOOL https://www.dbis.edu.hk/" },
          { label: "DISCOVERY COLLEGE 智新書院", value: "DISCOVERY COLLEGE 智新書院" },
          { label: "DMHC SIU MING CATHOLIC SEC SCH 天主教母佑會蕭明中學", value: "DMHC SIU MING CATHOLIC SEC SCH 天主教母佑會蕭明中學" },
          { label: "EBENEZER NEW HOPE SCHOOL 心光恩望學校", value: "EBENEZER NEW HOPE SCHOOL 心光恩望學校" },
          { label: "EBENEZER SCHOOL 心光學校", value: "EBENEZER SCHOOL 心光學校" },
          { label: "ECF SAINT TOO CANAAN COLLEGE 基督教中國佈道會聖道迦南書院", value: "ECF SAINT TOO CANAAN COLLEGE 基督教中國佈道會聖道迦南書院" },
          { label: "ELCHK LUTHERAN ACADEMY 基督教香港信義會宏信書院", value: "ELCHK LUTHERAN ACADEMY 基督教香港信義會宏信書院" },
          { label: "ELCHK LUTHERAN SECONDARY SCHOOL 基督教香港信義會信義中學", value: "ELCHK LUTHERAN SECONDARY SCHOOL 基督教香港信義會信義中學" },
          { label: "ELCHK YL LUTHERAN SECONDARY SCHOOL 基督教香港信義會元朗信義中學", value: "ELCHK YL LUTHERAN SECONDARY SCHOOL 基督教香港信義會元朗信義中學" },
          { label: "ELEGANTIA COLLEGE (SPONSORED BY ED CONV) 風采中學（教育評議會主辦）", value: "ELEGANTIA COLLEGE (SPONSORED BY ED CONV) 風采中學（教育評議會主辦）" },
          { label: "EVAN CHINA FELLOWSHIP HOLY WORD SCH 基督教中國佈道會聖道學校", value: "EVAN CHINA FELLOWSHIP HOLY WORD SCH 基督教中國佈道會聖道學校" },
          { label: "EVANGEL COLLEGE 播道書院", value: "EVANGEL COLLEGE 播道書院" },
          { label: "FANLING GOVERNMENT SECONDARY SCHOOL 粉嶺官立中學", value: "FANLING GOVERNMENT SECONDARY SCHOOL 粉嶺官立中學" },
          { label: "FANLING KAU YAN COLLEGE 粉嶺救恩書院", value: "FANLING KAU YAN COLLEGE 粉嶺救恩書院" },
          { label: "FANLING LUTHERAN SECONDARY SCHOOL 基督教香港信義會心誠中學", value: "FANLING LUTHERAN SECONDARY SCHOOL 基督教香港信義會心誠中學" },
          { label: "FANLING RHENISH CHURCH SECONDARY SCHOOL 粉嶺禮賢會中學", value: "FANLING RHENISH CHURCH SECONDARY SCHOOL 粉嶺禮賢會中學" },
          { label: "FDBWA SZETO HO SECONDARY SCHOOL 五邑司徒浩中學", value: "FDBWA SZETO HO SECONDARY SCHOOL 五邑司徒浩中學" },
          { label: "FORTRESS HILL METHODIST SECONDARY SCHOOL 炮台山循道衛理中學", value: "FORTRESS HILL METHODIST SECONDARY SCHOOL 炮台山循道衛理中學" },
          { label: "FUKIEN SECONDARY SCHOOL 福建中學", value: "FUKIEN SECONDARY SCHOOL 福建中學" },
          { label: "FUKIEN SECONDARY SCHOOL (SIU SAI WAN) 福建中學（小西灣）", value: "FUKIEN SECONDARY SCHOOL (SIU SAI WAN) 福建中學（小西灣）" },
          { label: "FUNG KAI LIU MAN SHEK TONG SEC SCH 鳳溪廖萬石堂中學", value: "FUNG KAI LIU MAN SHEK TONG SEC SCH 鳳溪廖萬石堂中學" },
          { label: "FUNG KAI NO.1 SECONDARY SCHOOL 鳳溪第一中學", value: "FUNG KAI NO.1 SECONDARY SCHOOL 鳳溪第一中學" },
          { label: "G. T. (ELLEN YEUNG) COLLEGE 優才（楊殷有娣）書院", value: "G. T. (ELLEN YEUNG) COLLEGE 優才（楊殷有娣）書院" },
          { label: "GCC&ITKD LAU PAK LOK SEC SCH 東莞工商總會劉百樂中學", value: "GCC&ITKD LAU PAK LOK SEC SCH 東莞工商總會劉百樂中學" },
          { label: "GERMAN SWISS INTERNATIONAL SCHOOL https://www.gsis.edu.hk/", value: "GERMAN SWISS INTERNATIONAL SCHOOL https://www.gsis.edu.hk/" },
          { label: "GERTRUDE SIMON LUTHERAN COLLEGE 路德會西門英才中學", value: "GERTRUDE SIMON LUTHERAN COLLEGE 路德會西門英才中學" },
          { label: "GOOD HOPE SCHOOL 德望學校", value: "GOOD HOPE SCHOOL 德望學校" },
          { label: "HAN ACADEMY 漢鼎書院", value: "HAN ACADEMY 漢鼎書院" },
          { label: "HARROW INTERNATIONAL SCHOOL HONG KONG 哈羅香港國際學校", value: "HARROW INTERNATIONAL SCHOOL HONG KONG 哈羅香港國際學校" },
          { label: "HAVEN OF HOPE SUNNYSIDE SCHOOL 靈實恩光學校", value: "HAVEN OF HOPE SUNNYSIDE SCHOOL 靈實恩光學校" },
          { label: "HEEP YUNN SCHOOL 協恩中學", value: "HEEP YUNN SCHOOL 協恩中學" },
          { label: "HELEN LIANG MEMORIAL SEC SCH (SHATIN) 梁文燕紀念中學（沙田）", value: "HELEN LIANG MEMORIAL SEC SCH (SHATIN) 梁文燕紀念中學（沙田）" },
          { label: "HENRIETTA SECONDARY SCHOOL 顯理中學", value: "HENRIETTA SECONDARY SCHOOL 顯理中學" },
          { label: "HEUNG TO MIDDLE SCHOOL 香島中學", value: "HEUNG TO MIDDLE SCHOOL 香島中學" },
          { label: "HEUNG TO MIDDLE SCHOOL (TIN SHUI WAI) 天水圍香島中學", value: "HEUNG TO MIDDLE SCHOOL (TIN SHUI WAI) 天水圍香島中學" },
          { label: "HEUNG TO SEC SCHOOL (TSEUNG KWAN O) 將軍澳香島中學", value: "HEUNG TO SEC SCHOOL (TSEUNG KWAN O) 將軍澳香島中學" },
          { label: "HHCKLA BUDDHIST CHING KOK SEC SCH 香海正覺蓮社佛教正覺中學", value: "HHCKLA BUDDHIST CHING KOK SEC SCH 香海正覺蓮社佛教正覺中學" },
          { label: "HHCKLA BUDDHIST LEUNG CHIK WAI COLLEGE 香海正覺蓮社佛教梁植偉中學", value: "HHCKLA BUDDHIST LEUNG CHIK WAI COLLEGE 香海正覺蓮社佛教梁植偉中學" },
          { label: "HHCKLA BUDDHIST MA KAM CHAN MEM ENG SE S 香海正覺蓮社佛教馬錦燦紀念英文中學", value: "HHCKLA BUDDHIST MA KAM CHAN MEM ENG SE S 香海正覺蓮社佛教馬錦燦紀念英文中學" },
          { label: "HHCKLA BUDDHIST PO KWONG SCHOOL 香海正覺蓮社佛教普光學校", value: "HHCKLA BUDDHIST PO KWONG SCHOOL 香海正覺蓮社佛教普光學校" },
          { label: "HK & KLN CHIU CHOW PUB ASSN SEC SCHOOL 港九潮州公會中學", value: "HK & KLN CHIU CHOW PUB ASSN SEC SCHOOL 港九潮州公會中學" },
          { label: "HK & KLN KFWA SUN FONG CHUNG COLLEGE 港九街坊婦女會孫方中書院", value: "HK & KLN KFWA SUN FONG CHUNG COLLEGE 港九街坊婦女會孫方中書院" },
          { label: "HK AND MACAU LU CH QUEEN MAUD SEC SCH 港澳信義會慕德中學", value: "HK AND MACAU LU CH QUEEN MAUD SEC SCH 港澳信義會慕德中學" },
          { label: "HK JUV CARE CTR CHAN NAM CHEONG MEM SCH 香港青少年培育會陳南昌紀念學校", value: "HK JUV CARE CTR CHAN NAM CHEONG MEM SCH 香港青少年培育會陳南昌紀念學校" },
          { label: "HK RED CROSS JOHN F KENNEDY CENTRE 香港紅十字會甘迺迪中心", value: "HK RED CROSS JOHN F KENNEDY CENTRE 香港紅十字會甘迺迪中心" },
          { label: "HK RED CROSS MARGARET TRENCH SCHOOL 香港紅十字會瑪嘉烈戴麟趾學校", value: "HK RED CROSS MARGARET TRENCH SCHOOL 香港紅十字會瑪嘉烈戴麟趾學校" },
          { label: "HK RED CROSS PRINCESS ALEXANDRA SCHOOL 香港紅十字會雅麗珊郡主學校", value: "HK RED CROSS PRINCESS ALEXANDRA SCHOOL 香港紅十字會雅麗珊郡主學校" },
          { label: "HK SKH BISHOP HALL SECONDARY SCHOOL 香港聖公會何明華會督中學", value: "HK SKH BISHOP HALL SECONDARY SCHOOL 香港聖公會何明華會督中學" },
          { label: "HK TEACHERS' ASSN LEE HENG KWEI SEC SCH 香港教師會李興貴中學", value: "HK TEACHERS' ASSN LEE HENG KWEI SEC SCH 香港教師會李興貴中學" },
          { label: "HKBUAS WONG KAM FAI SEC & PRI SCH 香港浸會大學附屬學校王錦輝中小學", value: "HKBUAS WONG KAM FAI SEC & PRI SCH 香港浸會大學附屬學校王錦輝中小學" },
          { label: "HKCCC UNION LOGOS ACADEMY 香港華人基督教聯會真道書院", value: "HKCCC UNION LOGOS ACADEMY 香港華人基督教聯會真道書院" },
          { label: "HKCWC FUNG YIU KING MEM SEC SCH 香港中國婦女會馮堯敬紀念中學", value: "HKCWC FUNG YIU KING MEM SEC SCH 香港中國婦女會馮堯敬紀念中學" },
          { label: "HKFEW WONG CHO BAU SECONDARY SCHOOL 香港教育工作者聯會黃楚標中學", value: "HKFEW WONG CHO BAU SECONDARY SCHOOL 香港教育工作者聯會黃楚標中學" },
          { label: "HKFYG LEE SHAU KEE COLLEGE 香港青年協會李兆基書院", value: "HKFYG LEE SHAU KEE COLLEGE 香港青年協會李兆基書院" },
          { label: "HKICC LEE SHAU KEE SCH OF CREATIVITY 香港兆基創意書院（李兆基基金會贊助、香港當代文化中心主辦）", value: "HKICC LEE SHAU KEE SCH OF CREATIVITY 香港兆基創意書院（李兆基基金會贊助、香港當代文化中心主辦）" },
          { label: "HKMA DAVID LI KWOK PO COLLEGE 香港管理專業協會李國寶中學", value: "HKMA DAVID LI KWOK PO COLLEGE 香港管理專業協會李國寶中學" },
          { label: "HKMA K S LO COLLEGE 香港管理專業協會羅桂祥中學", value: "HKMA K S LO COLLEGE 香港管理專業協會羅桂祥中學" },
          { label: "HKRSS TAI PO SECONDARY SCHOOL 香港紅卍字會大埔卍慈中學", value: "HKRSS TAI PO SECONDARY SCHOOL 香港紅卍字會大埔卍慈中學" },
          { label: "HKSYC&IA CHAN NAM CHONG MEMORIAL COLL 香港四邑商工總會陳南昌紀念中學", value: "HKSYC&IA CHAN NAM CHONG MEMORIAL COLL 香港四邑商工總會陳南昌紀念中學" },
          { label: "HKSYC&IA CHAN NAM CHONG MEMORIAL SCHOOL 香港四邑商工總會陳南昌紀念學校", value: "HKSYC&IA CHAN NAM CHONG MEMORIAL SCHOOL 香港四邑商工總會陳南昌紀念學校" },
          { label: "HKSYC&IA WONG TAI SHAN MEM COLL 香港四邑商工總會黃棣珊紀念中學", value: "HKSYC&IA WONG TAI SHAN MEM COLL 香港四邑商工總會黃棣珊紀念中學" },
          { label: "HKTA CHING CHUNG SECONDARY SCHOOL 香港道教聯合會青松中學", value: "HKTA CHING CHUNG SECONDARY SCHOOL 香港道教聯合會青松中學" },
          { label: "HKTA TANG HIN MEMORIAL SECONDARY SCHOOL 香港道教聯合會鄧顯紀念中學", value: "HKTA TANG HIN MEMORIAL SECONDARY SCHOOL 香港道教聯合會鄧顯紀念中學" },
          { label: "HKTA THE YUEN YUEN INT NO 3 SEC SCH 香港道教聯合會圓玄學院第三中學", value: "HKTA THE YUEN YUEN INT NO 3 SEC SCH 香港道教聯合會圓玄學院第三中學" },
          { label: "HKTA THE YUEN YUEN INT NO.1 SEC SCH 香港道教聯合會圓玄學院第一中學", value: "HKTA THE YUEN YUEN INT NO.1 SEC SCH 香港道教聯合會圓玄學院第一中學" },
          { label: "HKTA THE YUEN YUEN INT NO.2 SEC SCH 香港道教聯合會圓玄學院第二中學", value: "HKTA THE YUEN YUEN INT NO.2 SEC SCH 香港道教聯合會圓玄學院第二中學" },
          { label: "HKUGA COLLEGE 港大同學會書院", value: "HKUGA COLLEGE 港大同學會書院" },
          { label: "HKWMA CHU SHEK LUN SECONDARY SCHOOL 香港布廠商會朱石麟中學", value: "HKWMA CHU SHEK LUN SECONDARY SCHOOL 香港布廠商會朱石麟中學" },
          { label: "HO DAO COLL (SPONSORED BY SIK SIK YUEN) 可道中學（嗇色園主辦）", value: "HO DAO COLL (SPONSORED BY SIK SIK YUEN) 可道中學（嗇色園主辦）" },
          { label: "HO FUNG COLL (SPONSORED BY SIK SIK YUEN) 可風中學（嗇色園主辦）", value: "HO FUNG COLL (SPONSORED BY SIK SIK YUEN) 可風中學（嗇色園主辦）" },
          { label: "HO KOON NATURE EDU CUM ASTRONOMICAL CTR 可觀自然教育中心暨天文館", value: "HO KOON NATURE EDU CUM ASTRONOMICAL CTR 可觀自然教育中心暨天文館" },
          { label: "HO LAP COLL (SPSD BY THE SIK SIK YUEN) 可立中學（嗇色園主辦）", value: "HO LAP COLL (SPSD BY THE SIK SIK YUEN) 可立中學（嗇色園主辦）" },
          { label: "HO NGAI COLL (SPONSORED BY SIK SIK YUEN) 嗇色園主辦可藝中學", value: "HO NGAI COLL (SPONSORED BY SIK SIK YUEN) 嗇色園主辦可藝中學" },
          { label: "HO YU COLL & PRI (SPON BY SIK SIK YUEN) 嗇色園主辦可譽中學暨可譽小學", value: "HO YU COLL & PRI (SPON BY SIK SIK YUEN) 嗇色園主辦可譽中學暨可譽小學" },
          { label: "HOI PING CHAMBER OF COMMERCE SEC SCH 旅港開平商會中學", value: "HOI PING CHAMBER OF COMMERCE SEC SCH 旅港開平商會中學" },
          { label: "HOLY FAMILY CANOSSIAN COLLEGE 嘉諾撒聖家書院", value: "HOLY FAMILY CANOSSIAN COLLEGE 嘉諾撒聖家書院" },
          { label: "HOLY TRINITY COLLEGE 寶血會上智英文書院", value: "HOLY TRINITY COLLEGE 寶血會上智英文書院" },
          { label: "HOMANTIN GOVERNMENT SECONDARY SCHOOL 何文田官立中學", value: "HOMANTIN GOVERNMENT SECONDARY SCHOOL 何文田官立中學" },
          { label: "HON WAH COLLEGE 漢華中學", value: "HON WAH COLLEGE 漢華中學" },
          { label: "HONG CHI MORNINGJOY SCHOOL 匡智元朗晨樂學校", value: "HONG CHI MORNINGJOY SCHOOL 匡智元朗晨樂學校" },
          { label: "HONG CHI MORNINGLIGHT SCHOOL 匡智屯門晨曦學校", value: "HONG CHI MORNINGLIGHT SCHOOL 匡智屯門晨曦學校" },
          { label: "HONG CHI LIONS MORNINGHILL SCHOOL 匡智獅子會晨崗學校", value: "HONG CHI LIONS MORNINGHILL SCHOOL 匡智獅子會晨崗學校" },
          { label: "HONG CHI MORNINGHILL SCHOOL 匡智翠林晨崗學校", value: "HONG CHI MORNINGHILL SCHOOL 匡智翠林晨崗學校" },
          { label: "HONG CHI MORNINGHILL SCHOOL 匡智屯門晨崗學校", value: "HONG CHI MORNINGHILL SCHOOL 匡智屯門晨崗學校" },
          { label: "HONG CHI MORNINGHOPE SCHOOL 匡智屯門晨輝學校", value: "HONG CHI MORNINGHOPE SCHOOL 匡智屯門晨輝學校" },
          { label: "HONG CHI MORNINGLIGHT SCHOOL 匡智元朗晨曦學校", value: "HONG CHI MORNINGLIGHT SCHOOL 匡智元朗晨曦學校" },
          { label: "HONG CHI PINEHILL NO.2 SCHOOL 匡智松嶺第二校", value: "HONG CHI PINEHILL NO.2 SCHOOL 匡智松嶺第二校" },
          { label: "HONG CHI PINEHILL NO.3 SCHOOL 匡智松嶺第三校", value: "HONG CHI PINEHILL NO.3 SCHOOL 匡智松嶺第三校" },
          { label: "HONG CHI PINEHILL SCHOOL 匡智松嶺學校", value: "HONG CHI PINEHILL SCHOOL 匡智松嶺學校" },
          { label: "HONG CHI WINIFRED MARY CHEUNG MORN SCH 匡智張玉瓊晨輝學校", value: "HONG CHI WINIFRED MARY CHEUNG MORN SCH 匡智張玉瓊晨輝學校" },
          { label: "HONG KONG ACADEMY 香港學堂國際學校", value: "HONG KONG ACADEMY 香港學堂國際學校" },
          { label: "HONG KONG ADVENTIST ACADEMY 香港復臨學校", value: "HONG KONG ADVENTIST ACADEMY 香港復臨學校" },
          { label: "HONG KONG CHINESE WOMEN'S CLUB COLLEGE 香港中國婦女會中學", value: "HONG KONG CHINESE WOMEN'S CLUB COLLEGE 香港中國婦女會中學" },
          { label: "HONG KONG CHRISTIAN SERVICE PUI OI SCH 香港基督教服務處培愛學校", value: "HONG KONG CHRISTIAN SERVICE PUI OI SCH 香港基督教服務處培愛學校" },
          { label: "HONG KONG INTERNATIONAL SCHOOL https://www.hkis.edu.hk/", value: "HONG KONG INTERNATIONAL SCHOOL https://www.hkis.edu.hk/" },
          { label: "HONG KONG RED CROSS HOSPITAL SCHOOLS 香港紅十字會醫院學校", value: "HONG KONG RED CROSS HOSPITAL SCHOOLS 香港紅十字會醫院學校" },
          { label: "HONG KONG SEA SCHOOL 香港航海學校", value: "HONG KONG SEA SCHOOL 香港航海學校" },
          { label: "HONG KONG TANG KING PO COLLEGE 香港鄧鏡波書院", value: "HONG KONG TANG KING PO COLLEGE 香港鄧鏡波書院" },
          { label: "HONG KONG TRUE LIGHT COLLEGE 香港真光書院", value: "HONG KONG TRUE LIGHT COLLEGE 香港真光書院" },
          { label: "HONGKONG JAPANESE SCHOOL https://www.hkjs.edu.hk/", value: "HONGKONG JAPANESE SCHOOL https://www.hkjs.edu.hk/" },
          { label: "HOTUNG SECONDARY SCHOOL 何東中學", value: "HOTUNG SECONDARY SCHOOL 何東中學" },
          { label: "IMMACULATE HEART OF MARY COLLEGE 聖母無玷聖心書院", value: "IMMACULATE HEART OF MARY COLLEGE 聖母無玷聖心書院" },
          { label: "INDEPENDENT SCHOOLS FOUNDATION ACADEMY 弘立書院", value: "INDEPENDENT SCHOOLS FOUNDATION ACADEMY 弘立書院" },
          { label: "INTERNATIONAL CHRISTIAN SCHOOL 基督教國際學校", value: "INTERNATIONAL CHRISTIAN SCHOOL 基督教國際學校" },
          { label: "INTERNATIONAL COLLEGE HONG KONG (NT) https://www.ichk.edu.hk/", value: "INTERNATIONAL COLLEGE HONG KONG (NT) https://www.ichk.edu.hk/" },
          { label: "INTL CHR QUALITY MUSIC SEC & PRI SCHOOL 國際基督教優質音樂中學暨小學", value: "INTL CHR QUALITY MUSIC SEC & PRI SCHOOL 國際基督教優質音樂中學暨小學" },
          { label: "ISLAMIC KASIM TUET MEMORIAL COLLEGE 伊斯蘭脫維善紀念中學", value: "ISLAMIC KASIM TUET MEMORIAL COLLEGE 伊斯蘭脫維善紀念中學" },
          { label: "ISLAND SCHOOL https://www.island.edu.hk", value: "ISLAND SCHOOL https://www.island.edu.hk" },
          { label: "JOCKEY CLUB EDUYOUNG COLLEGE 賽馬會毅智書院", value: "JOCKEY CLUB EDUYOUNG COLLEGE 賽馬會毅智書院" },
          { label: "JOCKEY CLUB GOVERNMENT SEC SCH 賽馬會官立中學", value: "JOCKEY CLUB GOVERNMENT SEC SCH 賽馬會官立中學" },
          { label: "JOCKEY CLUB HONG CHI SCHOOL 賽馬會匡智學校", value: "JOCKEY CLUB HONG CHI SCHOOL 賽馬會匡智學校" },
          { label: "JOCKEY CLUB SARAH ROE SCHOOL 賽馬會善樂學校", value: "JOCKEY CLUB SARAH ROE SCHOOL 賽馬會善樂學校" },
          { label: "JOCKEY CLUB TI-I COLLEGE 賽馬會體藝中學", value: "JOCKEY CLUB TI-I COLLEGE 賽馬會體藝中學" },
          { label: "JU CHING CHU SEC SCH (KWAI CHUNG) 裘錦秋中學（葵涌）", value: "JU CHING CHU SEC SCH (KWAI CHUNG) 裘錦秋中學（葵涌）" },
          { label: "JU CHING CHU SEC SCH (YUEN LONG) 裘錦秋中學（元朗）", value: "JU CHING CHU SEC SCH (YUEN LONG) 裘錦秋中學（元朗）" },
          { label: "JU CHING CHU SECONDARY SCHOOL (TUEN MUN) 裘錦秋中學﹝屯門﹞", value: "JU CHING CHU SECONDARY SCHOOL (TUEN MUN) 裘錦秋中學﹝屯門﹞" },
          { label: "KAU YAN COLLEGE 救恩書院", value: "KAU YAN COLLEGE 救恩書院" },
          { label: "KELLETT SCHOOL", value: "KELLETT SCHOOL" },
          { label: "KIANGSU-CHEKIANG COLLEGE 蘇浙公學", value: "KIANGSU-CHEKIANG COLLEGE 蘇浙公學" },
          { label: "KIANGSU-CHEKIANG COLLEGE (KWAI CHUNG) 葵涌蘇浙公學", value: "KIANGSU-CHEKIANG COLLEGE (KWAI CHUNG) 葵涌蘇浙公學" },
          { label: "KIANGSU-CHEKIANG COLLEGE (SHATIN) 沙田蘇浙公學", value: "KIANGSU-CHEKIANG COLLEGE (SHATIN) 沙田蘇浙公學" },
          { label: "KING GEORGE V SCHOOL https://www.kgv.edu.hk/", value: "KING GEORGE V SCHOOL https://www.kgv.edu.hk/" },
          { label: "KING LING COLLEGE 景嶺書院", value: "KING LING COLLEGE 景嶺書院" },
          { label: "KING'S COLLEGE 英皇書院", value: "KING'S COLLEGE 英皇書院" },
          { label: "KIT SAM LAM BING YIM SECONDARY SCHOOL 潔心林炳炎中學", value: "KIT SAM LAM BING YIM SECONDARY SCHOOL 潔心林炳炎中學" },
          { label: "KO LUI SECONDARY SCHOOL 高雷中學", value: "KO LUI SECONDARY SCHOOL 高雷中學" },
          { label: "KOREAN INTERNATIONAL SCHOOL https://www.kis.edu.hk/", value: "KOREAN INTERNATIONAL SCHOOL https://www.kis.edu.hk/" },
          { label: "KOWLOON SAM YUK SECONDARY SCHOOL 九龍三育中學", value: "KOWLOON SAM YUK SECONDARY SCHOOL 九龍三育中學" },
          { label: "KOWLOON TECHNICAL SCHOOL 九龍工業學校", value: "KOWLOON TECHNICAL SCHOOL 九龍工業學校" },
          { label: "KOWLOON TONG SCHOOL (SECONDARY SECTION) 九龍塘學校（中學部）", value: "KOWLOON TONG SCHOOL (SECONDARY SECTION) 九龍塘學校（中學部）" },
          { label: "KOWLOON TRUE LIGHT SCHOOL 九龍真光中學", value: "KOWLOON TRUE LIGHT SCHOOL 九龍真光中學" },
          { label: "KWAI CHUNG METHODIST COLLEGE 葵涌循道中學", value: "KWAI CHUNG METHODIST COLLEGE 葵涌循道中學" },
          { label: "KWOK TAK SENG CATHOLIC SECONDARY SCHOOL 天主教郭得勝中學", value: "KWOK TAK SENG CATHOLIC SECONDARY SCHOOL 天主教郭得勝中學" },
          { label: "KWUN TONG GOVERNMENT SECONDARY SCHOOL 觀塘官立中學", value: "KWUN TONG GOVERNMENT SECONDARY SCHOOL 觀塘官立中學" },
          { label: "KWUN TONG KUNG LOK GOVERNMENT SEC SCH 觀塘功樂官立中學", value: "KWUN TONG KUNG LOK GOVERNMENT SEC SCH 觀塘功樂官立中學" },
          { label: "KWUN TONG MARYKNOLL COLLEGE 觀塘瑪利諾書院", value: "KWUN TONG MARYKNOLL COLLEGE 觀塘瑪利諾書院" },
          { label: "LA SALLE COLLEGE 喇沙書院", value: "LA SALLE COLLEGE 喇沙書院" },
          { label: "LAI CHACK MIDDLE SCHOOL 麗澤中學", value: "LAI CHACK MIDDLE SCHOOL 麗澤中學" },
          { label: "LAI KING CATHOLIC SECONDARY SCHOOL 荔景天主教中學", value: "LAI KING CATHOLIC SECONDARY SCHOOL 荔景天主教中學" },
          { label: "LAM TAI FAI COLLEGE 林大輝中學", value: "LAM TAI FAI COLLEGE 林大輝中學" },
          { label: "LAW TING PONG SECONDARY SCHOOL 羅定邦中學", value: "LAW TING PONG SECONDARY SCHOOL 羅定邦中學" },
          { label: "LEE KAU YAN MEMORIAL SCHOOL 李求恩紀念中學", value: "LEE KAU YAN MEMORIAL SCHOOL 李求恩紀念中學" },
          { label: "LEUNG SHEK CHEE COLLEGE 梁式芝書院", value: "LEUNG SHEK CHEE COLLEGE 梁式芝書院" },
          { label: "LI PO CHUN UNITED WORLD COLLEGE OF HK 李寶椿聯合世界書院", value: "LI PO CHUN UNITED WORLD COLLEGE OF HK 李寶椿聯合世界書院" },
          { label: "LING LIANG CHURCH E WUN SEC SCH 靈糧堂怡文中學", value: "LING LIANG CHURCH E WUN SEC SCH 靈糧堂怡文中學" },
          { label: "LING LIANG CHURCH M H LAU SECONDARY SCH 靈糧堂劉梅軒中學", value: "LING LIANG CHURCH M H LAU SECONDARY SCH 靈糧堂劉梅軒中學" },
          { label: "LINGNAN DR CHUNG WING KWONG MEM SEC SCH 嶺南鍾榮光博士紀念中學", value: "LINGNAN DR CHUNG WING KWONG MEM SEC SCH 嶺南鍾榮光博士紀念中學" },
          { label: "LINGNAN HANG YEE MEMORIAL SECONDARY SCH 嶺南衡怡紀念中學", value: "LINGNAN HANG YEE MEMORIAL SECONDARY SCH 嶺南衡怡紀念中學" },
          { label: "LINGNAN SECONDARY SCHOOL 嶺南中學", value: "LINGNAN SECONDARY SCHOOL 嶺南中學" },
          { label: "LIONS COLLEGE 獅子會中學", value: "LIONS COLLEGE 獅子會中學" },
          { label: "LIU PO SHAN MEMORIAL COLLEGE 廖寶珊紀念書院", value: "LIU PO SHAN MEMORIAL COLLEGE 廖寶珊紀念書院" },
          { label: "LKWFSL LAU WONG FAT SECONDARY SCHOOL 世界龍岡學校劉皇發中學", value: "LKWFSL LAU WONG FAT SECONDARY SCHOOL 世界龍岡學校劉皇發中學" },
          { label: "LOCK TAO SECONDARY SCHOOL 樂道中學", value: "LOCK TAO SECONDARY SCHOOL 樂道中學" },
          { label: "LOK SIN TONG KU CHIU MAN SEC SCH 樂善堂顧超文中學", value: "LOK SIN TONG KU CHIU MAN SEC SCH 樂善堂顧超文中學" },
          { label: "LOK SIN TONG LEUNG CHIK WAI MEMORIAL SCH 樂善堂梁植偉紀念中學", value: "LOK SIN TONG LEUNG CHIK WAI MEMORIAL SCH 樂善堂梁植偉紀念中學" },
          { label: "LOK SIN TONG LEUNG KAU KUI COLLEGE 樂善堂梁銶琚書院", value: "LOK SIN TONG LEUNG KAU KUI COLLEGE 樂善堂梁銶琚書院" },
          { label: "LOK SIN TONG WONG CHUNG MING SEC SCH 樂善堂王仲銘中學", value: "LOK SIN TONG WONG CHUNG MING SEC SCH 樂善堂王仲銘中學" },
          { label: "LOK SIN TONG YOUNG KO HSIAO LIN SEC SCH 樂善堂楊葛小琳中學", value: "LOK SIN TONG YOUNG KO HSIAO LIN SEC SCH 樂善堂楊葛小琳中學" },
          { label: "LOK SIN TONG YU KAN HING SEC SCHOOL 樂善堂余近卿中學", value: "LOK SIN TONG YU KAN HING SEC SCHOOL 樂善堂余近卿中學" },
          { label: "LUI CHEUNG KWONG LUTHERAN COLLEGE 路德會呂祥光中學", value: "LUI CHEUNG KWONG LUTHERAN COLLEGE 路德會呂祥光中學" },
          { label: "LUI MING CHOI LUTHERAN COLLEGE 路德會呂明才中學", value: "LUI MING CHOI LUTHERAN COLLEGE 路德會呂明才中學" },
          { label: "LUNG CHEUNG GOVERNMENT SECONDARY SCHOOL 龍翔官立中學", value: "LUNG CHEUNG GOVERNMENT SECONDARY SCHOOL 龍翔官立中學" },
          { label: "LUTHERAN SCHOOL FOR THE DEAF 路德會啓聾學校", value: "LUTHERAN SCHOOL FOR THE DEAF 路德會啓聾學校" },
          { label: "LYC'EE FRANCAIS INTL (FRENCH INTL SCH) https://www.fis.edu.hk/", value: "LYC'EE FRANCAIS INTL (FRENCH INTL SCH) https://www.fis.edu.hk/" },
          { label: "MA ON SHAN ST JOSEPH'S SECONDARY SCHOOL 馬鞍山聖若瑟中學", value: "MA ON SHAN ST JOSEPH'S SECONDARY SCHOOL 馬鞍山聖若瑟中學" },
          { label: "MA ON SHAN TSUNG TSIN SECONDARY SCHOOL 馬鞍山崇真中學", value: "MA ON SHAN TSUNG TSIN SECONDARY SCHOOL 馬鞍山崇真中學" },
          { label: "MADAM LAU KAM LUNG SEC SCH OF MFBM 妙法寺劉金龍中學", value: "MADAM LAU KAM LUNG SEC SCH OF MFBM 妙法寺劉金龍中學" },
          { label: "MAN KIU COLLEGE 閩僑中學", value: "MAN KIU COLLEGE 閩僑中學" },
          { label: "MARIA COLLEGE 瑪利亞書院", value: "MARIA COLLEGE 瑪利亞書院" },
          { label: "MARY ROSE SCHOOL 天保民學校", value: "MARY ROSE SCHOOL 天保民學校" },
          { label: "MARYCOVE SCHOOL 瑪利灣學校", value: "MARYCOVE SCHOOL 瑪利灣學校" },
          { label: "MARYKNOLL CONVENT SCH (SEC SECTION) 瑪利諾修院學校（中學部）", value: "MARYKNOLL CONVENT SCH (SEC SECTION) 瑪利諾修院學校（中學部）" },
          { label: "MARYKNOLL FATHERS' SCHOOL 瑪利諾神父教會學校", value: "MARYKNOLL FATHERS' SCHOOL 瑪利諾神父教會學校" },
          { label: "MARYKNOLL SECONDARY SCHOOL 瑪利諾中學", value: "MARYKNOLL SECONDARY SCHOOL 瑪利諾中學" },
          { label: "MARYMOUNT SECONDARY SCHOOL 瑪利曼中學", value: "MARYMOUNT SECONDARY SCHOOL 瑪利曼中學" },
          { label: "MENTAL HEALTH ASSN OF HK - CORNWALL SCH 香港心理衞生會－臻和學校", value: "MENTAL HEALTH ASSN OF HK - CORNWALL SCH 香港心理衞生會－臻和學校" },
          { label: "METHODIST CHURCH HK WESLEY COLLEGE 衞理中學", value: "METHODIST CHURCH HK WESLEY COLLEGE 衞理中學" },
          { label: "METHODIST COLLEGE 循道中學", value: "METHODIST COLLEGE 循道中學" },
          { label: "METHODIST LEE WAI LEE COLLEGE 李惠利中學", value: "METHODIST LEE WAI LEE COLLEGE 李惠利中學" },
          { label: "MISSION COVENANT CHURCH HOLM GLAD COLL 基督教聖約教會堅樂中學", value: "MISSION COVENANT CHURCH HOLM GLAD COLL 基督教聖約教會堅樂中學" },
          { label: "MKMCF MA CHAN DUEN HEY MEM COLLEGE 馬錦明慈善基金馬陳端喜紀念中學", value: "MKMCF MA CHAN DUEN HEY MEM COLLEGE 馬錦明慈善基金馬陳端喜紀念中學" },
          { label: "MU KUANG ENGLISH SCHOOL 慕光英文書院", value: "MU KUANG ENGLISH SCHOOL 慕光英文書院" },
          { label: "MUNSANG COLLEGE 民生書院", value: "MUNSANG COLLEGE 民生書院" },
          { label: "MUNSANG COLLEGE (HONG KONG ISLAND) 港島民生書院", value: "MUNSANG COLLEGE (HONG KONG ISLAND) 港島民生書院" },
          { label: "NAM WAH CATHOLIC SECONDARY SCHOOL 天主教南華中學", value: "NAM WAH CATHOLIC SECONDARY SCHOOL 天主教南華中學" },
          { label: "NEW ASIA MIDDLE SCHOOL 新亞中學", value: "NEW ASIA MIDDLE SCHOOL 新亞中學" },
          { label: "NEWMAN CATHOLIC COLLEGE 天主教新民書院", value: "NEWMAN CATHOLIC COLLEGE 天主教新民書院" },
          { label: "NG WAH CATHOLIC SECONDARY SCHOOL 天主教伍華中學", value: "NG WAH CATHOLIC SECONDARY SCHOOL 天主教伍華中學" },
          { label: "NG YUK SECONDARY SCHOOL 五育中學", value: "NG YUK SECONDARY SCHOOL 五育中學" },
          { label: "NING PO COLLEGE 寧波公學", value: "NING PO COLLEGE 寧波公學" },
          { label: "NING PO NO.2 COLLEGE 寧波第二中學", value: "NING PO NO.2 COLLEGE 寧波第二中學" },
          { label: "NLSI LUI KWOK PAT FONG COLLEGE 新生命教育協會呂郭碧鳳中學", value: "NLSI LUI KWOK PAT FONG COLLEGE 新生命教育協會呂郭碧鳳中學" },
          { label: "NLSI PEACE EVANGELICAL SECONDARY SCHOOL 新生命教育協會平安福音中學", value: "NLSI PEACE EVANGELICAL SECONDARY SCHOOL 新生命教育協會平安福音中學" },
          { label: "NORD ANGLIA INTERNATIONAL SCHOOL HK", value: "NORD ANGLIA INTERNATIONAL SCHOOL HK" },
          { label: "NOTRE DAME COLLEGE 聖母院書院", value: "NOTRE DAME COLLEGE 聖母院書院" },
          { label: "NTHYK TAI PO DISTRICT SECONDARY SCHOOL 新界鄉議局大埔區中學", value: "NTHYK TAI PO DISTRICT SECONDARY SCHOOL 新界鄉議局大埔區中學" },
          { label: "NTHYK YUEN LONG DISTRICT SECONDARY SCH 新界鄉議局元朗區中學", value: "NTHYK YUEN LONG DISTRICT SECONDARY SCH 新界鄉議局元朗區中學" },
          { label: "OUR LADY OF THE ROSARY COLLEGE 聖母玫瑰書院", value: "OUR LADY OF THE ROSARY COLLEGE 聖母玫瑰書院" },
          { label: "OUR LADY'S COLLEGE 聖母書院", value: "OUR LADY'S COLLEGE 聖母書院" },
          { label: "PAK KAU COLLEGE 伯裘書院", value: "PAK KAU COLLEGE 伯裘書院" },
          { label: "PAOC KA CHI SECONDARY SCHOOL 加拿大神召會嘉智中學", value: "PAOC KA CHI SECONDARY SCHOOL 加拿大神召會嘉智中學" },
          { label: "PENTECOSTAL LAM HON KWONG SCHOOL 五旬節林漢光中學", value: "PENTECOSTAL LAM HON KWONG SCHOOL 五旬節林漢光中學" },
          { label: "PENTECOSTAL SCHOOL 五旬節中學", value: "PENTECOSTAL SCHOOL 五旬節中學" },
          { label: "PHC WING KWONG COLLEGE 五旬節聖潔會永光書院", value: "PHC WING KWONG COLLEGE 五旬節聖潔會永光書院" },
          { label: "PLK ANITA LL CHAN (CENTENARY) SCHOOL 保良局陳麗玲（百周年）學校", value: "PLK ANITA LL CHAN (CENTENARY) SCHOOL 保良局陳麗玲（百周年）學校" },
          { label: "PLK C W CHU COLLEGE 保良局朱敬文中學", value: "PLK C W CHU COLLEGE 保良局朱敬文中學" },
          { label: "PLK CELINE HO YAM TONG COLLEGE 保良局何蔭棠中學", value: "PLK CELINE HO YAM TONG COLLEGE 保良局何蔭棠中學" },
          { label: "PLK CENTENARY LI SHIU CHUNG MEM COLL 保良局百周年李兆忠紀念中學", value: "PLK CENTENARY LI SHIU CHUNG MEM COLL 保良局百周年李兆忠紀念中學" },
          { label: "PLK HO YUK CHING (1984) COLLEGE 保良局甲子何玉清中學", value: "PLK HO YUK CHING (1984) COLLEGE 保良局甲子何玉清中學" },
          { label: "PLK LAW'S FOUNDATION SCHOOL 保良局羅氏信託學校", value: "PLK LAW'S FOUNDATION SCHOOL 保良局羅氏信託學校" },
          { label: "PLK LEE SHING PIK COLLEGE 保良局李城璧中學", value: "PLK LEE SHING PIK COLLEGE 保良局李城璧中學" },
          { label: "PLK MA KAM MING COLLEGE 保良局馬錦明中學", value: "PLK MA KAM MING COLLEGE 保良局馬錦明中學" },
          { label: "PLK MR & MRS CHAN PAK KEUNG TSING YI SCH 保良局陳百強伉儷青衣學校", value: "PLK MR & MRS CHAN PAK KEUNG TSING YI SCH 保良局陳百強伉儷青衣學校" },
          { label: "PLK MRS MA KAM MING CHEUNG FOOK SIEN COL 保良局馬錦明夫人章馥仙中學", value: "PLK MRS MA KAM MING CHEUNG FOOK SIEN COL 保良局馬錦明夫人章馥仙中學" },
          { label: "PLK NO.1 WH CHEUNG COLLEGE 保良局第一張永慶中學", value: "PLK NO.1 WH CHEUNG COLLEGE 保良局第一張永慶中學" },
          { label: "PLK TANG YUK TIEN COLLEGE 保良局董玉娣中學", value: "PLK TANG YUK TIEN COLLEGE 保良局董玉娣中學" },
          { label: "PLK TONG NAI KAN JUNIOR SEC COLLEGE 保良局唐乃勤初中書院", value: "PLK TONG NAI KAN JUNIOR SEC COLLEGE 保良局唐乃勤初中書院" },
          { label: "PLK VICWOOD KT CHONG SIXTH FORM COLLEGE 保良局莊啓程預科書院", value: "PLK VICWOOD KT CHONG SIXTH FORM COLLEGE 保良局莊啓程預科書院" },
          { label: "PLK WU CHUNG COLLEGE 保良局胡忠中學", value: "PLK WU CHUNG COLLEGE 保良局胡忠中學" },
          { label: "PLK YAO LING SUN COLLEGE 保良局姚連生中學", value: "PLK YAO LING SUN COLLEGE 保良局姚連生中學" },
          { label: "PLK YU LEE MO FAN MEMORIAL SCHOOL 保良局余李慕芬紀念學校", value: "PLK YU LEE MO FAN MEMORIAL SCHOOL 保良局余李慕芬紀念學校" },
          { label: "PO CHIU CATHOLIC SECONDARY SCHOOL 天主教普照中學", value: "PO CHIU CATHOLIC SECONDARY SCHOOL 天主教普照中學" },
          { label: "PO KOK SECONDARY SCHOOL 寶覺中學", value: "PO KOK SECONDARY SCHOOL 寶覺中學" },
          { label: "PO LEUNG KUK CHOI KAI YAU SCHOOL 保良局蔡繼有學校", value: "PO LEUNG KUK CHOI KAI YAU SCHOOL 保良局蔡繼有學校" },
          { label: "PO LEUNG KUK LAWS FOUNDATION COLLEGE 保良局羅氏基金中學", value: "PO LEUNG KUK LAWS FOUNDATION COLLEGE 保良局羅氏基金中學" },
          { label: "PO LEUNG KUK LO KIT SING (1983) COLLEGE 保良局羅傑承（一九八三）中學", value: "PO LEUNG KUK LO KIT SING (1983) COLLEGE 保良局羅傑承（一九八三）中學" },
          { label: "PO LEUNG KUK NGAN PO LING COLLEGE 保良局顏寶鈴書院", value: "PO LEUNG KUK NGAN PO LING COLLEGE 保良局顏寶鈴書院" },
          { label: "PO ON COMM ASSN WONG SIU CHING SEC SCH 寶安商會王少清中學", value: "PO ON COMM ASSN WONG SIU CHING SEC SCH 寶安商會王少清中學" },
          { label: "POH 80TH ANNIVERSARY TANG YING HEI COLL 博愛醫院八十週年鄧英喜中學", value: "POH 80TH ANNIVERSARY TANG YING HEI COLL 博愛醫院八十週年鄧英喜中學" },
          { label: "POH TANG PUI KING MEMORIAL COLLEGE 博愛醫院鄧佩瓊紀念中學", value: "POH TANG PUI KING MEMORIAL COLLEGE 博愛醫院鄧佩瓊紀念中學" },
          { label: "POK OI HOSPITAL CHAN KAI MEMORIAL COLL 博愛醫院陳楷紀念中學", value: "POK OI HOSPITAL CHAN KAI MEMORIAL COLL 博愛醫院陳楷紀念中學" },
          { label: "POOI TO MIDDLE SCHOOL 香港培道中學", value: "POOI TO MIDDLE SCHOOL 香港培道中學" },
          { label: "POPE PAUL VI COLLEGE 保祿六世書院", value: "POPE PAUL VI COLLEGE 保祿六世書院" },
          { label: "PRECIOUS BLOOD SECONDARY SCHOOL 寶血女子中學", value: "PRECIOUS BLOOD SECONDARY SCHOOL 寶血女子中學" },
          { label: "PUI CHING MIDDLE SCHOOL 香港培正中學", value: "PUI CHING MIDDLE SCHOOL 香港培正中學" },
          { label: "PUI KIU COLLEGE 培僑書院", value: "PUI KIU COLLEGE 培僑書院" },
          { label: "PUI KIU MIDDLE SCHOOL 培僑中學", value: "PUI KIU MIDDLE SCHOOL 培僑中學" },
          { label: "PUI SHING CATHOLIC SECONDARY SCHOOL 天主教培聖中學", value: "PUI SHING CATHOLIC SECONDARY SCHOOL 天主教培聖中學" },
          { label: "PUI TAK CANOSSIAN COLLEGE 嘉諾撒培德書院", value: "PUI TAK CANOSSIAN COLLEGE 嘉諾撒培德書院" },
          { label: "PUI YING SECONDARY SCHOOL 培英中學", value: "PUI YING SECONDARY SCHOOL 培英中學" },
          { label: "QES OLD STUDENTS' ASSOCIATION SEC SCH 伊利沙伯中學舊生會中學", value: "QES OLD STUDENTS' ASSOCIATION SEC SCH 伊利沙伯中學舊生會中學" },
          { label: "QESOSA TONG KWOK WAH SECONDARY SCHOOL 伊利沙伯中學舊生會湯國華中學", value: "QESOSA TONG KWOK WAH SECONDARY SCHOOL 伊利沙伯中學舊生會湯國華中學" },
          { label: "QUALIED COLLEGE 匯知中學", value: "QUALIED COLLEGE 匯知中學" },
          { label: "QUEEN ELIZABETH SCHOOL 伊利沙伯中學", value: "QUEEN ELIZABETH SCHOOL 伊利沙伯中學" },
          { label: "QUEEN'S COLL OLD BOYS' ASSN SEC SCH 皇仁舊生會中學", value: "QUEEN'S COLL OLD BOYS' ASSN SEC SCH 皇仁舊生會中學" },
          { label: "QUEEN'S COLLEGE 皇仁書院", value: "QUEEN'S COLLEGE 皇仁書院" },
          { label: "RAIMONDI COLLEGE 高主教書院", value: "RAIMONDI COLLEGE 高主教書院" },
          { label: "RCHK ISLAND WEST HONG CHI MORNINGHOPE SC 香港西區扶輪社匡智晨輝學校", value: "RCHK ISLAND WEST HONG CHI MORNINGHOPE SC 香港西區扶輪社匡智晨輝學校" },
          { label: "RENAISSANCE COLLEGE 啓新書院", value: "RENAISSANCE COLLEGE 啓新書院" },
          { label: "RHENISH CHURCH GRACE SCHOOL 禮賢會恩慈學校", value: "RHENISH CHURCH GRACE SCHOOL 禮賢會恩慈學校" },
          { label: "RHENISH CHURCH PANG HOK-KO MEMORIAL COLL 禮賢會彭學高紀念中學", value: "RHENISH CHURCH PANG HOK-KO MEMORIAL COLL 禮賢會彭學高紀念中學" },
          { label: "ROSARYHILL SCHOOL 玫瑰崗學校", value: "ROSARYHILL SCHOOL 玫瑰崗學校" },
          { label: "ROSARYHILL SECONDARY SCHOOL 玫瑰崗中學", value: "ROSARYHILL SECONDARY SCHOOL 玫瑰崗中學" },
          { label: "SACRED HEART CANOSSIAN COLLEGE 嘉諾撒聖心書院", value: "SACRED HEART CANOSSIAN COLLEGE 嘉諾撒聖心書院" },
          { label: "SAHK B M KOTEWALL MEMORIAL SCHOOL 香港耀能協會羅怡基紀念學校", value: "SAHK B M KOTEWALL MEMORIAL SCHOOL 香港耀能協會羅怡基紀念學校" },
          { label: "SAHK JOCKEY CLUB ELAINE FIELD SCHOOL 香港耀能協會賽馬會田綺玲學校", value: "SAHK JOCKEY CLUB ELAINE FIELD SCHOOL 香港耀能協會賽馬會田綺玲學校" },
          { label: "SAHK KO FOOK IU MEMORIAL SCHOOL 香港耀能協會高福耀紀念學校", value: "SAHK KO FOOK IU MEMORIAL SCHOOL 香港耀能協會高福耀紀念學校" },
          { label: "SAI KUNG SUNG TSUN CATH SCH (SEC SECT) 西貢崇真天主教學校（中學部）", value: "SAI KUNG SUNG TSUN CATH SCH (SEC SECT) 西貢崇真天主教學校（中學部）" },
          { label: "SAINT TOO SEAR ROGERS INTERNATIONAL SCH 聖道弘爵國際學校", value: "SAINT TOO SEAR ROGERS INTERNATIONAL SCH 聖道弘爵國際學校" },
          { label: "SALEM-IMMANUEL LUTHERAN COLLEGE 南亞路德會沐恩中學", value: "SALEM-IMMANUEL LUTHERAN COLLEGE 南亞路德會沐恩中學" },
          { label: "SALESIAN ENGLISH SCHOOL 慈幼英文學校", value: "SALESIAN ENGLISH SCHOOL 慈幼英文學校" },
          { label: "SALESIANS OF DON BOSCO NG SIU MUI SEC SC 天主教慈幼會伍少梅中學", value: "SALESIANS OF DON BOSCO NG SIU MUI SEC SC 天主教慈幼會伍少梅中學" },
          { label: "SALVATION ARMY WILLIAM BOOTH SEC SCH 救世軍卜維廉中學", value: "SALVATION ARMY WILLIAM BOOTH SEC SCH 救世軍卜維廉中學" },
          { label: "SALVATION ARMY SHEK WU SCHOOL 救世軍石湖學校", value: "SALVATION ARMY SHEK WU SCHOOL 救世軍石湖學校" },
          { label: "SAM SHUI NATIVES ASSN LAU PUN CHEUNG SCH 三水同鄉會劉本章學校", value: "SAM SHUI NATIVES ASSN LAU PUN CHEUNG SCH 三水同鄉會劉本章學校" },
          { label: "SAN WUI COMMERCIAL SOC CHAN PAK SHA SCH 新會商會陳白沙紀念中學", value: "SAN WUI COMMERCIAL SOC CHAN PAK SHA SCH 新會商會陳白沙紀念中學" },
          { label: "SAN WUI COMMERCIAL SOCIETY SEC SCH 新會商會中學", value: "SAN WUI COMMERCIAL SOCIETY SEC SCH 新會商會中學" },
          { label: "SAVIOUR LUTHERAN SCHOOL 路德會救主學校", value: "SAVIOUR LUTHERAN SCHOOL 路德會救主學校" },
          { label: "SEMPLE MEMORIAL SECONDARY SCHOOL 深培中學", value: "SEMPLE MEMORIAL SECONDARY SCHOOL 深培中學" },
          { label: "SHA TIN GOVERNMENT SECONDARY SCHOOL 沙田官立中學", value: "SHA TIN GOVERNMENT SECONDARY SCHOOL 沙田官立中學" },
          { label: "SHA TIN METHODIST COLLEGE 沙田循道衞理中學", value: "SHA TIN METHODIST COLLEGE 沙田循道衞理中學" },
          { label: "SHATIN COLLEGE https://www.shatincollege.edu.hk/", value: "SHATIN COLLEGE https://www.shatincollege.edu.hk/" },
          { label: "SHATIN COLLEGE", value: "SHATIN COLLEGE" },
          { label: "SHATIN PUBLIC SCHOOL 沙田公立學校", value: "SHATIN PUBLIC SCHOOL 沙田公立學校" },
          { label: "SHATIN PUI YING COLLEGE 沙田培英中學", value: "SHATIN PUI YING COLLEGE 沙田培英中學" },
          { label: "SHATIN TSUNG TSIN SECONDARY SCHOOL 沙田崇真中學", value: "SHATIN TSUNG TSIN SECONDARY SCHOOL 沙田崇真中學" },
          { label: "SHAU KEI WAN EAST GOVERNMENT SEC SCH 筲箕灣東官立中學", value: "SHAU KEI WAN EAST GOVERNMENT SEC SCH 筲箕灣東官立中學" },
          { label: "SHAU KEI WAN GOVERNMENT SECONDARY SCHOOL 筲箕灣官立中學", value: "SHAU KEI WAN GOVERNMENT SECONDARY SCHOOL 筲箕灣官立中學" },
          { label: "SHEK LEI CATHOLIC SECONDARY SCHOOL 石籬天主教中學", value: "SHEK LEI CATHOLIC SECONDARY SCHOOL 石籬天主教中學" },
          { label: "SHENG KUNG HUI ST BENEDICT'S SCHOOL 聖公會聖本德中學", value: "SHENG KUNG HUI ST BENEDICT'S SCHOOL 聖公會聖本德中學" },
          { label: "SHEUNG SHUI GOVERNMENT SECONDARY SCHOOL 上水官立中學", value: "SHEUNG SHUI GOVERNMENT SECONDARY SCHOOL 上水官立中學" },
          { label: "SHI HUI WEN SECONDARY SCHOOL 釋慧文中學", value: "SHI HUI WEN SECONDARY SCHOOL 釋慧文中學" },
          { label: "SHUN LEE CATHOLIC SECONDARY SCHOOL 順利天主教中學", value: "SHUN LEE CATHOLIC SECONDARY SCHOOL 順利天主教中學" },
          { label: "SHUNG TAK CATHOLIC ENGLISH COLLEGE 天主教崇德英文書院", value: "SHUNG TAK CATHOLIC ENGLISH COLLEGE 天主教崇德英文書院" },
          { label: "SING YIN SECONDARY SCHOOL 聖言中學", value: "SING YIN SECONDARY SCHOOL 聖言中學" },
          { label: "SINGAPORE INTERNATIONAL SCH (HONG KONG) 新加坡國際學校", value: "SINGAPORE INTERNATIONAL SCH (HONG KONG) 新加坡國際學校" },
          { label: "SIR ELLIS KADOORIE SEC SCH (WEST KLN) 官立嘉道理爵士中學（西九龍）", value: "SIR ELLIS KADOORIE SEC SCH (WEST KLN) 官立嘉道理爵士中學（西九龍）" },
          { label: "SKH ALL SAINTS' MIDDLE SCHOOL 聖公會諸聖中學", value: "SKH ALL SAINTS' MIDDLE SCHOOL 聖公會諸聖中學" },
          { label: "SKH BISHOP BAKER SECONDARY SCHOOL 聖公會白約翰會督中學", value: "SKH BISHOP BAKER SECONDARY SCHOOL 聖公會白約翰會督中學" },
          { label: "SKH BISHOP MOK SAU TSENG SEC SCH 聖公會莫壽增會督中學", value: "SKH BISHOP MOK SAU TSENG SEC SCH 聖公會莫壽增會督中學" },
          { label: "SKH CHAN YOUNG SECONDARY SCHOOL 聖公會陳融中學", value: "SKH CHAN YOUNG SECONDARY SCHOOL 聖公會陳融中學" },
          { label: "SKH HOLY CARPENTER SECONDARY SCHOOL 聖公會聖匠中學", value: "SKH HOLY CARPENTER SECONDARY SCHOOL 聖公會聖匠中學" },
          { label: "SKH HOLY TRINITY CHURCH SECONDARY SCHOOL 聖公會聖三一堂中學", value: "SKH HOLY TRINITY CHURCH SECONDARY SCHOOL 聖公會聖三一堂中學" },
          { label: "SKH KEI HAU SECONDARY SCHOOL 聖公會基孝中學", value: "SKH KEI HAU SECONDARY SCHOOL 聖公會基孝中學" },
          { label: "SKH LAM KAU MOW SECONDARY SCHOOL 聖公會林裘謀中學", value: "SKH LAM KAU MOW SECONDARY SCHOOL 聖公會林裘謀中學" },
          { label: "SKH LAM WOO MEMORIAL SECONDARY SCHOOL 聖公會林護紀念中學", value: "SKH LAM WOO MEMORIAL SECONDARY SCHOOL 聖公會林護紀念中學" },
          { label: "SKH LEUNG KWAI YEE SECONDARY SCHOOL 聖公會梁季彜中學", value: "SKH LEUNG KWAI YEE SECONDARY SCHOOL 聖公會梁季彜中學" },
          { label: "SKH LI FOOK HING SECONDARY SCHOOL 聖公會李福慶中學", value: "SKH LI FOOK HING SECONDARY SCHOOL 聖公會李福慶中學" },
          { label: "SKH LI PING SECONDARY SCHOOL 聖公會李炳中學", value: "SKH LI PING SECONDARY SCHOOL 聖公會李炳中學" },
          { label: "SKH LUI MING CHOI SECONDARY SCHOOL 聖公會呂明才中學", value: "SKH LUI MING CHOI SECONDARY SCHOOL 聖公會呂明才中學" },
          { label: "SKH ST MARY'S CHURCH MOK HING YIU COL 聖公會聖馬利亞堂莫慶堯中學", value: "SKH ST MARY'S CHURCH MOK HING YIU COL 聖公會聖馬利亞堂莫慶堯中學" },
          { label: "SKH ST SIMON'S LUI MING CHOI SEC SCH 聖公會聖西門呂明才中學", value: "SKH ST SIMON'S LUI MING CHOI SEC SCH 聖公會聖西門呂明才中學" },
          { label: "SKH TANG SHIU KIN SECONDARY SCHOOL 聖公會鄧肇堅中學", value: "SKH TANG SHIU KIN SECONDARY SCHOOL 聖公會鄧肇堅中學" },
          { label: "SKH TSANG SHIU TIM SECONDARY SCHOOL 聖公會曾肇添中學", value: "SKH TSANG SHIU TIM SECONDARY SCHOOL 聖公會曾肇添中學" },
          { label: "SKH TSOI KUNG PO SECONDARY SCHOOL 聖公會蔡功譜中學", value: "SKH TSOI KUNG PO SECONDARY SCHOOL 聖公會蔡功譜中學" },
          { label: "SMKMCF MA KO PAN MEM COLLEGE 馬錦明慈善基金馬可賓紀念中學", value: "SMKMCF MA KO PAN MEM COLLEGE 馬錦明慈善基金馬可賓紀念中學" },
          { label: "SOC OF BOYS' CTRS SHING TAK CTR SCH 香港扶幼會盛德中心學校", value: "SOC OF BOYS' CTRS SHING TAK CTR SCH 香港扶幼會盛德中心學校" },
          { label: "SOC OF BOYS' CTRS-HUI CHUNG SING MEM SCH 香港扶幼會－許仲繩紀念學校", value: "SOC OF BOYS' CTRS-HUI CHUNG SING MEM SCH 香港扶幼會－許仲繩紀念學校" },
          { label: "SOCIETY OF BOYS' CTRS CHAK YAN CTR SCH 香港扶幼會則仁中心學校", value: "SOCIETY OF BOYS' CTRS CHAK YAN CTR SCH 香港扶幼會則仁中心學校" },
          { label: "SOUTH ISLAND SCHOOL https://www.sis.edu.hk/", value: "SOUTH ISLAND SCHOOL https://www.sis.edu.hk/" },
          { label: "SOUTH TUEN MUN GOVERNMENT SEC SCH 南屯門官立中學", value: "SOUTH TUEN MUN GOVERNMENT SEC SCH 南屯門官立中學" },
          { label: "SPHRC KUNG YIK SHE SECONDARY SCHOOL 十八鄉鄉事委員會公益社中學", value: "SPHRC KUNG YIK SHE SECONDARY SCHOOL 十八鄉鄉事委員會公益社中學" },
          { label: "ST ANTONIUS GIRLS' COLLEGE 聖安當女書院", value: "ST ANTONIUS GIRLS' COLLEGE 聖安當女書院" },
          { label: "ST BONAVENTURE COLLEGE & HIGH SCHOOL 聖文德書院", value: "ST BONAVENTURE COLLEGE & HIGH SCHOOL 聖文德書院" },
          { label: "ST CATHARINE'S SCH FOR GIRLS 聖傑靈女子中學", value: "ST CATHARINE'S SCH FOR GIRLS 聖傑靈女子中學" },
          { label: "ST CLARE'S GIRLS' SCHOOL 聖嘉勒女書院", value: "ST CLARE'S GIRLS' SCHOOL 聖嘉勒女書院" },
          { label: "ST FRANCIS' CANOSSIAN COLLEGE 嘉諾撒聖方濟各書院", value: "ST FRANCIS' CANOSSIAN COLLEGE 嘉諾撒聖方濟各書院" },
          { label: "ST FRANCIS OF ASSISI'S COLLEGE 聖芳濟各書院", value: "ST FRANCIS OF ASSISI'S COLLEGE 聖芳濟各書院" },
          { label: "ST FRANCIS XAVIER'S COLLEGE 聖芳濟書院", value: "ST FRANCIS XAVIER'S COLLEGE 聖芳濟書院" },
          { label: "ST FRANCIS XAVIER'S SCHOOL 荃灣聖芳濟中學", value: "ST FRANCIS XAVIER'S SCHOOL 荃灣聖芳濟中學" },
          { label: "ST JOAN OF ARC SECONDARY SCHOOL 聖貞德中學", value: "ST JOAN OF ARC SECONDARY SCHOOL 聖貞德中學" },
          { label: "ST JOSEPH'S ANGLO-CHINESE SCHOOL 聖若瑟英文中學", value: "ST JOSEPH'S ANGLO-CHINESE SCHOOL 聖若瑟英文中學" },
          { label: "ST JOSEPH'S COLLEGE 聖若瑟英文書院", value: "ST JOSEPH'S COLLEGE 聖若瑟英文書院" },
          { label: "ST LOUIS SCHOOL 聖類斯中學", value: "ST LOUIS SCHOOL 聖類斯中學" },
          { label: "ST MARGARET'S CO-EDU ENG SEC & PRI SCH 聖瑪加利男女英文中小學", value: "ST MARGARET'S CO-EDU ENG SEC & PRI SCH 聖瑪加利男女英文中小學" },
          { label: "ST MARGARET'S GIRLS' COLLEGE 香港聖瑪加利女書院", value: "ST MARGARET'S GIRLS' COLLEGE 香港聖瑪加利女書院" },
          { label: "ST MARK'S SCHOOL 聖馬可中學", value: "ST MARK'S SCHOOL 聖馬可中學" },
          { label: "ST MARY'S CANOSSIAN COLLEGE 嘉諾撒聖瑪利書院", value: "ST MARY'S CANOSSIAN COLLEGE 嘉諾撒聖瑪利書院" },
          { label: "ST MATTHEW'S LUTHERAN SCHOOL 路德會聖馬太學校", value: "ST MATTHEW'S LUTHERAN SCHOOL 路德會聖馬太學校" },
          { label: "ST PAUL'S CO-EDUCATIONAL COLLEGE 聖保羅男女中學", value: "ST PAUL'S CO-EDUCATIONAL COLLEGE 聖保羅男女中學" },
          { label: "ST PAUL'S COLLEGE 聖保羅書院", value: "ST PAUL'S COLLEGE 聖保羅書院" },
          { label: "ST PAUL'S CONVENT SCHOOL 聖保祿學校", value: "ST PAUL'S CONVENT SCHOOL 聖保祿學校" },
          { label: "ST PAUL'S SCHOOL (LAM TIN) 藍田聖保祿中學", value: "ST PAUL'S SCHOOL (LAM TIN) 藍田聖保祿中學" },
          { label: "ST PAUL'S SECONDARY SCHOOL 聖保祿中學", value: "ST PAUL'S SECONDARY SCHOOL 聖保祿中學" },
          { label: "ST PETER'S SECONDARY SCHOOL 聖伯多祿中學", value: "ST PETER'S SECONDARY SCHOOL 聖伯多祿中學" },
          { label: "ST ROSE OF LIMA'S COLLEGE 聖羅撒書院", value: "ST ROSE OF LIMA'S COLLEGE 聖羅撒書院" },
          { label: "ST STEPHEN'S CHURCH COLLEGE 聖士提反堂中學", value: "ST STEPHEN'S CHURCH COLLEGE 聖士提反堂中學" },
          { label: "ST STEPHEN'S COLLEGE 聖士提反書院", value: "ST STEPHEN'S COLLEGE 聖士提反書院" },
          { label: "ST STEPHEN'S GIRLS' COLLEGE 聖士提反女子中學", value: "ST STEPHEN'S GIRLS' COLLEGE 聖士提反女子中學" },
          { label: "ST. GLORIA COLLEGE (KOWLOON) 聖迦利亞書院（九龍）", value: "ST. GLORIA COLLEGE (KOWLOON) 聖迦利亞書院（九龍）" },
          { label: "ST. TERESA SECONDARY SCHOOL 德蘭中學", value: "ST. TERESA SECONDARY SCHOOL 德蘭中學" },
          { label: "STAMFORD AMERICAN SCHOOL HONG KONG https://www.sais.hk", value: "STAMFORD AMERICAN SCHOOL HONG KONG https://www.sais.hk" },
          { label: "STEWARDS POOI KEI COLLEGE 香港神託會培基書院", value: "STEWARDS POOI KEI COLLEGE 香港神託會培基書院" },
          { label: "STEWARDS POOI TUN SECONDARY SCHOOL 香港神託會培敦中學", value: "STEWARDS POOI TUN SECONDARY SCHOOL 香港神託會培敦中學" },
          { label: "STFA CHENG YU TUNG SECONDARY SCHOOL 順德聯誼總會鄭裕彤中學", value: "STFA CHENG YU TUNG SECONDARY SCHOOL 順德聯誼總會鄭裕彤中學" },
          { label: "STFA LEE SHAU KEE COLLEGE 順德聯誼總會李兆基中學", value: "STFA LEE SHAU KEE COLLEGE 順德聯誼總會李兆基中學" },
          { label: "STFA LEUNG KAU KUI COLLEGE 順德聯誼總會梁銶琚中學", value: "STFA LEUNG KAU KUI COLLEGE 順德聯誼總會梁銶琚中學" },
          { label: "STFA SEAWARD WOO COLLEGE 順德聯誼總會胡兆熾中學", value: "STFA SEAWARD WOO COLLEGE 順德聯誼總會胡兆熾中學" },
          { label: "STFA TAM PAK YU COLLEGE 順德聯誼總會譚伯羽中學", value: "STFA TAM PAK YU COLLEGE 順德聯誼總會譚伯羽中學" },
          { label: "STFA YUNG YAU COLLEGE 順德聯誼總會翁祐中學", value: "STFA YUNG YAU COLLEGE 順德聯誼總會翁祐中學" },
          { label: "TACK CHING GIRLS' SECONDARY SCHOOL 德貞女子中學", value: "TACK CHING GIRLS' SECONDARY SCHOOL 德貞女子中學" },
          { label: "TAI PO SAM YUK SECONDARY SCHOOL 大埔三育中學", value: "TAI PO SAM YUK SECONDARY SCHOOL 大埔三育中學" },
          { label: "TAK NGA SECONDARY SCHOOL 德雅中學", value: "TAK NGA SECONDARY SCHOOL 德雅中學" },
          { label: "TAK OI SECONDARY SCHOOL 德愛中學", value: "TAK OI SECONDARY SCHOOL 德愛中學" },
          { label: "TAK SUN SECONDARY SCHOOL 德信中學", value: "TAK SUN SECONDARY SCHOOL 德信中學" },
          { label: "TANG KING PO SCHOOL 鄧鏡波學校", value: "TANG KING PO SCHOOL 鄧鏡波學校" },
          { label: "TANG SHIU KIN VICTORIA GOVT SEC SCH 鄧肇堅維多利亞官立中學", value: "TANG SHIU KIN VICTORIA GOVT SEC SCH 鄧肇堅維多利亞官立中學" },
          { label: "TEXTILE INT ACC WOO HON FAI SEC SCH 紡織學會美國商會胡漢輝中學", value: "TEXTILE INT ACC WOO HON FAI SEC SCH 紡織學會美國商會胡漢輝中學" },
          { label: "THE CHINESE FOUNDATION SECONDARY SCHOOL 中華基金中學", value: "THE CHINESE FOUNDATION SECONDARY SCHOOL 中華基金中學" },
          { label: "THE HARBOUR SCHOOL 港灣學校", value: "THE HARBOUR SCHOOL 港灣學校" },
          { label: "TIN KA PING SECONDARY SCHOOL 田家炳中學", value: "TIN KA PING SECONDARY SCHOOL 田家炳中學" },
          { label: "TIN SHUI WAI GOVERNMENT SECONDARY SCHOOL 天水圍官立中學", value: "TIN SHUI WAI GOVERNMENT SECONDARY SCHOOL 天水圍官立中學" },
          { label: "TIN SHUI WAI METHODIST COLLEGE 天水圍循道衞理中學", value: "TIN SHUI WAI METHODIST COLLEGE 天水圍循道衞理中學" },
          { label: "TOI SHAN ASSOCIATION COLLEGE 台山商會中學", value: "TOI SHAN ASSOCIATION COLLEGE 台山商會中學" },
          { label: "TRUE LIGHT GIRLS' COLLEGE 真光女書院", value: "TRUE LIGHT GIRLS' COLLEGE 真光女書院" },
          { label: "TRUE LIGHT MIDDLE SCHOOL OF HONG KONG 香港真光中學", value: "TRUE LIGHT MIDDLE SCHOOL OF HONG KONG 香港真光中學" },
          { label: "TSANG PIK SHAN SECONDARY SCHOOL 曾璧山中學", value: "TSANG PIK SHAN SECONDARY SCHOOL 曾璧山中學" },
          { label: "TSEUNG KWAN O GOVERNMENT SEC SCHOOL 將軍澳官立中學", value: "TSEUNG KWAN O GOVERNMENT SEC SCHOOL 將軍澳官立中學" },
          { label: "TSEUNG KWAN O PUI CHI SCHOOL 將軍澳培智學校", value: "TSEUNG KWAN O PUI CHI SCHOOL 將軍澳培智學校" },
          { label: "TSUEN WAN GOVERNMENT SECONDARY SCHOOL 荃灣官立中學", value: "TSUEN WAN GOVERNMENT SECONDARY SCHOOL 荃灣官立中學" },
          { label: "TSUEN WAN PUB HO CHUEN YIU MEMORIAL COLL 荃灣公立何傳耀紀念中學", value: "TSUEN WAN PUB HO CHUEN YIU MEMORIAL COLL 荃灣公立何傳耀紀念中學" },
          { label: "TSUNG TSIN CHRISTIAN ACADEMY 基督教崇真中學", value: "TSUNG TSIN CHRISTIAN ACADEMY 基督教崇真中學" },
          { label: "TSUNG TSIN COLLEGE 崇真書院", value: "TSUNG TSIN COLLEGE 崇真書院" },
          { label: "TSUNG TSIN MIDDLE SCHOOL 崇正中學", value: "TSUNG TSIN MIDDLE SCHOOL 崇正中學" },
          { label: "TUEN MUN CATHOLIC SECONDARY SCHOOL 屯門天主教中學", value: "TUEN MUN CATHOLIC SECONDARY SCHOOL 屯門天主教中學" },
          { label: "TUEN MUN GOVERNMENT SECONDARY SCHOOL 屯門官立中學", value: "TUEN MUN GOVERNMENT SECONDARY SCHOOL 屯門官立中學" },
          { label: "TUNG CHUNG CATHOLIC SCHOOL 東涌天主教學校", value: "TUNG CHUNG CATHOLIC SCHOOL 東涌天主教學校" },
          { label: "TWGH CHANG MING THIEN COLLEGE 東華三院張明添中學", value: "TWGH CHANG MING THIEN COLLEGE 東華三院張明添中學" },
          { label: "TWGH CHEN ZAO MEN COLLEGE 東華三院陳兆民中學", value: "TWGH CHEN ZAO MEN COLLEGE 東華三院陳兆民中學" },
          { label: "TWGH CY MA MEMORIAL COLLEGE 東華三院馬振玉紀念中學", value: "TWGH CY MA MEMORIAL COLLEGE 東華三院馬振玉紀念中學" },
          { label: "TWGH KAP YAN DIRECTORS' COLLEGE 東華三院甲寅年總理中學", value: "TWGH KAP YAN DIRECTORS' COLLEGE 東華三院甲寅年總理中學" },
          { label: "TWGH KWAN FONG KAI CHI SCHOOL 東華三院群芳啓智學校", value: "TWGH KWAN FONG KAI CHI SCHOOL 東華三院群芳啓智學校" },
          { label: "TWGH KWOK YAT WAI COLLEGE 東華三院郭一葦中學", value: "TWGH KWOK YAT WAI COLLEGE 東華三院郭一葦中學" },
          { label: "TWGH LEE CHING DEA MEMORIAL COLLEGE 東華三院李潤田紀念中學", value: "TWGH LEE CHING DEA MEMORIAL COLLEGE 東華三院李潤田紀念中學" },
          { label: "TWGH LI KA SHING COLLEGE 東華三院李嘉誠中學", value: "TWGH LI KA SHING COLLEGE 東華三院李嘉誠中學" },
          { label: "TWGH LO KON TING MEMORIAL COLLEGE 東華三院盧幹庭紀念中學", value: "TWGH LO KON TING MEMORIAL COLLEGE 東華三院盧幹庭紀念中學" },
          { label: "TWGH LUI YUN CHOY MEMORIAL COLLEGE 東華三院呂潤財紀念中學", value: "TWGH LUI YUN CHOY MEMORIAL COLLEGE 東華三院呂潤財紀念中學" },
          { label: "TWGH MRS FUNG WONG FUNG TING COLLEGE 東華三院馮黃鳳亭中學", value: "TWGH MRS FUNG WONG FUNG TING COLLEGE 東華三院馮黃鳳亭中學" },
          { label: "TWGH MRS WU YORK YU MEMORIAL COLLEGE 東華三院伍若瑜夫人紀念中學", value: "TWGH MRS WU YORK YU MEMORIAL COLLEGE 東華三院伍若瑜夫人紀念中學" },
          { label: "TWGH SC GAW MEMORIAL COLLEGE 東華三院吳祥川紀念中學", value: "TWGH SC GAW MEMORIAL COLLEGE 東華三院吳祥川紀念中學" },
          { label: "TWGH SUN HOI DIRECTORS' COLLEGE 東華三院辛亥年總理中學", value: "TWGH SUN HOI DIRECTORS' COLLEGE 東華三院辛亥年總理中學" },
          { label: "TWGH TSUI TSIN TONG SCHOOL 東華三院徐展堂學校", value: "TWGH TSUI TSIN TONG SCHOOL 東華三院徐展堂學校" },
          { label: "TWGH WONG FUNG LING COLLEGE 東華三院黃鳳翎中學", value: "TWGH WONG FUNG LING COLLEGE 東華三院黃鳳翎中學" },
          { label: "TWGH WONG FUT NAM COLLEGE 東華三院黃笏南中學", value: "TWGH WONG FUT NAM COLLEGE 東華三院黃笏南中學" },
          { label: "TWGH YAU TZE TIN MEM COLLEGE 東華三院邱子田紀念中學", value: "TWGH YAU TZE TIN MEM COLLEGE 東華三院邱子田紀念中學" },
          { label: "TWGH YOW KAM YUEN COLLEGE 東華三院邱金元中學", value: "TWGH YOW KAM YUEN COLLEGE 東華三院邱金元中學" },
          { label: "TWGHS MR & MRS KWONG SIK KWAN COLLEGE 東華三院鄺錫坤伉儷中學", value: "TWGHS MR & MRS KWONG SIK KWAN COLLEGE 東華三院鄺錫坤伉儷中學" },
          { label: "TYYI MFBM NEI MING CHAN LCT MEM COLLEGE 圓玄學院妙法寺內明陳呂重德紀念中學", value: "TYYI MFBM NEI MING CHAN LCT MEM COLLEGE 圓玄學院妙法寺內明陳呂重德紀念中學" },
          { label: "UNITED CHRISTIAN COLLEGE 滙基書院", value: "UNITED CHRISTIAN COLLEGE 滙基書院" },
          { label: "UNITED CHRISTIAN COLLEGE (KOWLOON EAST) 滙基書院（東九龍）", value: "UNITED CHRISTIAN COLLEGE (KOWLOON EAST) 滙基書院（東九龍）" },
          { label: "VALTORTA COLLEGE 恩主教書院", value: "VALTORTA COLLEGE 恩主教書院" },
          { label: "VICTORIA SHANGHAI ACADEMY 滬江維多利亞學校", value: "VICTORIA SHANGHAI ACADEMY 滬江維多利亞學校" },
          { label: "WA YING COLLEGE 華英中學", value: "WA YING COLLEGE 華英中學" },
          { label: "WAH YAN COLLEGE 香港華仁書院", value: "WAH YAN COLLEGE 香港華仁書院" },
          { label: "WAH YAN COLLEGE 華仁書院（九龍）", value: "WAH YAN COLLEGE 華仁書院（九龍）" },
          { label: "WAI KIU COLLEGE 惠僑英文中學", value: "WAI KIU COLLEGE 惠僑英文中學" },
          { label: "WELLINGTON ED ORG CHANG PUI CHUNG MEM SC 威靈頓教育機構張沛松紀念中學", value: "WELLINGTON ED ORG CHANG PUI CHUNG MEM SC 威靈頓教育機構張沛松紀念中學" },
          { label: "WEST ISLAND SCHOOL https://www.wis.edu.hk/", value: "WEST ISLAND SCHOOL https://www.wis.edu.hk/" },
          { label: "WONG SHIU CHI SECONDARY SCHOOL 王肇枝中學", value: "WONG SHIU CHI SECONDARY SCHOOL 王肇枝中學" },
          { label: "WORKERS' CHILDREN SECONDARY SCHOOL 勞工子弟中學", value: "WORKERS' CHILDREN SECONDARY SCHOOL 勞工子弟中學" },
          { label: "YAN CHAI HOSPITAL LAW CHAN CHOR SI COLL 仁濟醫院羅陳楚思中學", value: "YAN CHAI HOSPITAL LAW CHAN CHOR SI COLL 仁濟醫院羅陳楚思中學" },
          { label: "YAN CHAI HOSPITAL NO. 2 SECONDARY SCHOOL 仁濟醫院第二中學", value: "YAN CHAI HOSPITAL NO. 2 SECONDARY SCHOOL 仁濟醫院第二中學" },
          { label: "YAN OI TONG TIN KA PING SECONDARY SCHOOL 仁愛堂田家炳中學", value: "YAN OI TONG TIN KA PING SECONDARY SCHOOL 仁愛堂田家炳中學" },
          { label: "YCH LAN CHI PAT MEMORIAL SECONDARY SCH 仁濟醫院靚次伯紀念中學", value: "YCH LAN CHI PAT MEMORIAL SECONDARY SCH 仁濟醫院靚次伯紀念中學" },
          { label: "YCH LIM POR YEN SECONDARY SCHOOL 仁濟醫院林百欣中學", value: "YCH LIM POR YEN SECONDARY SCHOOL 仁濟醫院林百欣中學" },
          { label: "YCH TUNG CHI YING MEM SEC SCHOOL 仁濟醫院董之英紀念中學", value: "YCH TUNG CHI YING MEM SEC SCHOOL 仁濟醫院董之英紀念中學" },
          { label: "YCH WONG WHA SAN SECONDARY SCHOOL 仁濟醫院王華湘中學", value: "YCH WONG WHA SAN SECONDARY SCHOOL 仁濟醫院王華湘中學" },
          { label: "YEW CHUNG INTL SCH - SECONDARY 耀中國際學校（中學）", value: "YEW CHUNG INTL SCH - SECONDARY 耀中國際學校（中學）" },
          { label: "YING WA COLLEGE 英華書院", value: "YING WA COLLEGE 英華書院" },
          { label: "YING WA GIRLS' SCHOOL 英華女學校", value: "YING WA GIRLS' SCHOOL 英華女學校" },
          { label: "YLPMSAA TANG SIU TONG SECONDARY SCHOOL 元朗公立中學校友會鄧兆棠中學", value: "YLPMSAA TANG SIU TONG SECONDARY SCHOOL 元朗公立中學校友會鄧兆棠中學" },
          { label: "YMCA OF HONG KONG CHRISTIAN COLLEGE 港青基信書院", value: "YMCA OF HONG KONG CHRISTIAN COLLEGE 港青基信書院" },
          { label: "YOT CHAN WONG SUK FONG MEM SEC SCH 仁愛堂陳黃淑芳紀念中學", value: "YOT CHAN WONG SUK FONG MEM SEC SCH 仁愛堂陳黃淑芳紀念中學" },
          { label: "YPI&CA LEE LIM MING COLLEGE 恩平工商會李琳明中學", value: "YPI&CA LEE LIM MING COLLEGE 恩平工商會李琳明中學" },
          { label: "YU CHUN KEUNG MEMORIAL COLLEGE 余振強紀念中學", value: "YU CHUN KEUNG MEMORIAL COLLEGE 余振強紀念中學" },
          { label: "YU CHUN KEUNG MEMORIAL COLLEGE NO.2 余振強紀念第二中學", value: "YU CHUN KEUNG MEMORIAL COLLEGE NO.2 余振強紀念第二中學" },
          { label: "YUEN LONG CATHOLIC SECONDARY SCHOOL 元朗天主教中學", value: "YUEN LONG CATHOLIC SECONDARY SCHOOL 元朗天主教中學" },
          { label: "YUEN LONG MERCHANTS ASSOCIATION SEC SCH 元朗商會中學", value: "YUEN LONG MERCHANTS ASSOCIATION SEC SCH 元朗商會中學" },
          { label: "YUEN LONG PUBLIC SECONDARY SCHOOL 元朗公立中學", value: "YUEN LONG PUBLIC SECONDARY SCHOOL 元朗公立中學" },
          { label: "YWCA HIOE TJO YOENG COLLEGE 基督教女青年會丘佐榮中學", value: "YWCA HIOE TJO YOENG COLLEGE 基督教女青年會丘佐榮中學" },

        ]

      },



    ]
  }
]