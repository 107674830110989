import { initializeApp, getApp, getApps } from 'firebase/app';
import { v4 } from 'uuid';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
} from "firebase/auth";

import {
  setDoc,
  doc,
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  updateDoc,
  getDoc,

} from "firebase/firestore";

import toast from 'react-hot-toast';
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyClnIgACiW5PYt4YhwKyMmuseCBb9CRA8k",
  authDomain: "tutorplus-3cd0a.firebaseapp.com",
  projectId: "tutorplus-3cd0a",
  storageBucket: "tutorplus-3cd0a.appspot.com",
  messagingSenderId: "924476552581",
  appId: "1:924476552581:web:a40a58cc2b82304f7440ec",
  measurementId: "G-XY7XRJ7F9L"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app)

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        verified: false,
        dse_verified: false
      });
      updateInnerDoc('users', user.uid, 'apply-list', 'list', [])
      updateInnerDoc('users', user.uid, 'favorite-list', 'list', [])
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const facebookProvider = new FacebookAuthProvider();
const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "facebook",
        email: user.email,
        verified: false,
        dse_verified: false
      });
      updateInnerDoc('users', user.uid, 'apply-list', 'list', [])
      updateInnerDoc('users', user.uid, 'favorite-list', 'list', [])

    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const getAll = async (ref) => {
  let result = []

  await getDocs(ref).then((snapshot) => {
    snapshot.docs.forEach((doc) => {
      result.push({ ...doc.data(), id: doc.id })
    })
  })
    .catch(err => {
      console.log(err.message);
    })
  return result;
};

const logInWithEmailAndPassword = async (email, password) => {
  let result = null
  try {
    await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      result = user;
      // ...
    })
      .catch((error) => {
        result = null;
      });
  } catch (err) {
    console.error(err);
    alert(err.message);
    result = null;
  }
  return result
};
export const addVisitor = async (col, data) => {
  const submit_notification = toast.loading('提交中...')
  try {
    await addDoc(collection(db, col), {
      appellation: data?.appellation,
      name: data?.name,
      phone: data?.phone,
      found: false
    });
    toast.success("提交成功", {
      id: submit_notification
    })
  } catch (err) {
    toast.error("提交失敗", {
      id: submit_notification
    })
    console.log(err.message);
  }

}
export const createCol = async (col, email_id, innerCol, innerDoc) => {
  const colRef = collection(db, col);
  const docRef = doc(colRef, email_id, innerCol, innerDoc)
  try {
    await setDoc((docRef), {
      value: []
    })
  } catch (err) {
    console.log(err.message);
  }

}

const registerWithEmailAndPassword = async (name, email, password) => {
  let result = null
  const login_notification = toast.loading('請稍等...')
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    const user = res.user;
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      verified: false,
      dse_verified: false
    });
    updateInnerDoc('users', user.uid, 'apply-list', 'list', [])
    updateInnerDoc('users', user.uid, 'favorite-list', 'list', [])
    result = user;
    toast.success("註冊成功\n請驗證電郵", {
      id: login_notification
    })
  } catch (err) {

    toast.error(err.message, {
      id: login_notification
    })
  }
  return result;
};
const sendPasswordReset = async (email) => {
  const reset_notification = toast.loading('請稍等...')
  try {
    await sendPasswordResetEmail(auth, email)
    toast.success("密碼重設連結已發送到你的電郵", {
      id: reset_notification
    })



  } catch (err) {

    toast.error('重設失敗', {
      id: reset_notification
    })

  }
};
const sendVerifiedEmail = async () => {
  sendEmailVerification(auth.currentUser)
  .then(() => {

  });
}
const logout = () => {
  signOut(auth);

  toast.success("你已登出")

};

export const addRegisterCase = async (col, data, newCaseNum) => {
  let result = null
  const addCase_notification = toast.loading('提交中...')
  const subject_arr = []
  await (data?.tutor_subject).map((item) => (
    subject_arr.push(item.value)
  ))

  try {
    await addDoc(collection(db, col), {
      case_num : newCaseNum,
      fee: data?.tutor_fee,
      gender: data?.student_gender,
      grade: data?.student_grade.label,
      grade_index: data?.student_grade.value,
      icon_link: '',
      district: data?.district?.label ? data?.district?.label: '視像',
      location: data?.location ? data?.location : '視像',
      num: '1',
      period: data?.lesson_per_week,
      post_date: '',
      remarks: data?.remarks,
      subject: subject_arr,
      time: data?.hour_per_lesson,
      tutor: data?.tutor_gender,
      tutor_edu: '',
      type: data?.tutor_way.label,
      verified: false



    });
    toast.success("提交成功", {
      id: addCase_notification
    })
    result = true
  } catch (err) {
    toast.error("提交失敗", {
      id: addCase_notification
    })
    console.log(err.message);
  }
  return result;

};
export const getAllCases = async (doc_id) => {
  let result = []
  const colRef = query(collection(db, 'cases'), where('verified', '==', true))
  await getDocs(colRef).then((snapshot) => {
    snapshot.docs.forEach((doc) => {
      result.push({ ...doc.data(), id: doc.id })
    })
  })
    .catch(err => {
      console.log(err.message);
    })
  return result;
};

export const updateMemberInfo = async (data, uid) => {

  let result = null
  const update_notification = toast.loading('請稍等...')


  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, uid, 'detail', 'basic_information'), data)
    toast.success("已更新資料", {
      id: update_notification
    })
    result = true
  } catch (err) {
    toast.error("更新失敗", {
      id: update_notification
    })

  }
  return result
}


export const uploadMemberImg = async (uid, icon) => {
  try {
    const icon_url =  `images/${icon[0].name + v4()}`
    const imageRef = ref(storage, icon_url)
    await uploadBytes(imageRef, icon[0]).then((snapshot) => {
      const returnURL = getDownloadURL(imageRef).then((url)=>{
        const userRef = doc(db, 'users', uid, 'detail', 'basic_information')
        updateDoc(userRef, {upload_icon :url})
        return url
      })
      return returnURL
    })
    return uploadBytes

  } catch (err) {
    console.log(err.message);
    return false
  }
}
export const uploadDSEImg = async (uid, icon) => {
  try {
    const icon_url =  `images/${icon[0].name + v4()}`
    const imageRef = ref(storage, icon_url)
    await uploadBytes(imageRef, icon[0]).then((snapshot) => {
      const returnURL = getDownloadURL(imageRef).then((url)=>{
        const userRef = doc(db, 'users', uid, 'detail', 'basic_information')
        updateDoc(userRef, {upload_dse :url})
        return url
      })
      return returnURL
    })
    return uploadBytes

  } catch (err) {
    console.log(err.message);
    return false
  }
}

export const getMemberImg = async (uid) => {
  let result = ''
  const docRef = doc(db, "users", uid, 'detail', 'basic_information');

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if(docSnap.data().upload_icon !== ''){
      result = docSnap.data().upload_icon
    }
  }
  return result;
};




export const getDSEImg = async (uid) => {
  let result = ''
  const docRef = doc(db, "users", uid, 'detail', 'basic_information');

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if(docSnap.data().upload_dse !== ''){
      result = docSnap.data().upload_dse
    }
  }
  return result;
};

export const setVerified = async (col,id, verified_status) => {
  const docRef = doc(db, col, id)
  try {
    await updateDoc(docRef, {
      verified: verified_status
    })
  } catch (err) {
    console.log(err.message);
  }

}

export const getAllInnerDoc = async (col, email_id, innerCol) => {
  let result = []

  const colRef = collection(db, col, email_id, innerCol)

  await getDocs(colRef).then((snapshot) => {
    snapshot.docs.forEach((doc) => {
      result.push({ ...doc.data(), id: doc.id })
    })



  })
    .catch(err => {
      console.log(err.message);
    })
  return result;
};

export const getSpecCase = async (col, doc_id) => {
  let result = []
  const docRef = doc(db, col, doc_id)
  await getDoc(docRef).then((snapshot) => {

      result.push({ ...snapshot.data(), id: snapshot.id })




  })
    .catch(err => {
      console.log(err.message);
    })
  return result;
};

export const updateInnerDoc = async (col, email_id, innerCol, innerDoc, arr) => {
  const colRef = collection(db, col);
  try {
    await setDoc(doc(colRef, email_id, innerCol, innerDoc), {
      value: arr
    })
  } catch (err) {

    console.log(err.message);
  }
}
export const createCaseNum = async (ref) => {
  let result = []
  let highest = 0
  try {
    await getDocs(ref).then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        let trimed = Number((doc.data().case_num).replace(/^A+/, '').replace(/A+$/, ''))
        if (trimed >= highest) {
          highest = trimed
        }

      })
      result = `A${highest + 1}`
    })
  } catch (err) {
    console.log(err.message);
  }
  return result
}
export {
  auth,
  db,
  storage,
  signInWithGoogle,
  signInWithFacebook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendVerifiedEmail,
  sendPasswordReset,
  logout,
};