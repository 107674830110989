import React from 'react'
import { Helmet } from "react-helmet";

export default function tutorfee() {
    return (
        <div className='min-h-[800px]'>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/tutorfee" />
            </Helmet>
            <section className=' max-w-5xl mx-auto px-7 py-14'>
                <div className='py-8 border-b-2 border-grey'>
                    <h2 className='font-normal text-3xl'>導師收費</h2>

                </div>

                <div className='mt-7'>
                    <p className='mb-1'>導師會員應充分理解本會的收費制度,因此特設本頁清楚介紹各種準則及衡量收費的標準。</p>
                    <p>希望導師會員在申請或接納個案前,務必清晰其權利、責任和義務。</p>
                    <p className='mb-1'>本會將收取 2星期協議堂數 為行政費用,有關費用會由家長/學生代為轉交予本會。</p><p>
                        本會確認收到有關費用後,往後家長/學生會直接與導師交收學費,不會再經由本平台負責。</p>
                    <table className='w-full mt-10 text-center text-sm md:text-base'>
                        <tr className='bg-[#4094bf] text-white '>
                            <th className='p-4'>項目說明</th>
                            <th className='p-4'>費用 (HKD)</th>
                            <th className='p-4'>付款期限</th>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>登記成為導師</td>
                            <td className='p-4'>不用收費</td>
                            <td className='p-4'>-----</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>口頭承諾確認個案前放棄個案<sup>(1)</sup></td>
                            <td className='p-4'>不用收費</td>
                            <td className='p-4'>-----</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>口頭承諾確認個案<sup>(2)</sup></td>
                            <td className='p-4'>2星期協議堂數<sup>(3)</sup></td>
                            <td className='p-4'>家長/學生代為轉交</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>取消已確認接納之個案</td>
                            <td className='p-4'>仍未開始上課:$200<br />已上首課:原行政費用</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>導師與家長串同欺騙行政費用<sup>4</sup></td>
                            <td className='p-4'>原行政費用+$300</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>個案開始後家長終止服務</td>
                            <td className='p-4'>2星期內終止:已上課堂之一半學費<sup>5</sup><br />2星期後終止:仍為全數行政費用</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>因失責行為致家長終止服務<sup>6</sup></td>
                            <td className='p-4'>已上堂數一半學費+$200</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>任何原因失約致個案取消<sup>7</sup></td>
                            <td className='p-4'>原行政費用+$200</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>私自將個案轉讓第三者</td>
                            <td className='p-4'>原行政費用+$200</td>
                            <td className='p-4'>確認個案終止48小時內</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>私下與家長/學生更動堂數<br />並且未有即時匯報</td>
                            <td className='p-4'>原行政費用</td>
                            <td className='p-4'>原定日期</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>過期付費超逾5天或以上<sup>(8)</sup></td>
                            <td className='p-4'>原行政費用+10%附加費</td>
                            <td className='p-4'>即時</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>過期付費超逾5天或以上</td>
                            <td className='p-4'>個案轉至「小額錢債」追討<br />原行政費用+20%附加費用</td>
                            <td className='p-4'>即時</td>
                        </tr>
                    </table>

                </div>
                <div className='mt-10'>
                    (1) 如導師兩次在個案最後確認前決定放棄個案,其會藉有可能會被置於黑名單內。<br/>
                    (2) 確認個案指Tutor Plus職員與導師電話對話表明個案已確認(confirm)。<br/>
                    (3) 「2星期」指首堂上課日起計2個星期,「協議堂數」指家長/學生原定每星期恆常的上課堂數,因此並非跟
                    據首2星期實際補習堂數而定,而是以2星期恆常堂數作準。即使家長/學生在首2星期減少堂數,本會亦不會減
                    收費用。除非家長/學生決定日後補習堂數會永久性減少,本會將酌情考慮減免部分費用,否則即使家長/學生
                    在首2星期減少堂數,本會亦只會把收費日期順延至「2星期協議堂數」完成日作準。<br/>
                    (4) 根據ICAC防止貪汙條例,於三方確認後任何一方不可私下提出更改所有有關利益輸送的協議,本會備有完善
                    系統檢查異常個案,如有發現,本會會采取追究行動並不作任何通知,切勿以身試法。<br/>
                    (5) 如導師於首2個星期內被終止服務(首課開始後14天內),我們會退還已上課堂一半學費予導師,但每個個案最
                    少收取$250元,導師必須在24小時內通知Tutor Plus負責個案職員,否則仍須繳交全數原行政費用。如導師於2
                    星期後遭解雇,Tutor Plus仍會收取全數行政費用。<br/>
                    (6) 失責行為包括爽約、遲到、失責、提供失實履歷、更改或經常更改上課日期時間等。<br/>
                    (7) 任何原因是包括生病或任何意外所致失約,均須付$200附加費用。<br/>
                    (8) 如未能提供有效收條證明已付費,將視為拖欠費用。<br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    * 當你於Tutor Plus(香港首席導師協會)登記成為導師會員,即表示同意及遵守這份合約,這代表你能夠理解
這份合約的內容及有正常的判斷力去作出決定。若果你不同意本公司之守則,請不要登記使用該項服務。本站有
權停止提供服務給任何會員。
                </div>
            </section>


        </div>
    )
}
