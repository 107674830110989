import React from 'react'
import { Helmet } from "react-helmet";

export default function tutorzone() {
    return (
        <div>
        <Helmet>
            <link rel="canonical" href="https://www.tutorplushk.com/tutorzone" />
        </Helmet>
        <section className='section-padding'>
            <div className='section-container'>
                <h2 className='heading'>導師條款與守則</h2>
                <div className="mt-[50px]">

                    <div className="max-h-[700px] overflow-scroll p-5 rounded-[3px] mb-[20px] border-[#e3e3e3] border-[1px] bg-[#f5f5f5]">

                        歡迎您登記為Tutor Plus（香港首席導師協會）的導師，登記前請各導師參閱「導師收費」的說明，以下乃 閣下須就使用Tutor Plus（香港首席導師協會）網站及其提供之服務需要遵守之條款和規則。當完成導師登記後，即表示你同意接受這些條款和規則之約束。
                        <br /><br />
                        「本站」是指Tutor Plus（香港首席導師協會）網站及同系之網站。 本站可隨時修訂合約內容，修訂條款會在此網站內張貼。新修訂條款將於張貼七天後自動生效。因此， 閣下應該定期檢視Tutor Plus（香港首席導師協會）網站留意條款所作出之更改。倘於該等條款作出任何更改後，閣下仍繼續使用本網站或本網站提供的服務，則表示接納該等更改。
                        <br /><br />
                        1. 定義
                        <br /><br />
                        1.1 詞彙之定義
                        <br /><br />
                        以下詞彙在此合約中具下列定義：
                        <br /><br />
                        「個案確認(confirm)／接受個案」指此網站職員如在電話對話中表明「個案已經confirm／確認」，如導師沒有 提出反對，從電話結束以後的一刻開始，個案已被視為「已確認／confirm／已接受／已接納」
                        <br /><br />
                        「全科」指該級別之全級常規科目，小學科目則為「中文、英文、數學、常識、普通話」，中學科目則需按個別學校情況而定，另功課輔導即等同全科補習
                        <br /><br />
                        「導師／家長/ 香港導師會三方關係 」指導師與家長之關係為服務合作關係，其間只為獨立合作聘約，並不存在僱傭關係；導師與此網站亦只存在服務提供者與服務使用者之關係，雙方沒有任何僱傭關係 ，服務亦與「職業介紹」完全無關
                        <br /><br />
                        「會員合約 」指此網站的任何一部分所示指引、指南、內容、條款、說明，均為會員合約之一部分，此網站有權依據合約收取費用，成功登記即代表導師同意所有條款
                        <br /><br />
                        「導師登記」指會員導師於此網站上進行登記，法律上與實體合約無異
                        <br /><br />
                        「欺騙行為」指會員導師虛報資料登記／行騙／與學生或家長串聯行騙
                        <br /><br />
                        1.2 標題
                        <br /><br />
                        此合約中的標題只為方便閱讀而設，並非此合約內容的一部份，絕不會影響對此合約或其任何部份的解釋。
                        <br /><br />
                        2. 提供服務
                        <br /><br />
                        2.1 使用
                        <br /><br />
                        任何人士欲以導師身份使用此網站的服務，必須先登記為本站導師會員。
                        <br /><br />
                        2.2 登記成為本站導師
                        <br /><br />
                        用戶向Tutor Plus（香港首席導師協會）提供所有需要資料及網上填妥表格，按下「確認」鍵後即成為本站導師會員。Tutor Plus（香港首席導師協會）保留拒絕任何導師會員申請而不作任何解釋的權利。
                        <br /><br />
                        3. 登記成為本站導師資格
                        <br /><br />
                        年滿十五歲或以上，具中四或以上程度學歷之自然人且擁有簽訂合約的心智和法律能力之人士，以及獲其憲章、附例及公司組織章程大綱批准其與Tutor Plus（香港首席導師協會）簽訂此合約的公司，方可申請成為會員。Tutor Plus（香港首席導師協會）保留拒絕任何人士申請成為會員或終止任何人士之會員資格而不作任何解釋之權利。不符合上述資格者不得使用服務。
                        <br /><br />
                        4. 收費
                        <br /><br />
                        4.1 導師收費說明
                        <br /><br />
                        使用服務中收費（「收費」）列於「導師收費說明」（收費說明）中，而該收費說明乃巳納入本合約中，並視為構成合約的一部份。Tutor Plus（香港首席導師協會）保留隨時更改有關收費的權利，而新收費將於指定日期生效（生效日期將為網頁刊登經修訂收費表後的七天內）。所有用戶必須於每次使用服務前查核收費表。
                        <br /><br />
                        4.2 收費模式
                        <br /><br />
                        有關導師收費會由家長／學生直接向本平台支付。家長／學生在協議堂數完成後不會直接向導師支付學費，而會在協議堂數完成後48小時內，直接向本平台支付協議堂數之學費以作導師收費用途，因此導師毋需額外支付其他費用。在本平台確認收到有關費用後，往後家長／學生會直接向導師支付學費，不會再經由本平台負責。
                        <br /><br />
                        4.3 貨幣
                        <br /><br />
                        除非另有所指，所有收費均以港幣為單位。
                        <br /><br />
                        4.4 其他雜費
                        <br /><br />
                        所有適用之稅項，以及在閣下使用Tutor Plus（香港首席導師協會）所帶來的收入而衍生之稅務情況，一概由閣下負責。Tutor Plus（香港首席導師協會）可全權酌情決定隨時增加、刪減或更改部份或全部服務。
                        <br /><br />
                        4.5 特別情況
                        <br /><br />
                        假如在首兩周任何原因而沒有完成應有補習日數，亦會根據原定補習日數為準則收取費用。 註：原定補習日數是首兩周內應有之所有堂數，學生或導師因任何理由缺課導致首月上課日數減少，例如公眾假期，病假等，本公司將會順延繳費日期而不會減收費用。相反，學生與導師增加堂數的話，也不會增加費用。導師與本公司職員電話確認接受補習個案後，因任何事故決定取消補習，手續費為$200，亦必須於補習首堂開始48小時之前致電我們確認取消個案，否則本公司將收取原定之行政費。若家長於首兩星期內 *因私人理由而* 取消補習，我們會向導師收取已上課堂一半學費。 如有任何由家長提出之堂數更改或個案取消，必須於 *家長/學生提出後* 24小時內以電話聯絡我們，若未經本公司確認，導師即使已經離職，但仍雖繳付有關之行政費。
                        <br /><br />
                        4.6 轉介第三方導師／學生資料
                        <br /><br />
                        任何一方取消補習後，除非經Tutor Plus（香港首席導師協會）再次介紹，否則導師必須承諾在六個月之內不能向該學生提供相同或相似的補習服務。導師亦不能轉介該學生之資料予第三者及轉介其他導師之資料予該學生，若發現導師未有繳付行政費用而為該學生提供補習服務及上述轉介資料之情況，本公司必從正常之法律途徑追討其行政費及附加費$2000，另外亦保留收取文件送 遞、賬單、蒐集證據之所有費用。無論於Tutor Plus（香港首席導師協會）提供介紹前或後而經其他任何途徑獲得該學生之介紹或與學生直接接洽情況下，導師亦不能為該學生提供補習服務。而於任何情況下，若導師為該學生提供相同或相 似的補習服務而沒有經書面通知本公司，即時需要繳付上述之所有費用。 註: 該學生是指該學生及其家庭之任何學生或該學生相同地址之任何學生
                        <br /><br />
                        當你表示同意及遵守這份合約，這代表你能夠理解這份合約的內容及有正常的判斷力去作出決定。若果你不同意本公司之守則，請不要登記使用該項服務。本站有權停止提供服務給任何會員。
                        <br /><br />
                        4.7 相同學生分科補習的處理方法
                        <br /><br />
                        如學生在找Tutor Plus（香港首席導師協會）時已表明需要分科補習時，行政費將會以科目為單位去收取。即如果學生需要兩科補習，將會分別收取各兩星期學費為行政費。如導師先接受了一科科目補習，期後再接受另一科科目補習，不論是經本公司或學生直接確認上課時間，也視作同意本公司之行政費用收取，導師必需繳付每一科個別補習之全數行政費。
                        <br /><br />
                        5. Tutor Plus（香港首席導師協會）職責
                        <br /><br />
                        5.1 Tutor Plus（香港首席導師協會）僅為教育平台
                        <br /><br />
                        Tutor Plus（香港首席導師協會）作為一個教育平台，其責任僅為導師及家長或學生進行相互配對，以使導師可以向家長或學生提供補習服務, 其間只為獨立合作聘約，並不存在僱傭關係；導師與Tutor Plus（香港首席導師協會）亦只存在服務提供者與服務使用者之關係，雙方沒有任何僱傭關係，因此Tutor Plus（香港首席導師協會）有權依本公司與導師所訂定之「導師條款」收取行政費用，成功登記即代表導師同意條款全部內容。而「導師條款」所訂之內容等同Tutor Plus（香港首席導師協會）與導師之間所定之合約，而Tutor Plus（香港首席導師協會）網站內所列出的任何規則或指示都屬於會員合約的一部份，本站導師亦須格守。
                        <br /><br />
                        5.2 不擔保家長或學生對導師要求之合理性
                        <br /><br />
                        Tutor Plus（香港首席導師協會）明確聲明並沒有預先與家長或學生進行面談或家訪，而且家長或學生對導師在道德、學術、修養、教學質素及質量等之合法性、合適性或其他合理性和原則，並不在Tutor Plus（香港首席導師協會） 控制範圍之內。
                        <br /><br />
                        5.3 不擔保導師人生安全
                        <br /><br />
                        Tutor Plus（香港首席導師協會）明確聲明導師的人生安全或其他個人利益得失，亦非Tutor Plus（香港首席導師協會）控制範圍之內。Tutor Plus（香港首席導師協會）概不接納或承擔有關導師和家長或學生之間之任何法律責任。
                        <br /><br />
                        5.3 概不擔保家長或學生長期使用導師
                        <br /><br />
                        Tutor Plus（香港首席導師協會）概不擔保家長或學生長期使用導師，導師如使用此網站服務，須自行評估風險或其他個人利益得失。所有人士必須留意與未屆合法年齡人士、提供虛假理由或沒有訂約的行為能力的人士進行交易所涉及的風險。
                        <br /><br />
                        5.4 概不擔保家長或學生或導師身份
                        <br /><br />
                        Tutor Plus（香港首席導師協會）不能亦不會確認每一家長或學生或導師之身份。因此，閣下接受hkta所示與家長或學生進行交易時，務須小心防範欺騙行為。Tutor Plus（香港首席導師協會）概不就任何用戶之任何行為或遺漏負責。
                        <br /><br />
                        5.5 概不擔保資料的準確性
                        <br /><br />
                        Tutor Plus（香港首席導師協會）不能控制家長或學生透過本網站所提供之資料，有關保證項目已列於收費說明或服務簡介中，如前所述，該收費說明乃巳納入本合約中，並視為構成合約的一部份。因此，Tutor Plus（香港首席導師協會）概不擔保任何人士透過使用服務所一提供之任何資料之準確性。
                        <br /><br />
                        5.7 免除Tutor Plus（香港首席導師協會)系統服務器故障與漏洞的相關責任
                        <br /><br />
                        本公司提供之服務涉及到互聯網服務和線上系統，可能會受到各個環節不穩定因素的影響。因此服務存在不可抗力、系統不穩定、電腦病毒或黑客攻擊以及其他任何技術操作、互聯網絡等原因造成的服務中斷或不能滿足用戶要求的風險。有關系統服務器故障和漏洞已造成或可能造成之潛在損失，Tutor Plus（香港首席導師協會) 一概毋須負上有關賠償和法律責任。
                        <br /><br />
                        5.8 免除Tutor Plus（香港首席導師協會) 的法律責任
                        <br /><br />
                        若家長或學生與導師之間出現任何糾紛或因使用服務而引起任何糾紛，與該等糾紛有關或因而引起的所有責任、索償、索求、賠償金（實質或間接），不論屬何性質，已知及未知、懷疑與否、已公開及未公開者，Tutor Plus（香港首席導師協會) 一概毋須負上有關法律責任。
                        <br /><br />
                        5.9 Tutor Plus（香港首席導師協會) 的支出
                        <br /><br />
                        倘Tutor Plus（香港首席導師協會) 因應閣下之要求或閣下所涉及的任何訴訟中作出的指令（不論通過傳票或任何具司法管轄權的法院所頒法令，包括遵守任何披露文件之法令或出席法院作證）而引起 的所有費用，包括Tutor Plus（香港首席導師協會) 尋求法律或其他專業意見或陳述時的支出，一概由閣下負責。（在所有此等情況下，一切費用按實際損失計算）
                        <br /><br />
                        6. 有關補習中介服務之條款
                        <br /><br />
                        6.1 導師之基本責任
                        <br /><br />
                        倘閣下，作為導師，確認接納Tutor Plus（香港首席導師協會)之個案後，必須盡力依照Tutor Plus（香港首席導師協會) 所發出的指示，上課務必準時到達及依時完成，不可在沒有家長或學生同意的情況下刪減時間。你同意授權本站利用你的「登記資料」作為本站的宣傳之用，而不受任何法例所限制。你亦同意本站在必要時候局部或全部顯示「登記資料」，而不受任何版權法例及個人資料（私隱）條例所限制。你同意不會使用任何工具、軟件或程序干擾本站伺服器的正常運作，更不會故意、有計劃地令本站的系統負荷過重。你不可以傳播任何電腦病毒或有破壞性的程式給本站和本站的所有會員。你同意不會追究因本站系統失靈而造成的任何損失。本站會盡力維持系統的可靠性，並保持良好的效率。你不可利用任何本站的服務作出非法或不道德的行為。
                        <br /><br />
                        6.2 導師提供正確資料之責任
                        <br /><br />
                        導師如沒有提供正確資料，或因沒有提供正確資料而導師個案服務被終止，Tutor Plus（香港首席導師協會) 可以拒絕為其提供服務，亦有可以收取所衍生之行政費用。
                        <br /><br />
                        6.3 導師撤回個案所衍生之責任
                        <br /><br />
                        在個案已確認後，如導師決定取消向相關家長或學生提供補習，導師絕不能把其家長或學生之資料轉交予任何人仕。
                        <br /><br />
                        6.4 導師進行欺騙行為之責任
                        <br /><br />
                        導師如被證實，或Tutor Plus（香港首席導師協會) 認為或發現導師極有可能與家長或學生串聯進行任何欺騙行為，包括導師聲稱終止但仍然為家長或學生提供有關服務、導師和家長其任何一方要求對方配合及向Tutor Plus（香港首席導師協會）謊報服務經已終止，Tutor Plus（香港首席導師協會）會立即終止有關導師的資格，並要求有關人士於七天內清繳有關全部費用；即使其後家長或學生決定終止有關人士之服務，導師仍須繳付全數服務費用。Tutor Plus（香港首席導師協會) 會保留一切刑事及民事追究之權利，並不會容忍任何欺騙行為出現。
                        <br /><br />
                        7. Tutor Circle 的權利
                        <br /><br />
                        7.1 暫停或終止用戶資格的權利
                        <br /><br />
                        倘發現用戶出現以下行為，則Tutor Plus（香港首席導師協會）有權暫停或終止用戶資格而毋須支付賠償：
                        <br /><br />
                        (a) 違反本合約所列出之條件及條款，或
                        <br /><br />
                        (b) 透過定罪、和解、保險或託管調查或其他方式而從事與此網站有關之欺騙行為。
                        <br /><br />
                        7.2 處理由用戶提供的資料的權利
                        <br /><br />
                        Tutor Plus（香港首席導師協會）擁有絕對及不受約束之權力，拒絕或刪除用戶所提供之任何資料，而毋須向任何一方發出通知或作出賠償。在無損此項權利下，倘導師所提供之任何資料一旦刊登或 繼續刊登，會導致Tutor Plus（香港首席導師協會）須為第三者負上任何法律責任，或令Tutor Plus（香港首席導師協會）觸犯任何有效司法管轄區的任何法律或規則或第三者的權益，則Tutor Plus（香港首席導師協會）擁有權拒絕或修改任何所提供之資料。
                        <br /><br />
                        7.3 撤回提供中介服務之權利
                        <br /><br />
                        Tutor Plus（香港首席導師協會）擁有絕對及不受約束的權利，撤回任何服務條件而不向導師發出任何通知或作出任何賠償。在無減損此權利下，倘發生下列情況，Tutor Plus（香港首席導師協會）有權撤回服務：
                        <br /><br />
                        (a) 該導師提供有個人資料有極大可能不全正確；
                        <br /><br />
                        (b) 導師被發現違反本合約列出的條件及條款，或其戶口被Tutor Plus（香港首席導師協會）根據此合約的條款勒令暫停；
                        <br /><br />
                        (c) Tutor Plus（香港首席導師協會）因任何原因無法核實或證實導師或家長或學生提供的任何資料。
                        <br /><br />
                        7.4 拒絕提供服務之權利
                        <br /><br />
                        Tutor Plus（香港首席導師協會）有權停止提供服務給任何會員並保留拒絕任何人士申請成為會員或終止任何人士之會員資格而不作任何解釋之權利。
                        <br /><br />
                        7.5 更改有關收費的權利
                        <br /><br />
                        使用服務中收費列於「導師收費說明」中，而該收費說明乃已納入本合約中，視為構成合約的一部份。Tutor Plus（香港首席導師協會）保留隨時更改有關收費的權利。所 有用戶必須於每次使用服務前查核收費表
                        <br /><br />
                        7.6 增刪本合約的權利
                        <br /><br />
                        Tutor Plus（香港首席導師協會）保留隨時增刪本合約的一切權利，而不會事先通知會員；會員必須隨時翻看本合約資料，以確保清楚最新的會員約束條款。
                        <br /><br />
                        8. 不保證
                        <br /><br />
                        8.1 按「現狀」基準提供服務
                        <br /><br />
                        Tutor Plus（香港首席導師協會）按「現狀」基準提供此網站服務。Tutor Plus（香港首席導師協會）之僅有責任已在此合約中列出。除在此合約中清楚列出之事項外，Tutor Plus（香港首席導師協會）不會作出任何明確或隱含之保證或條件。Tutor Plus（香港首席導師協會）不能保證此網站所設之功能以及所提供之服務將會不受干擾或無誤，或問題將獲修正或此網站或其伺服器將無病毒或其他有害元素。Tutor Plus（香港首席導師協會）並不就用戶或用戶使用此網站之後果作保證或任何聲明。
                        <br /><br />
                        8.2 提供付款機制所屬責任
                        <br /><br />
                        提供予用戶之付款機制以及輔助性處理付款設施，乃僅便於用戶使用，而Tutor Plus（香港首席導師協會）概不就該等設施提供任何明確或隱含之保證。在任何情況下，Tutor Plus（香港首席導師協會）概不就用戶來自或因任何誤差及／或錯誤及／或錯誤扣除或計入信用卡賬戶之錯誤月結單以及任何故障、失靈、中斷、停工時間、干擾、計算錯誤、延誤、失準、損 失或數據訛誤或任何其他有關付款渠道設施失靈而蒙受或產生之任何損失、賠償、成本或開支負上責任。
                        <br /><br />
                        9. 不法行為之追究
                        <br /><br />
                        9.1 虛報資料／行騙責任
                        <br /><br />
                        跟據香港特別行政區法例對綱上行業／電貿（e-commerce）的規管及法律行駛，網際商業與其他實業皆受到相同法律之約束及保障。因此，導師於網上登記，在法律上與實體合約無異，導師虛報資料登記／行騙／與學生或家長串聯行騙，將會被視作犯罪行為；Tutor Plus（香港首席導師協會）決不會因為導師年輕或對法律認知不足而不作追究。本公司絕不容忍導師此等違法行為，導師登記時的IP及日後每一個動作的IP亦將被紀錄下 來，Tutor Plus（香港首席導師協會）亦會在有需要時把曾與導師交談的內容錄音備份，有需要時會以該等資訊舉證。導師請勿因一時貪念致前途盡毀。
                        <br /><br />
                        9.2 口頭承諾責任
                        <br /><br />
                        本公司職員與導師間之談話間，其對口頭承諾之任何部分，均可視為一個正式協議或合約，並具法律效力，本公司亦可能把部分或全部之對話錄音。
                        <br /><br />
                        9.3「登記資料」責任
                        <br /><br />
                        你必須對你的「登記資料」負責，而本站只是一個被動的資料收集及傳送中介。「登記資料」必須合乎道德，並須保證沒有觸犯任何法律，不含有暴力、色情成份及粗言穢語，以及不可包含任何侵犯私隱權或帶有歧視的成份的內容。若本站認為你的「登記資料」會對任何人或機構造成負面影響，本站將會保留採取法律行動的權 利。本站更有權停止顯示你的「登記資料」予所有有權限之查閱者（包括其本人）和因此而永久刪除你的會籍。
                        <br /><br />
                        10. 其他事項
                        <br /><br />
                        10.1 用戶不得：
                        <br /><br />
                        (a) 使用任何裝置、軟件或程式干預或企圖干預此網站之正常運作或任何在此網站進行的交易；
                        <br /><br />
                        (b) 進行任何令此網站的基礎設施承受不合理或不合比例的巨大負荷的活動；
                        <br /><br />
                        (c) 把其密碼向第三者透露或與第三者使用同一密碼或將其密碼用作未經許可的用途。
                        <br /><br />
                        10.2 遵守法律
                        <br /><br />
                        所有用戶在使用此網站服務時，須遵守所有適用法律、規程、條例和守則。
                        <br /><br />
                        10.3 概無代理關係
                        <br /><br />
                        本文件任何內容均不會令Tutor Plus（香港首席導師協會）與任何人士或公司或其他使用此網站或服務之用戶之間構成或建立任何代理、合伙、聯營、僱員／僱主或專營商／專營承辦商之關係。
                        <br /><br />
                        10.4 通知
                        <br /><br />
                        除非特別聲明，所有通知均以電子郵件或電話形式發出，及就用戶而言，倘通知已透過用戶於登記時向Tutor Plus（香港首席導師協會）提供的電郵地址或其他由該用戶指定的電郵地址或透過在此網站張貼通知，則已被視作發出通知。Tutor Plus（香港首席導師協會）有權以各種形式，包括電郵、郵寄等會訊，如希望不再接收，必須之前電郵通知我們，我們在處理會回覆確實；日後會員如需要再次接收，必須再行電郵通知Tutor Plus（香港首席導師協會）。Tutor Plus（香港首席導師協會）擁有這份會員合約的最終解釋權。
                        <br /><br />
                        10.5 條款的獨立性
                        <br /><br />
                        倘根據任何具司法管轄權的法院所實際引用的適用法例，此合約中的任何條款屬違法或無法實施，此等條款將與合約分開及視作無效論，而絕不影響此合約的其餘條款。然而，在有關法律容許的情況下，若有關法律條文可獲寬免，各方須同意其獲寬免，以便此合約的有關條款能夠有效、具約束力及得以實施。
                        <br /><br />
                        10.6 網頁之完整性
                        <br /><br />
                        倘閣下利用Tutor Plus（香港首席導師協會）系統或任何程序或伺服器漏動，竊取任何資料，閣下須負上一切法律責任。
                        <br /><br />
                        10.7 報行管轄的法律及司法機構
                        <br /><br />
                        (a) 本合約受香港特別行政區法律管轄，並依香港特別行政區法律詮釋。
                        <br /><br />
                        (b) 與此網站之使用有關或因此而起的任何法律行動或訴訟，任何有關一方須願受香港特別行政區法院的司法管轄權管轄；任何一方不得以有關訴訟被帶到任何不相稱的法院為理由而提出反對。
                        <br /><br />
                        (c) 上文所述願受香港特別行政區法院司法管轄權的管轄並不會影響其他任何一方於任何司法範圍提起訴訟的權利；而於任何司法範圍提起訴訟亦不表示其他任何一方不能於其他任何司法範圍提起訴訟。

                        <br />
                        <br />

                    </div>


                </div>

            </div>
        </section>
    </div>
    )
}
