import { TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import FormSelect from "./FormSelect"
import { district_list, expect_tutor_fee, genderList, gradeList, hour_per_lesson, lesson_per_week, traffic_way, tutorWays, tutor_gender, yes_no, tutor_requirement, all_subject } from "../Data"
import FormSelectGroup from "./FormSelectGroup"
import { addRegisterCase, auth, createCaseNum, db } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection } from "firebase/firestore";
import tickIcon from './../../images/tick.png'


const textFieldStyles = ({
    textField: {

    },
    inputWrapper: {
        borderRadius: 20,
        bgcolor: '#000',

    },
    input: {
        borderRadius: 20,
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderColor: 'hsl(0, 0%, 90%)'
    },
    label: {
        top: -6
    }


});


export default function RegisterForm({ userInfo }) {

    const [formInfo, setFormInfo] = useState([])
    const [detail, setDetail] = useState([])
    const getData = () => {
        fetch('form.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {

                return response.json();
            })
            .then(function (myJson) {

                setFormInfo(myJson)

            });
    }



    const [LoginEmail, setLoginEmail] = useState(null)
    const [LoginEmailValid, setLoginEmailValid] = useState(null)
    const [LoginUid, setLoginUid] = useState(null)
    const [formStep, setFormStep] = useState(0)
    const [caseNumber, setcaseNumber] = useState(0)

    const getUserInfoInput = (result) => {
        setDetail(result)
    }

    useEffect(() => {
        getData()
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setLoginEmail(currentUser.email)
                setLoginEmailValid(currentUser.emailVerified)

            }


        })
        getUserInfoInput(userInfo)

    }, [])


    const { register, unregister, control, trigger, setError, formState: { errors, isSubmitSuccessful, isValid }, handleSubmit, watch, reset, resetField, getValues, setValue } = useForm({
        mode: 'all',

    });
    useEffect(() => {
        if (watch('tutor_way')?.label === '視像') {
            reset({ ...getValues(), district: '', location: '' });
            unregister('district')
            unregister('location')
            unregister('traffic_way')
            unregister('transfer_way')
        } else {
            //reset({ ...getValues(), district: '', location: '' });
            register("district", { required: '請輸入' });
            register("location", { required: '請輸入' });
            register("traffic_way");
            register("transfer_way");

        }

    }, [watch('tutor_way')?.label])

    const onSubmit = async (data, e) => {
        e.preventDefault()
        Object.keys(data).forEach(function (key) {
            if (data[key] === undefined) {
                data[key] = ""
            }

        });

        const noError = await trigger();
        if (noError) {
            createCaseNum(collection(db, "cases")).then((newCaseNum) => {
                setcaseNumber(newCaseNum)
                addRegisterCase('cases', data, newCaseNum).then(result => {
                    if (result) {
                        completeFormStep();
                    }

                });
            })



        }
    }
    const completeFormStep = () => {
        setFormStep(step => step + 1)
    }

    const backFormStep = () => {
        setFormStep(step => step - 1)
    }
    const renderNextButton = () => {
        if (formStep > 2) {
            return undefined
        } else if (formStep === 2) {
            return (
                <button
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    className="mt-6 bg-[#053e68] text-white text-xl rounded-xl px-6 py-3 w-fit disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    提交個案
                </button>
            )
        } else {
            return (
                <button
                    disabled={!isValid}
                    onClick={async () => {
                        const noError = await trigger();
                        if (noError) {
                            completeFormStep()

                        }

                    }}
                    type="button"
                    className="mt-6 bg-[#053e68] text-xl text-white rounded-xl px-6 py-3 w-fit disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                    下一頁
                </button>
            )

        }
    }
    const renderPrevButton = () => {
        if (formStep > 2) {
            return undefined
        } else if (formStep === 0) {
            return (
                <button
                    /* onClick={completeFormStep} */
                    type="button"
                    disabled
                    className="mt-6 bg-[#053e68] text-xl text-white rounded-xl px-6 py-3 w-fit disabled:bg-gray-400 disabled:cursor-not-allowed disabled:opacity-25"
                >
                    上一頁
                </button>
            )
        } else {
            return (
                <button
                    onClick={backFormStep}
                    type="button"
                    className="mt-6 bg-white text-[#053e68] border-[#053e68]   border-2 text-xl rounded-xl px-6 py-3 w-fit disabled:bg-gray-400"
                >
                    上一頁
                </button>
            )

        }
    }

    return (

        <div className="max-w-5xl mx-auto px-5">

            <div className=" w-full mb-24 md:px-[20px] mx-auto overflow-hidden z-10 min-h-[700px] ">
                {formStep < 3 && (
                    <div className='py-8 border-b-2 border-grey'>
                        <h2 className='font-normal text-3xl'>登記補習個案</h2>

                    </div>
                )}

                {formStep < 2 && (
                    <div className="">
                        <div className="mx-auto md:p-5 py-8 border-b-2 border-grey md:px-4 ">
                            <p className="text-base md:text-lg mb-3">請確保下列所有資料準確。</p>
                            <p className="text-base md:text-lg">本平台十分注重個人私隱保護，所有已登記的資料將會絕對保密。</p>
                            <span className="rounded-xl bg-[#053e68] px-4 py-2 text-white text-xl inline-block font-bold">
                                {formStep === 0 && ('補習資料')}
                                {formStep === 1 && ('聯絡資料')}

                            </span>
                            <span className="text-lg text-red font-bold ml-4">*為必須填寫</span>
                        </div>
                    </div>
                )}


                <div className="md:px-5 py-8 pb-4 bg-white">
                    <form noValidate method="post">
                        {formStep === 0 && (
                            <section>
                                <div className="grid md:grid-cols-2 gap-5">
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelectGroup
                                            itemList={gradeList}
                                            name={`student_grade`}
                                            control={control}
                                            rules={{ "required": true }}
                                            label={'學生年級(必填)'}

                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={genderList}
                                            label={`學生性別(必填)`}
                                            inputProps={register(`student_gender`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`student_gender`)}
                                            error={errors.student_gender}
                                            helperText={errors.student_gender?.message}
                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelectGroup
                                            itemList={all_subject}
                                            name={`tutor_subject`}
                                            control={control}
                                            rules={{ "required": true }}
                                            label={'補習科目(可多選)(必填)'}
                                            isMulti={true}

                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelectGroup
                                            itemList={tutorWays}
                                            name={`tutor_way`}

                                            control={control}
                                            rules={{ "required": true }}
                                            label={'補習形式(必填)'}
                                        />

                                    </div>
                                    <div className="relative">
                                        {(watch('tutor_way')?.label) !== '視像' && (
                                            <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        )}
                                        <FormSelectGroup
                                            itemList={district_list}
                                            name={`district`}
                                            control={control}
                                            isDisabled={(watch('tutor_way')?.label !== '視像') ? false : true}
                                            label={'補習地區(必填)'}
                                        />
                                    </div>

                                    <div className="relative">
                                        {(watch('tutor_way')?.label) !== '視像' && (
                                            <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        )}
                                        <TextField label={`補習地點(必填)`} variant="outlined" size="small" fullWidth {...register(`location`)}  error={!!errors?.location} helperText={errors?.location ? errors.location.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} disabled={(watch('tutor_way')?.label !== '視像') ? false : true} />
                                        <p className="mt-1 text-sm mb-0 pl-3">大概位置即可，例如：美孚新邨</p>
                                    </div>
                                    <FormSelect
                                        itemList={traffic_way}
                                        label={`交通方法`}
                                        inputProps={register(`traffic_way`)}
                                        control={control}
                                        name={`traffic_way`}
                                        value={watch(`traffic_way`)}
                                        error={errors.traffic_way}
                                        helperText={errors.traffic_way?.message}
                                    />
                                    <div>
                                        <TextField label={`轉乘方法`} variant="outlined" size="small" fullWidth {...register(`transfer_way`)} error={!!errors?.transfer_way} helperText={errors?.transfer_way ? errors.transfer_way.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        <p className="mt-1 text-sm mb-0 pl-3">例如：荃灣站轉乘238M</p>
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={lesson_per_week}
                                            label={`每星期堂數(必填)`}
                                            inputProps={register(`lesson_per_week`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`lesson_per_week`)}
                                            error={errors.lesson_per_week}
                                            helperText={errors.lesson_per_week?.message}
                                        />
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={hour_per_lesson}
                                            label={`每堂時數(小時)(必填)`}
                                            inputProps={register(`hour_per_lesson`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`hour_per_lesson`)}
                                            error={errors.hour_per_lesson}
                                            helperText={errors.hour_per_lesson?.message}
                                        />
                                    </div>

                                    <div>
                                        <TextField label={`補習時間`} variant="outlined" size="small" fullWidth {...register(`tutor_time`)} error={!!errors?.tutor_time} helperText={errors?.tutor_time ? errors.tutor_time.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        <p className="mt-1 text-sm mb-0 pl-3">例如：星期一/三4:30-7:00pm內</p>
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={expect_tutor_fee}
                                            label={`每小時學費(HKD/小時)(必填)`}
                                            inputProps={register(`tutor_fee`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`tutor_fee`)}
                                            error={errors.tutor_fee}
                                            helperText={errors.tutor_fee?.message}
                                        />
                                        <div className="flex items-center ">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-[#053e68]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <a href="/tuitionfee" target="_blank" className="block text-[#053e68] text-sm mb-0 pl-1 font-bold">學費參考</a>
                                        </div>

                                    </div>

                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={tutor_gender}
                                            label={`導師性別(必填)`}
                                            inputProps={register(`tutor_gender`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`tutor_gender`)}
                                            error={errors.tutor_gender}
                                            helperText={errors.tutor_gender?.message}
                                        />
                                    </div>
                                    <div className="relative">
                                        <FormSelect
                                            itemList={tutor_requirement}
                                            label={`導師學歷要求`}
                                            inputProps={register(`negotiable`)}
                                            value={watch(`negotiable`)}
                                            error={errors.negotiable}
                                            helperText={errors.negotiable?.message}
                                        />
                                    </div>








                                </div>




                            </section>
                        )}
                        {formStep === 1 && (
                            <section>
                                <div className="grid md:grid-cols-2 gap-5">
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <TextField label={`如何稱呼你(必填)`} variant="outlined" size="small" fullWidth {...register(`appellation`)} error={!!errors?.appellation} helperText={errors?.appellation ? errors.appellation.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        <p className="mt-1 text-sm mb-0 pl-3">例如：陳小姐／林先生</p>
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <TextField label="手提電話" variant="outlined" size="small" fullWidth {...register(`contact`, {
                                            required: "需填寫", pattern: { value: /^[0-9]+$/, message: '只接受數字' }, "maxLength": {
                                                "value": 20,
                                                "message": "請輸入不多於20個數字"
                                            }
                                        })} error={!!errors?.contact} helperText={errors?.contact ? errors.contact.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        <p className="mt-1 text-sm mb-0 pl-3">請確保號碼正確以便聯絡</p>
                                    </div>
                                    <div className="relative">
                                        <div className="absolute md:-top-2  -top-4  -left-0 md:-left-1 text-red font-bold text-lg">*</div>
                                        <FormSelect
                                            itemList={yes_no}
                                            label={`可WhatsApp?`}
                                            inputProps={register(`can_whatsapp`, {
                                                "required": "需填寫"
                                            })}
                                            value={watch(`can_whatsapp`)}
                                            error={errors.can_whatsapp}
                                            helperText={errors.can_whatsapp?.message}
                                        />
                                    </div>

                                    <div className="relative">

                                        <TextField label={`方便聯絡的時段`} variant="outlined" size="small" fullWidth {...register(`contact_time`)} error={!!errors?.contact_time} helperText={errors?.contact_time ? errors.contact_time.message : null} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                        <p className="mt-1 text-sm mb-0 pl-3">例如:6:00-10:00pm</p>
                                    </div>



                                </div>
                                <div className="mt-7">
                                    <div className="text-xl py-[8px] px-[12px] mb-[10px]">特別要求/備註</div>
                                    <TextField placeholder="(如有)" variant="outlined" size="small" fullWidth {...register(`remarks`)} multiline rows={7} InputProps={{ style: textFieldStyles.inputWrapper }} inputProps={{ style: textFieldStyles.input }} />
                                </div>



                            </section>
                        )}

                        {formStep === 2 && (
                            <section>
                                <h5 className="text-[#053e68]">請核對以下資料是否正確，然後按下提交鍵</h5>
                                <h5 className="text-[#053e68]">如需更改資料，可返回上一頁進行修改</h5>
                                <div className="my-5">
                                    <div className="flex">
                                        <h5 className="">學生年級／性別：</h5>
                                        <h5 className="font-normal">{(watch('student_grade')).label}（{watch('student_gender')}）</h5>

                                    </div>
                                    <div className="flex">
                                        <h5 className="">補習科目：</h5>
                                        <h5 className="font-normal">{(watch('tutor_subject')).map((subject) => {

                                            return subject.label
                                        })}</h5>

                                    </div>
                                    <div className="flex">
                                        <h5 className="">補習地點：</h5>
                                        <h5 className="font-normal">{(watch('district'))?.label} {(watch('location'))} ({(watch('tutor_way'))?.label})</h5>

                                    </div>
                                    {watch('traffic_way') && (
                                        <div className="flex">
                                            <h5 className="">交通方法：</h5>
                                            <h5 className="font-normal">{watch('traffic_way')}</h5>
                                        </div>
                                    )}
                                    {watch('transfer_way') && (
                                        <div className="flex">
                                            <h5 className="">轉乘方法：</h5>
                                            <h5 className="font-normal">{watch('transfer_way')}</h5>
                                        </div>
                                    )}

                                    <div className="flex">
                                        <h5 className="">每星期堂數：</h5>
                                        <h5 className="font-normal">{watch('lesson_per_week')}</h5>

                                    </div>
                                    <div className="flex">
                                        <h5 className="">每堂時數(小時)：</h5>
                                        <h5 className="font-normal">{watch('hour_per_lesson')}</h5>

                                    </div>
                                    {watch('tutor_time') && (

                                        <div className="flex">
                                            <h5 className="">補習時間：</h5>
                                            <h5 className="font-normal">{watch('tutor_time')}</h5>
                                        </div>
                                    )}
                                    <div className="flex">
                                        <h5 className="">每小時學費(HKD)：</h5>

                                        <h5 className="font-normal">{watch('tutor_fee')}</h5>

                                        <h5 className="font-normal">{watch('tutor_fee_per_hour')}</h5>


                                    </div>
                                    <div className="flex">
                                        <h5 className="">導師性別/要求：</h5>
                                        <h5 className="font-normal">{watch('tutor_gender')}/{watch('negotiable')}</h5>

                                    </div>

                                    <div className="flex mt-10">
                                        <h5 className="">如何稱呼：</h5>
                                        <h5 className="font-normal">{watch('appellation')}</h5>

                                    </div>
                                    <div className="flex">
                                        <h5 className="">聯絡電話：</h5>
                                        <h5 className="font-normal">{watch('contact')}</h5>

                                    </div>
                                    <div className="flex">
                                        <h5 className="">可WhatsApp：</h5>
                                        <h5 className="font-normal">{watch('can_whatsapp')}</h5>

                                    </div>

                                    {watch('contact_time') && (
                                    <div className="flex">
                                        <h5 className="">方便聯絡的時段：</h5>
                                        <h5 className="font-normal">{watch('contact_time')}</h5>
                                    </div>
                                    )}


                                </div>

                                {/* <div className="relative">
                                    <div className="absolute -top-0 -left-3 text-red font-bold text-lg">*</div>
                                    <FormControlLabel
                                        control={<Checkbox name="checkbox" {...register(`agreement`, {
                                            required: "需填寫"
                                        })} />}
                                        value={true}
                                        label={`我同意私隱政策聲明`}
                                        error={errors.agreement}
                                        helperText={errors.agreement?.message}
                                    />
                                </div> */}
                                {/* <div className="flex items-center mt-1 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-light-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <a href="/parentzone" target="_blank" className="block text-light-blue text-[5px] mb-0 pl-1 font-bold">私隱政策聲明</a>
                                </div> */}


                                {watch('remarks') && (

                                <div className="flex mt-10 flex-col">
                                    <h5 className="">特別要求/備註：</h5>
                                    <h5 className="font-normal">{watch('remarks')}</h5>
                                </div>
                                )}


                                {/* <pre className="text-black mt-3">{JSON.stringify(watch(), null, 2)}</pre> */}
                    </section>

                        )}

                    {formStep === 3 && (
                        <section className="font-normal text-center mt-12">
                            <div className='text-center'>
                                <img className="w-16 m-auto mb-8" src={tickIcon} alt="Tutor Plus 香港首席導師協會" />
                                <h3 className='text-dark-blue mb-5 font-normal'>你的個案已成功提交</h3>
                                <h3 className='text-dark-blue mb-16 font-normal'>請稍等，我們稍後會有專人聯絡你</h3>

                                <h5 className='font-normal'>負責聯絡你的職員：黃小姐</h5>
                                <h5 className='font-normal'>熱線電話：5938 1383</h5>
                                <h5 className='font-normal'>個案編號：{caseNumber}</h5>
                                <h5 className='text-dark-blue mt-10 pb-4 font-normal'>多謝使用 Tutor Plus香港首席導師協會 的服務！</h5>
                                <a href="/" className='home-cta bg-dark-blue hover:bg-dark-blue  text-white text-lg px-8 py-2'>回到主頁</a>

                            </div>

                        </section>

                    )}


                    <div className="flex justify-between">
                        {renderPrevButton()}
                        {renderNextButton()}
                    </div>





                </form>
            </div>
            {/* <pre className="text-black mt-3">{JSON.stringify(watch(), null, 2)}</pre> */}
        </div>

        </div >

    )
}

