import React from 'react'
import { Helmet } from "react-helmet";

export default function parentzone() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/parentzone" />
            </Helmet>
        <section className='section-padding'>
            <div className='section-container'>
                <h2 className='heading'>私隱政策聲明</h2>
                <div className="mt-[50px]">

                    <div className="max-h-[700px] overflow-scroll p-5 rounded-[3px] mb-[20px] border-[#e3e3e3] border-[1px] bg-[#f5f5f5]">

                        根據Tutor Plus（香港首席導師協會）的政策﹐本平台確保將客戶（即本平台的實際客戶和網站用戶）提供的所有資料保密。
                        <br/><br/>
                        客戶在瀏覽Tutor Plus（香港首席導師協會）網站時﹐本平台不會主動收集任何用以識別瀏覽者身份的資料（「個人資料」）。客戶就Tutor Plus（香港首席導師協會）提供的服務提出咨詢或提交申請時，需要提交某些個人資料。在這種情況下，客戶將會收到相關的「個人資料收集聲明」﹐以了解我們收集這些資料的目的與用途（包括傳送和公布這些資料的範圍）﹔客戶亦有權查閱和更改這些個人資料。 某些網站設定了Cookie檔案，其目的只是為了讓客戶在下次登入同一網站時更加方便。
                        <br/><br/>
                        在客戶向Tutor Plus（香港首席導師協會）提交個人資料後，本平台會將這些資料安全嚴密地儲存在本平台的加密系統內。只有經過相關的專業訓練，並且獲得授權的職員，才能夠查閱這些個人資料。在未經客戶本人同意的情況下，我們絕對不會泄露客戶的個人資料。然而，我們可能會因應客戶的個人資料﹐而不時向客戶發送一些直接推廣的訊息，介紹我們的產品和服務。如果客戶以書面方式或電郵通知我們，表示不願再收到這些訊息，我們將立即停止發送。 客戶亦可在登記時選擇「拒絕接收服務」。
                        <br/><br/>
                        本平台確保客戶的私隱得到妥善的保障。如果客戶對我們的私隱政策和運作方式有任何疑問，歡迎Whatsapp/致電12345678 或電郵至hk.tutorplus@gmail.com向本平台職員查詢。

                        <br/><br/>

                        個人資料收集聲明
                        <br/><br/>
                        閣下提供的資料將會用作處理你的咨詢或服務申請。本平台不會把  閣下的資料和身份向任何第三者透露。 閣下有權要求查閱或修改有關 閣下在本平台紀錄裡的資料；在任何情況下，如果 閣下希望查閱或修改你 閣下本平台紀錄裡的個人資料，請Whatsapp/致電12345678 或電郵至hk.tutorplus@gmail.com 本平台的職員查詢。

                        <br />
                        <br />

                    </div>


                </div>

            </div>
        </section>
        </div>
    )
}
