import React from 'react'
import { Helmet } from "react-helmet";


export default function tuitionfee() {

    return (
        <div className='min-h-[800px]'>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/tuitionfee" />
            </Helmet>
            <section className=' max-w-5xl mx-auto px-7 py-14'>
                <div className='py-8 border-b-2 border-grey'>
                    <h2 className='font-normal text-3xl'>私人上門補習學費參考（每小時）</h2>

                </div>
                <div className='mt-7'>
                    <p className='mb-1'>根據以往經驗和市場狀況，家長／學生學生可參考以下上門補習學費。</p>
                    <p>家長／學生不必硬性跟隨以下價錢，價錢會因導師資歷及補習地區而有所改變。</p>

                    <table className='w-full mt-10 text-center text-sm md:text-base'>
                        <tr className='bg-[#4094bf] text-white '>
                            <th className='p-4'>學生年級</th>
                            <th className='p-4'>LV4或以下</th>
                            <th className='p-4'>LV5-5**</th>
                            <th className='p-4'>全職導師</th>
                            <th className='p-4'>現職教師</th>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>幼稚園</td>
                            <td className='p-4'>$90-130</td>
                            <td className='p-4'>$110-160</td>
                            <td className='p-4'>$100-200</td>
                            <td className='p-4'>$150-200</td>

                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>小一</td>
                            <td className='p-4'>$90-130</td>
                            <td className='p-4'>$110-160</td>
                            <td className='p-4'>$100-200</td>
                            <td className='p-4'>$180-250</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>小二</td>
                            <td className='p-4'>$100-140</td>
                            <td className='p-4'>$120-170</td>
                            <td className='p-4'>$100-200</td>
                            <td className='p-4'>$180-250</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>小三</td>
                            <td className='p-4'>$100-140</td>
                            <td className='p-4'>$120-170</td>
                            <td className='p-4'>$100-200</td>
                            <td className='p-4'>$180-250</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>小四</td>
                            <td className='p-4'>$110-150</td>
                            <td className='p-4'>$130-170</td>
                            <td className='p-4'>$120-200</td>
                            <td className='p-4'>$180-250</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>小五</td>
                            <td className='p-4'>$110-150</td>
                            <td className='p-4'>$130-170</td>
                            <td className='p-4'>$120-200</td>
                            <td className='p-4'>$190-250</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>小六</td>
                            <td className='p-4'>$120-160</td>
                            <td className='p-4'>$140-180</td>
                            <td className='p-4'>$130-200</td>
                            <td className='p-4'>$200-250</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>中一</td>
                            <td className='p-4'>$130-160</td>
                            <td className='p-4'>$150-180</td>
                            <td className='p-4'>$150-250</td>
                            <td className='p-4'>$200-400</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>中二</td>
                            <td className='p-4'>$130-160</td>
                            <td className='p-4'>$150-180</td>
                            <td className='p-4'>$150-250</td>
                            <td className='p-4'>$200-400</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>中三</td>
                            <td className='p-4'>$140-170</td>
                            <td className='p-4'>$160-180</td>
                            <td className='p-4'>$150-250</td>
                            <td className='p-4'>$200-400</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>中四</td>
                            <td className='p-4'>$140-170</td>
                            <td className='p-4'>$160-190</td>
                            <td className='p-4'>$180-300</td>
                            <td className='p-4'>$250-450</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>中五</td>
                            <td className='p-4'>$150-180</td>
                            <td className='p-4'>$170-200</td>
                            <td className='p-4'>$180-300</td>
                            <td className='p-4'>$250-450</td>
                        </tr>
                        <tr className=''>
                            <td className='p-4'>中六</td>
                            <td className='p-4'>$160-190</td>
                            <td className='p-4'>$180-220</td>
                            <td className='p-4'>$200-350</td>
                            <td className='p-4'>$300-450</td>
                        </tr>
                        <tr className='bg-[#b7d9ed]'>
                            <td className='p-4'>大專或以上</td>
                            <td className='p-4'></td>
                            <td className='p-4'>$190-300</td>
                            <td className='p-4'>$300-400</td>
                            <td className='p-4'>$400或以上</td>
                        </tr>
                    </table>

                </div>
                <div className='mt-10'>
                <a href="/registerForm" className='home-cta bg-blue text-xl px-10 py-4'>立即配對</a>
                </div>
            </section>


        </div>
    )
}
