import React from 'react'
import brandLogo from './../images/logo_4.png'
import fbLogo from './../images/FB.png'
import wtsappLogo from './../images/WTS.png'
import instagramLogo from './../images/IG.png'

export default function Footer() {
  return (

      <footer className='bg-[#333333]'>
      <div className='section-container py-[20px]'>
        <div className='block sm:flex flex-col md:flex-row justify-between items-center'>
          <div className='block sm:flex items-center'>
            <a className="hidden sm:block" href="/">
              <div className="text-5xl text-white w-40 flex ml-0 sm:ml-8 justify-center items-center">
                <img src={brandLogo} alt="Tutor Plus 香港首席導師協會" />
              </div>
            </a>

            <ul className="block sm:flex flex-wrap ml-2 sm:ml-[20px] mt-4 sm:mt-16 lg:ml-[50px]">
              <li className="px-0 sm:px-[15px]">
                <a href="/" className='text-white'>主頁</a>
              </li>
              <li className="pt-4 sm:pt-0 px-0 sm:px-[15px]">
                <a href="/showcase" className='text-white'>補習個案</a>
              </li>
              <li className="pt-4 sm:pt-0 px-0 sm:px-[15px]">
                  <a href="/tutorzone" className='block text-white cursor-default pointer-events-none'>導師專區</a>
                  <ul className='flex sm:block'>
                    {/* <a href="/tutorzone" className='block text-white opacity-60 py-1 hover:opacity-100'>登入／註冊介面</a> */}
                    <li className='pr-2'><a href="/tutorfee" className='block text-white opacity-60 py-1 hover:opacity-100'>導師收費</a></li>
                    <li className='pr-2'><a href="/tutorinfo" className='block text-white opacity-60 py-1 hover:opacity-100'>更新導師資料</a></li>
                    <li className='pr-2'><a href="/tutorzone" className='block text-white opacity-60 py-1 hover:opacity-100'>導師條款與守則</a></li>
                  </ul>

              </li>
              <li className="pt-4 sm:pt-0 px-0 sm:px-[15px]">
                <a href="/parentzone" className='text-white cursor-default pointer-events-none'>家長專區</a>
                <ul className='flex sm:block'>
                <li className='pr-2'><a href="/registerform" className='block text-white opacity-60 py-1 hover:opacity-100'>登記補習個案</a></li>
                <li className='pr-2'><a href="/tuitionfee" className='block text-white opacity-60 py-1 hover:opacity-100'>學費參考</a></li>
                <li className='pr-2'><a href="/parentzone" className='block text-white opacity-60 py-1 hover:opacity-100'>私隱政策聲明</a></li>
                </ul>
              </li>
              <li className="pt-4 sm:pt-0 px-0 sm:px-[15px]">
                <a href="/contactus" className='text-white'>聯絡我們</a>
              </li>

            </ul>
          </div>
          <div className="flex mt-[20px] md:mt-0">
          <a target="_blank" href="https://api.whatsapp.com/send/?phone=85256295964&text&type=phone_number&app_absent=0">
            <img className="w-8 mx-2" src={wtsappLogo} alt="Tutor Plus 香港首席導師協會 Whatsapp" />
          </a>
          <a target="_blank" href="https://www.facebook.com/hktutorplus">
            <img className="w-8 mx-2" src={fbLogo} alt="Tutor Plus 香港首席導師協會 Facebook" />
          </a>
          <a target="_blank" href="https://www.instagram.com/tutor_plus_hk/">
            <img className="w-8 mx-2 mr-16" src={instagramLogo} alt="Tutor Plus 香港首席導師協會 Instagram" />
          </a>
          </div>
          <a className="block sm:hidden mt-4" href="/">
              <div className="text-5xl text-white w-36 flex ml-0 sm:ml-8 justify-center items-center">
                <img src={brandLogo} alt="Tutor Plus 香港首席導師協會" />
              </div>
            </a>
        </div>
        <div className='text-center mt-12'>
          <span className='text-white text-center'>Tutor Plus © 2022</span>
        </div>
      </div>
    </footer>


  )
}
