import React, { useState } from 'react'
import store from '../app/store'
import { auth, logout } from '../firebase'
import Modal from './PopupModal'
import brandLogo from './../images/logo_3.png'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth'
import PopupModal from './PopupModal'

export default function Header() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false)
    const [isDropDownOpen, setDropDownOpen] = useState(false)
    const [isLogin, setLogin] = useState(null);

    let unsubscribe = store.subscribe(() =>
        setLogin(store.getState().loginItems.auth)
    )

    const [mobileCollapse, setMobileCollapse] = useState(false)
    const [mobileCollapse2, setMobileCollapse2] = useState(false)
    const initMobileMenu = () => {
        if (window.innerWidth > 767) {
            setIsOpen(false)
            setMobileCollapse(false)
            setMobileCollapse2(false)
        }
    }
    const onLogout = () => {
        logout()
        navigate('/')
    }
    window.addEventListener("resize", initMobileMenu)
    return (
        <header className='z-[999] sticky left-0 top-0'>
            <nav className=' w-full bg-[#fff] shadow-[0_4px_2px_-2px_rgba(149,157,165,0.2)] md:h-auto'>
                <div className='section-container'>
                    <div className='flex justify-between items-center'>
                        <div className='flex items-center'>
                            <a href="/">
                                <div className='max-w-[170px] lg:max-w-[250px] py-4'>
                                    <img className="w-40 mr-8" src={brandLogo} alt="logo"></img>
                                </div>
                            </a>

                            <div className='ml-[30px] lg:ml-[50px] text-lg hidden md:block'>
                                <ul className='flex '>
                                    <li>
                                        <a href="/" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent '>主頁</a>
                                    </li>
                                    {/* <li className='relative group'>
                                        <a href="/" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent '>服務簡介</a>
                                        <ul className='py-[10px] pt-0 min-w-[160px] max-w-[240px] scale-0 transition-all ease-linear origin-top-left absolute top-full -right-full z-10 group-hover:scale-100'>
                                            <li>
                                                <a href="/" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>服務簡介</a>
                                            </li>
                                            <li>
                                                <a href="/" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>服務簡介</a>
                                            </li>
                                            <li>
                                                <a href="/" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>服務簡介</a>
                                            </li>
                                        </ul>
                                    </li> */}
                                    <li>
                                        <a href="/showcase" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent '>補習個案</a>
                                    </li>
                                    <li className='relative group'>
                                        <a href="/" className='flex items-center px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent cursor-default pointer-events-none '>
                                            導師專區
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-3`} fill="none" viewBox="0 0 20 20" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </a>
                                        <ul className='py-[10px] pt-0 min-w-[160px] max-w-[240px] scale-0 transition-all ease-linear origin-top-left absolute top-full z-10 group-hover:scale-100'>
                                            {/* <li>
                                                <a href="/" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>登入／註冊介面</a>
                                            </li> */}
                                          {/*
                                            {!isLogin && (
                                                <li>
                                                     <Modal type="dropdown_choice" />
                                                </li>
                                            )}
                                               */}
                                            {isLogin && (
                                                <li>
                                                    <a href="/tutorinfo" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>更新導師資料</a>
                                                </li>
                                            )}

                                            <li>
                                                <a href="/tutorfee" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>導師收費</a>
                                            </li>
                                            <li>
                                                <a href="/tutorzone" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>導師條款與守則</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className='relative group'>
                                        <a href="/" className='flex items-center px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent cursor-default pointer-events-none '>
                                            家長專區
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-3`} fill="none" viewBox="0 0 20 20" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </a>
                                        <ul className='py-[10px] pt-0 min-w-[160px] max-w-[240px] scale-0 transition-all ease-linear origin-top-left absolute top-full z-10 group-hover:scale-100'>
                                            <li>
                                                <a href="/registerform" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>登記補習個案</a>
                                            </li>
                                            <li>
                                                <a href="/tuitionfee" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>學費參考</a>
                                            </li>
                                            <li>
                                                <a href="/parentzone" className='bg-white block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent '>私隱政策聲明</a>
                                            </li>
                                        </ul>
                                    </li>


                                    <li>
                                        <a href="/contactus" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent '>聯絡我們</a>
                                    </li>

                                </ul>
                            </div>
                        </div>


                        {!isLogin && (
                            <div className='flex items-center'>


                                <div className='hidden md:block'>
                                    <Modal type="btn" />
                                </div>

                                <div className='block cursor-pointer ml-[20px] md:hidden' onClick={() => setIsOpen(!isOpen)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                        )}
                        {isLogin && (
                            <div className='flex items-center relative '>
                                <button className='relative items-center border-2 rounded-full border-black p-3 cursor-pointer hidden md:flex' onClick={() => setDropDownOpen(!isDropDownOpen)}>
                                    {isLogin?.email}
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`ml-3 h-4 w-4 ${isDropDownOpen && 'rotate-180'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                {isDropDownOpen && (
                                    <div className='absolute top-full w-full bg-white hidden md:block'>
                                        <div className='border-2 border-t-0 p-4'>
                                            <a href="/profile" className='text-white rounded-full bg-[#053e68] px-[20px] py-[12px] font-bold hover:bg-[#053e68] hover:opacity-80 block mb-3 text-center'>收藏個案</a>
                                            <a href="/tutorinfo" className='text-white bg-[#053e68] rounded-full px-[20px] py-[12px] font-bold hover:bg-[#053e68] hover:opacity-80 block mb-3 text-center'>更新個人資料</a>
                                            <button onClick={onLogout} className='w-full text-white bg-[#053e68] rounded-full px-[20px] py-[12px] font-bold hover:bg-[#053e68] hover:opacity-80 block'>登出</button>
                                        </div>

                                    </div>
                                )}


                                <div className='block cursor-pointer ml-[20px] md:hidden' onClick={() => setIsOpen(!isOpen)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>

                        )}




                    </div>
                </div>
            </nav>
            <div className={`fixed top-[60px] w-full bg-white ${isOpen ? "block h-full" : "hidden"}`}>
                <div className=''>
                    <ul className='mt-[20px]'>
                        <li>
                            <a href="/" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>主頁</a>
                        </li>

                        <li>
                            <a href="/showcase" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>補習個案</a>
                        </li>

                        <li className='relative'>
                            <div onClick={() => setMobileCollapse(!mobileCollapse)}>
                                <span className='inline-block px-3 py-[7px] pl-[20px] text-black w-[85%] opacity-60 mr-[50px] hover:opacity-100'>導師專區</span>
                                <div className='cursor-pointer absolute top-0 right-0 mt-[2px] mr-[10px] w-[35px] h-[35px] text-center opacity-60 flex justify-center items-center hover:opacity-100' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                                    </svg>
                                </div>
                            </div>

                            <ul className={`ml-[30px] overflow-hidden transition-all ${mobileCollapse ? "max-h-[1000px]" : "max-h-0"}`}>
                                {/* <li>
                                    <a href="/" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>登入／註冊介面</a>
                                </li> */}
                                 {/*
                                    <a href=
                                 {!isLogin && (
                                    <li>
                                        <Modal type="dropdown_choice" />
                                    </li>
                                )}
                                 */}
                                {isLogin && (
                                    <li>
                                        <a href="/tutorinfo" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>更新導師資料</a>
                                    </li>
                                )}
                                <li>
                                    <a href="/tutorfee" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>導師收費</a>
                                </li>

                                <li>
                                    <a href="/tutorzone" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>導師條款與守則</a>
                                </li>

                            </ul>
                        </li>
                        <li className='relative'>
                            <div onClick={() => setMobileCollapse2(!mobileCollapse2)}>
                                <span className='inline-block px-3 py-[7px] pl-[20px] text-black w-[85%] opacity-60 mr-[50px] hover:opacity-100'>家長專區</span>
                                <div className='cursor-pointer absolute top-0 right-0 mt-[2px] mr-[10px] w-[35px] h-[35px] text-center opacity-60 flex justify-center items-center hover:opacity-100' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse2 ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse2 ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                                    </svg>
                                </div>
                            </div>

                            <ul className={`ml-[30px] overflow-hidden transition-all ${mobileCollapse2 ? "max-h-[1000px]" : "max-h-0"}`}>
                                <li>
                                    <a href="/registerform" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>登記補習個案</a>
                                </li>
                                <li>
                                    <a href="/tuitionfee" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>學費參考</a>
                                </li>
                                <li>
                                    <a href="/parentzone" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>私隱政策聲明</a>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <a href="/contactus" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>聯絡我們</a>
                        </li>


                    </ul>


                    {/* <div className='p-[15px] pl-[20px]'>
                        導師
                    </div> */}
                    <hr className='w-full border-[#e1e1e1]' />

                    <div className='block text-left mt-[15px] mx-5 md:hidden'>
                        {!isLogin && (
                            <Modal type="btn" />
                        )}
                        {isLogin && (
                            <div className=''>
                                <a href="/profile" className='text-white bg-[#053e68] rounded-[3px] px-[20px] py-[12px] font-bold block mb-3 text-center'>收藏個案</a>
                                <a href="/tutorinfo" className='text-white bg-[#053e68] rounded-[3px] px-[20px] py-[12px] font-bold block mb-3 text-center'  >更新個人資料</a>
                                <button onClick={logout} className='w-full text-white bg-[#053e68] rounded-[3px] px-[20px] py-[12px] font-bold block'>登出</button>
                            </div>

                        )}

                    </div>
                </div>
            </div>
        </header>
    )
}
