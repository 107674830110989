import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { auth, logInWithEmailAndPassword, signInWithGoogle, signInWithFacebook, registerWithEmailAndPassword, sendPasswordReset, sendVerifiedEmail } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import googleIcon from './../images/Google.png'
import fbIcon from './../images/FB-V2.png'
import { useNavigate } from "react-router-dom";
import brandLogo from './../images/logo_3.png'

const btn = {
    color: 'white',
    bgcolor: '#053e68',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: 4,
    fontWeight: 'bold',
    "&:hover": {
        'background-color': '#053e68'
    }

}
const btn2 = {
    width: '100%',
    display: 'block',
    fontSize: '1.125rem',
    bgcolor: 'white',
    borderRadius: '0px',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    paddingTop: '15px',
    paddingBottom: '15px',
    color: 'rgba(0,0,0,.6)',

    textAlign: 'left',
    "&:hover": {
        bgcolor: 'white',
        color: 'rgba(0,0,0,1)'
    }
}
const stylebox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    paddingLeft: '10',
    paddingRight: '10'
};

const textFieldStyles = ({
    textField: {

    },
    inputWrapper: {
        borderRadius: 20,
        bgcolor: '#000',

    },
    input: {
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16
    },
    label: {
        top: -6
    }


});

export default function PopupModal({type}) {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const { control, formState: { errors }, handleSubmit, watch, reset } = useForm();
    const {
        formState: { errors: errorsLogin },
        handleSubmit: handleSubmitLogin,
        control: controlLogin,
        reset: resetLogin
    } = useForm();

    const {
        formState: { errors: errorsReset },
        handleSubmit: handleSubmitReset,
        control: controlReset,
        reset: resetReset
    } = useForm();

    const onSubmit = (data, event) => {
        event.preventDefault()


        registerWithEmailAndPassword(data.name, data.email, data.password).then((result) => {
            if (result) {


                sendVerifiedEmail()

                /* setPage(2) */
                reset();
                navigate("/tutorinfo");
            }
        })
    }
    const onSubmitLogin = (data) => {
        /* console.log(data);
        console.log(isSubmitSuccessfulLogin); */
        const notification = toast.loading('請稍等...')

        logInWithEmailAndPassword(data.email_login, data.password_login).then((result) => {

            if (result) {
                toast.success('登入成功', {
                    id: notification,
                })
                resetLogin();
                handleClose();
                navigate("/");
            } else {
                toast.error("登入失敗", {
                    id: notification,
                })
            }

        })
    }
    const onSubmitReset = (data) => {

        sendPasswordReset(data.email_reset)

    }

    const passwordRef = useRef({});
    passwordRef.current = watch("password", "");

    useEffect(() => {

        if (loading) {
            // maybe trigger a loading screen

            return;
        }
        if (user) {
            /* console.log(user); */
        }
    }, [user, loading]);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setPage(0)
        reset();
        resetLogin();
        resetReset();
    };
    const [page, setPage] = useState(0)



    return (
        <div className=''>
            <Button sx={type === 'btn'? btn : btn2} className={type === 'btn' ? 'hover:bg-black tutor-register':'hover:bg-black tutor-login'} onClick={handleOpen}>{type === 'btn' ? '導師登入':'登入／註冊介面'}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={stylebox}>

                    <div className='cursor-pointer absolute right-[10px] top-[10px]' onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#aaa" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    {page === 0 && (
                        <form noValidate onSubmit={handleSubmitLogin(onSubmitLogin)}>
                            <div className='text-center px-14'>
                                <img className="w-48 my-8 mx-auto" src={brandLogo} alt="Tutor Plus 香港首席導師協會"/>
                                <div className='text-xl mb-6'>導師登入</div>
                                <div className='mb-4'>
                                    <Controller
                                        name="email_login"
                                        control={controlLogin}
                                        defaultValue=""
                                        className=""
                                        rules={{
                                            required: true,
                                        }}
                                        render={
                                            ({ field }) =>
                                                <TextField {...field} InputProps={{
                                                    style: textFieldStyles.inputWrapper
                                                }} inputProps={{ style: textFieldStyles.input }} label="電郵地址" variant="outlined" fullWidth size="small" />
                                        }
                                    />
                                    {errorsLogin.email_login?.type === 'required' && (
                                        <div className="text-red text-left mt-1 pl-2">請輸入電郵地址</div>
                                    )}

                                    {/* <TextField onChange={(e) => setEmail(e.target.value)} size="small" fullWidth id="outlined-basic" label="登入名稱" variant="outlined" /> */}
                                </div>

                                <div className='relative mb-3'>
                                    <Controller
                                        name="password_login"
                                        control={controlLogin}
                                        defaultValue=""
                                        className=""
                                        rules={{
                                            required: true,
                                        }}

                                        render={
                                            ({ field }) =>
                                                <TextField {...field} label="密碼" InputProps={{
                                                    style: textFieldStyles.inputWrapper
                                                }} inputProps={{ style: textFieldStyles.input }} variant="outlined" fullWidth size="small" type={showPassword ? "text" : "password"} />
                                        }
                                    />
                                    {errorsLogin.password_login?.type === 'required' && (
                                        <div className=" text-red text-left mt-1 pl-2">請輸入密碼</div>
                                    )}
                                    {/* <TextField onChange={(e) => setPassword(e.target.value)} size="small" fullWidth type={showPassword ? "text" : "password"} id="fullWidth" classes="relative" label="密碼" variant="outlined" /> */}
                                    {/* <button className='absolute right-[10px] top-1/2 -translate-y-1/2' onClick={() => setShowPassword(s => !s)}>
                                        {!showPassword && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                            </svg>
                                        )}
                                        {showPassword && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        )}
                                    </button> */}

                                </div>
                                <p className='text-right text-xs text-grey' ><span className='cursor-pointer' onClick={() => { setPage(3) }}>忘記密碼?</span></p>
                                <button className='modal-btn text-grey border-grey mb-4 hover:text-white hover:bg-dark-blue hover:border-dark-blue '>登入</button>
                                <button id="tutor-register"className='modal-btn text-dark-blue border-dark-blue  hover:text-white hover:bg-dark-blue mb-4' onClick={() => { setPage(1) }}>註冊</button>
                                <p className="text-grey font-bold mb-4">OR</p>
                                <div className='grid grid-cols-2 gap-[10px]'>
                                    <div className='flex-1'>
                                        <button onClick={signInWithFacebook} className='w-32 -ml-10 flex justify-center items-center modal-btn px-3 border-dark-blue text-dark-blue'>
                                            <div className='w-[20px] mr-[10px]'>
                                                {/* <svg role="img" viewBox="0 0 24 24" fill='dark-blue' xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg> */}
                                                <img src={fbIcon} alt="Tutor Plus 香港首席導師協會 Facbook Sign In" />
                                            </div>

                                            <p className='mb-0 uppercase font-bold'>Log in </p>
                                        </button>
                                    </div>
                                    <div className='flex-1'>
                                        <button onClick={signInWithGoogle} className='w-32 -mr-10 flex justify-center items-center modal-btn px-3 border-red text-red'>
                                            <div className='w-[17px] mr-[10px]'>
                                                {/* <svg role="img" viewBox="0 0 24 24" fill="red" xmlns="http://www.w3.org/2000/svg"><title>Google</title><path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z" /></svg> */}
                                                <img src={googleIcon} alt="Tutor Plus 香港首席導師協會 Google Sign In" />
                                            </div>

                                            <p className='mb-0 uppercase font-bold'>Log in</p>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </form>

                    )}

                    {page > 0 && page < 3 && (
                        <div className="text-center px-[30px]">
                            <h3 className="uppercase text-dark-blue italic mb-3">Tutor Plus</h3>
                            <div className='text-xl mb-6'>導師註冊</div>


                        </div>
                    )}

                    {page === 1 && (
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className='text-center px-[30px]'>
                                <div className="mb-5">
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        className=""
                                        rules={{
                                            required: true,
                                            minLength: 5,
                                            maxLength: 15
                                        }}
                                        render={
                                            ({ field }) =>
                                                <TextField {...field} label="名稱*" variant="outlined" fullWidth InputProps={{
                                                    style: textFieldStyles.inputWrapper
                                                }} inputProps={{ style: textFieldStyles.input }} InputLabelProps={{ style: textFieldStyles.label }} />
                                        }
                                    />
                                    {errors.name?.type === 'required' && (
                                        <div className="text-red text-left mt-1 ml-2">請輸入名稱</div>
                                    )}
                                    {errors.name?.type === 'minLength' && (
                                        <div className="text-red text-left mt-1 ml-2">名稱至少要5個字或以上</div>
                                    )}
                                    {errors.name?.type === 'maxLength' && (
                                        <div className="text-red text-left mt-1 ml-2">名稱限15字以下</div>
                                    )}
                                </div>
                                <div className='mb-5'>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        className=""
                                        rules={{
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        }}
                                        render={
                                            ({ field }) =>
                                                <TextField {...field} label="電郵地址*" variant="outlined" fullWidth InputProps={{
                                                    style: textFieldStyles.inputWrapper
                                                }} inputProps={{ style: textFieldStyles.input }} InputLabelProps={{ style: textFieldStyles.label }} />
                                        }
                                    />
                                    {errors.email?.type === 'required' && (
                                        <div className="text-red text-left mt-1 ml-2">請輸入電郵地址</div>
                                    )}
                                    {errors.email?.type === 'pattern' && (
                                        <div className="text-red text-left mt-1 ml-2">請輸入有效電郵地址</div>
                                    )}
                                    {/* <TextField type="number" required error={phone.length !== 8} onChange={(e) => setPhone(e.target.value)} helperText={phoneErrorMessage} size="small" fullWidth id="standard-basic" label="電話號碼" variant="standard" /> */}
                                </div>

                                <div className="flex flex-col md:flex-row">
                                    <div className='relative mb-5'>
                                        <Controller
                                            name="password"
                                            control={control}
                                            defaultValue=""
                                            className=""

                                            rules={{
                                                required: true,
                                                maxLength: 20,
                                                minLength: 8
                                            }}
                                            render={
                                                ({ field }) =>
                                                    <TextField {...field} label="密碼*" variant="outlined" fullWidth type={showPassword2 ? "text" : "password"} InputProps={{
                                                        style: textFieldStyles.inputWrapper
                                                    }} inputProps={{ style: textFieldStyles.input }} InputLabelProps={{ style: textFieldStyles.label }} />
                                            }

                                        />
                                        {errors.password?.type === 'required' && (
                                            <div className="text-red text-left mt-1 ml-2">請輸入密碼</div>
                                        )}
                                        {errors.password?.type === 'maxLength' && (
                                            <div className="text-red text-left mt-1 ml-2">限20字以下</div>
                                        )}
                                        {errors.password?.type === 'minLength' && (
                                            <div className="text-red text-left mt-1 ml-2">至少要8個字或以上</div>
                                        )}



                                        <div className='absolute right-[10px] top-[22px] -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword2(s => !s)}>
                                            {!showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                </svg>
                                            )}
                                            {showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                    <div className='relative mb-5 md:ml-3'>
                                        <Controller
                                            name="password_repeat"
                                            control={control}
                                            defaultValue=""
                                            className=""

                                            rules={{
                                                required: true,
                                                validate: value =>
                                                    value === passwordRef.current || "The passwords do not match"
                                            }}
                                            render={
                                                ({ field }) =>
                                                    <TextField {...field} label="再次輸入密碼*" variant="outlined" fullWidth type={showPassword2 ? "text" : "password"} InputProps={{
                                                        style: textFieldStyles.inputWrapper
                                                    }} inputProps={{ style: textFieldStyles.input }} InputLabelProps={{ style: textFieldStyles.label }} />
                                            }

                                        />
                                        {errors.password?.type === 'required' && (
                                            <div className="text-red text-left mt-1 ml-2">請輸入密碼</div>
                                        )}
                                        {errors.password_repeat && <p className="text-red text-left mt-1 ml-2">{errors.password_repeat.message}</p>}



                                        <div className='absolute right-[10px] top-[22px] -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword2(s => !s)}>
                                            {!showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                </svg>
                                            )}
                                            {showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className='modal-btn text-grey border-grey mb-4 hover:text-white hover:bg-dark-blue hover:border-dark-blue'>


                                        註冊
                                    </button>
                                <p className="text-grey font-bold mb-4">OR</p>
                                <div className='grid grid-cols-2 gap-[10px]'>
                                    <div className='flex-1'>
                                        <button type="button" className='w-32 -ml-6 flex justify-center items-center modal-btn px-3 border-dark-blue text-dark-blue'>
                                            <div className='w-[20px] mr-[10px]'>
                                                <img src={fbIcon} alt="Tutor Plus 香港首席導師協會 Sign Up" />
                                            </div>

                                            <p className='mb-0 uppercase font-bold'>Sign Up </p>
                                        </button>
                                    </div>
                                    <div className='flex-1'>
                                        <button type="button" className='w-32 -mr-6 flex justify-center items-center modal-btn px-3 border-red text-red'>
                                            <div className='w-[17px] mr-[10px]'>
                                                <img src={googleIcon} alt="Tutor Plus 香港首席導師協會 Google Sign Up" />
                                            </div>

                                            <p className='mb-0 uppercase font-bold'>Sign Up</p>
                                        </button>
                                    </div>

                                </div>



                                {/* <div className='relative mb-[20px]'>
                                        <TextField required onChange={(e) => { setConfirmPassword2(e.target.value) }} size="small" fullWidth type={showPassword2 ? "text" : "password"} id="fullWidth" classes="relative" label="重新輸入密碼" variant="standard" error={password2.length > 12 || confirmPassword2.length > 12 || password2 !== confirmPassword2} helperText={pwErrorMessage} />
                                        <div className='absolute right-[10px] top-[28px] -translate-y-1/2 cursor-pointer' onClick={() => setShowPassword2(s => !s)}>
                                            {!showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                </svg>
                                            )}
                                            {showPassword2 && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            )}
                                        </div>

                                    </div> */}


                                <div className='flex items-center justify-between'>

                                    <button onClick={() => { setPage(0); reset(); }} className='w-fit rounded-2xl text-grey px-3 py-1 border-grey border-[1px] mt-10'>


                                        <p className='mb-0 text-xs uppercase font-bold'>返回登入</p>
                                    </button>





                                </div>

                            </div>
                        </form>

                    )}
                    {/* {page === 2 && (
                        <div className="text-center">
                            <p>恭喜閣下，成為本會新會員！</p>
                            <button onClick={() => { setPage(0) }} className='w-fit px-[8px] h-[36px] text-[16px] border-[1px] border-[#000] rounded-[5px]'>
                                <p className='mb-0 uppercase font-bold'>返回登錄</p>
                            </button>
                        </div>
                    )} */}
                    {page === 3 && (
                        <form noValidate onSubmit={handleSubmitReset(onSubmitReset)}>
                            <div className="text-center p-5 pb-0">
                                <h5 className="mb-5">請輸入電郵地址</h5>
                                <div className="mb-8">
                                    <Controller
                                        name="email_reset"
                                        control={controlReset}
                                        defaultValue=""
                                        className=""
                                        rules={{
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        }}
                                        render={
                                            ({ field }) =>
                                                <TextField {...field} label="電郵地址*" variant="outlined" size="small" fullWidth InputProps={{
                                                    style: textFieldStyles.inputWrapper
                                                }} inputProps={{ style: textFieldStyles.input }} />
                                        }
                                    />
                                    {errorsReset.email_reset?.type === 'required' && (
                                        <div className="text-red text-left mt-1 ml-2">請輸入電郵地址</div>
                                    )}
                                    {errorsReset.email_reset?.type === 'pattern' && (
                                        <div className="text-red text-left mt-1 ml-2">請輸入有效電郵地址</div>
                                    )}
                                </div>
                                <button type="submit" className='modal-btn text-grey border-grey hover:text-white hover:bg-dark-blue hover:border-dark-blue'>
                                    提交
                                </button>
                            </div>
                        </form>

                    )}

                </Box>
            </Modal>


        </div>
    )
}
