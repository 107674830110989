import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { auth } from '../../firebase';


export default function PrivateRouteLogin({children}) {
    
    const [isLogin, setLogin] = useState(true);
    
        onAuthStateChanged(auth, (currentUser) => {
            
            setLogin(currentUser)
            
        })
    
    return isLogin ? children : <Navigate to="/"/>;
    
}
