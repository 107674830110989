import React, { useState, useEffect } from 'react'
import { getAllCases } from '../firebase';
import logo_5 from './../images/最新個案.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
export default function CasePreview() {
    const [isMobile, setIsMobile] = useState(null)
    const [cases, setCases] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        getAllCases().then((result) => {
            setCases(result)
            setIsLoading(false)
        });
    }, [])
    const detectWidth = () => {
        if (window.innerWidth < 600) {
            setIsMobile(true)
        }else {
            setIsMobile(false)
        }
    }
    window.addEventListener("resize", detectWidth)


    const displayCasesMobile = cases.slice(0, 5).map(({ icon_link, case_num, grade, type, gender, subject, location, district, fee, time, period, tutor, tutor_edu, post_date, num, remarks, id }, index) => (


            <SwiperSlide key={case_num}>
                <a href={`/showcase#${case_num}`} className="block transition-all duration-300 md:hover:scale-105 ">
                    {/*
                    <div className='flex flex-col max-w-sm mx-auto'>
                        <div className='aspect-[1/1]'>
                            <img className='object-cover h-full' src={`/${subject[0].trim()}.jpg`} alt='Tutor Plus 香港首席導師協會' />
                        </div>
                        <div className='p-4 md:p-2 md:px-5 bg-brown-yellow flex flex-row justify-between text-base md:flex-col lg:flex-row'>
                            <div className='flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <p className='text-xl font-bold mb-0 ml-1'>{district}</p>

                            </div>
                            <p className='text-base font-bold mb-0 text-center lg:text-left'>${fee}/hr</p>

                        </div>
                        <div className='bg-white p-5 py-3'>
                            <p className='text-lg font-normal mb-1'>年級：{grade} ({gender})</p>
                            <p className='text-lg font-normal mb-0'>科目：{subject}</p>
                            <p className='text-lg font-normal mb-1'>堂數：每星期{period}堂</p>
                            <p className='text-lg font-normal mb-1'>時數：{time}hr</p>
                            <p className='text-lg font-normal mb-1'>地點：{district} {location}</p>
                            <p className={`text-lg font-normal mb-1`}>要求：<span className={`${tutor === '男導師' && 'text-light-blue'} ${tutor === '女導師' && 'text-[#ec59c3]'} ${tutor === '男女導師均可' && 'text-[#3aba43]'}`}>{tutor}</span></p>
                            <div className='flex justify-between items-center flex-col lg:flex-row mt-3'>
                                <p className='text-sm mb-0'>{post_date}</p>
                                <p className='text-sm mb-0'>#{case_num}</p>
                            </div>
                        </div>

                    </div>
                    */}
                    <div className='bg-white block md:hidden'>
                        <div className='p-4 md:p-0 flex w-full md:w-auto '>
                            <div className='min-w-[105px]'>
                                <img className="w-[130px] md:w-[105px] aspect-[1/1] rounded-[4px] md:rounded-none object-cover" src={`/${subject[0].trim()}.jpg`} alt="icon_link" ></img>
                            </div>
                            <div className='pl-[10px] md:pl-[30px] flex justify-center md:items-center'>
                                <div className='md:w-[100px] lg:w-[280px] md:py-[10px] md:border-r-[1px] border-[#ccc] pr-[20px] md:pr-0'>
                                    <div className='text-[16px] font-bold hidden md:block'>個案編號 {case_num}</div>
                                    <div className='text-[16px] font-bold text-showcase-theme'>{grade} {subject}</div>
                                    <div className='block md:hidden'>
                                        <p className='mb-[5px] text-[14px]'>學生人數: {gender} - {num}名</p>
                                        <p className='mb-[5px] text-[14px]'>補習地點: {district} {location}</p>
                                        <p className='mb-[5px] text-[14px]'>家長要求: {tutor}</p>
                                        <p className='text-showcase-theme mb-0'>個案編號: {case_num}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='flex justify-between w-full px-4 pb-[10px] md:hidden'>
                            <span className='text-[13px] text-[#999]'>登記時間:{post_date}</span>
                            <span className='text-[15px] font-bold tracking-[1px]'>${fee}/hr</span>
                        </div>
                    </div>


                </a>
            </SwiperSlide>





    ))
    const displayCasesDesktop = cases.slice(0, 5).map(({ icon_link, case_num, grade, type, gender, subject, location, district, fee, time, period, tutor, tutor_edu, post_date, num, remarks, id }, index) => (
        <a key={case_num} href={`/showcase#${case_num}`} className="transition-all duration-300 md:hover:scale-105 block">
                <div className='flex flex-col hidden md:flex'>
                    <div className='aspect-[1/1]'>
                        <img className='object-cover h-full' src={`/${subject[0].trim()}.jpg`} alt='Tutor Plus 香港首席導師協會' />
                    </div>
                    <div className='p-4 md:p-2 md:px-5 bg-brown-yellow flex flex-row justify-between text-base md:flex-col lg:flex-row'>
                        <div className='flex items-center justify-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <p className='text-xl font-bold mb-0 ml-1'>{district}</p>

                        </div>
                        <p className='text-base font-bold mb-0 text-center lg:text-left'>${fee}/hr</p>

                    </div>
                    <div className='bg-white p-5 py-3'>
                        <p className='text-lg font-normal mb-1'>年級：{grade} ({gender})</p>
                        <p className='text-lg font-normal mb-0'>科目：{subject}</p>
                        <p className='text-lg font-normal mb-1'>堂數：每星期{period}堂</p>
                        <p className='text-lg font-normal mb-1'>時數：{time}hr</p>
                        <p className='text-lg font-normal mb-1'>地點：{district} {location}</p>
                        <p className={`text-lg font-normal mb-1`}>要求：<span className={`${tutor === '男導師' && 'text-light-blue'} ${tutor === '女導師' && 'text-[#ec59c3]'} ${tutor === '男女導師均可' && 'text-[#3aba43]'}`}>{tutor}</span></p>
                        <div className='flex justify-between items-center flex-col lg:flex-row mt-3'>
                            <p className='text-sm mb-0'>{post_date}</p>
                            <p className='text-sm mb-0'>#{case_num}</p>
                        </div>
                    </div>

                </div>
                <div className='bg-white block md:hidden'>
                    <div className='p-4 md:p-0 flex w-full md:w-auto '>
                        <div className='min-w-[105px]'>
                            <img className="w-[130px] md:w-[105px] aspect-[1/1] rounded-[4px] md:rounded-none object-cover" src={`/${subject[0].trim()}.jpg`} alt="Tutor Plus 香港首席導師協會" ></img>
                        </div>
                        <div className='pl-[10px] md:pl-[30px] flex justify-center md:items-center'>
                            <div className='md:w-[100px] lg:w-[280px] md:py-[10px] md:border-r-[1px] border-[#ccc] pr-[20px] md:pr-0'>
                                <div className='text-[16px] font-bold hidden md:block'>個案編號 {case_num}</div>
                                <div className='text-[16px] font-bold text-showcase-theme'>{grade} {subject}</div>
                                <div className='block md:hidden'>
                                    <p className='mb-[5px] text-[14px]'>學生人數: {gender} - {num}名</p>
                                    <p className='mb-[5px] text-[14px]'>補習地點: {district} {location}</p>
                                    <p className='mb-[5px] text-[14px]'>家長要求: {tutor}</p>
                                    <p className='text-showcase-theme mb-0'>個案編號: {case_num}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='flex justify-between w-full px-4 pb-[10px] md:hidden'>
                        <span className='text-[13px] text-[#999]'>登記時間:{post_date}</span>
                        <span className='text-[15px] font-bold tracking-[1px]'>${fee}/hr</span>
                    </div>
                </div>


            </a>
    ))

    return (
        <div className='section-container mx-auto'>
            <h2 className='md:-mt-0 -mt-4 text-2xl md:text-3xl flex items-center text-grey mb-8'>
                <img className='w-6 md:w-8 aspect-[1/1] mr-2 md:mr-4' src={logo_5} alt="Tutor Plus 香港首席導師協會" />
                最新個案
            </h2>
            {isLoading &&
                <div className='flex justify-center items-center h-[500px]'>
                    <div className='loader'></div>
                </div>
            }
            {isMobile ? (
                <Swiper
                className='bg-white'
                modules={[Pagination, A11y]}

                navigation
                pagination={{ clickable: true }}
                spaceBetween={30}
                slidesPerView={1}
                loop={true}

            >

                {displayCasesMobile}
            </Swiper>
            )
            :
            (
                <div className='grid md:grid-cols-5 gap-7 md:gap-y-16 mt-16'>

                    {displayCasesDesktop}


                </div>
            )}



            <a href="/showcase" className='md:mr-0 home-cta bg-[#053e68] mb-8 mt-12 hover:opacity-80 hover:bg-[#053e68] text-xl px-10 py-4'>查看更多</a>
        </div>
    )
}
