import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Header from "./components/Header"
import Footer from "./components/Footer"
import FloatingBtnForm from './components/FloatingBtnForm';
import Home from "./pages/home";
import Showcase from './pages/showcase';
import Tutorzone from './pages/tutorzone';
import Tutorfee from './pages/tutorfee';
import Parentzone from './pages/parentzone';
import Tuitionfee from './pages/tuitionfee';
import Contact from './pages/contact';
import PrivateRouteLogin from './components/PrivateRoute/PrivateRouteLogin';
import RegisterForm from './pages/registerForm';
import TutorForm from './pages/Stepform'
import Profile from './pages/profile';
import Error from "./pages/error";
import { Toaster } from 'react-hot-toast';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { putAuthState } from './features/loginSlice';





function App() {
  const auth = getAuth();
  const dispatch = useDispatch()
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(putAuthState({
        auth: user

      }))
      /* setVerified('users', user?.email, user?.emailVerified) */
    } else {
      dispatch(putAuthState({
        auth: null

      }))

    }



  });

  return (
    <BrowserRouter>
      <Toaster />
      <Header />
      <FloatingBtnForm />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/showcase" element={<Showcase />} />
        <Route path="/parentzone" element={<Parentzone />} />
        <Route path="/tuitionfee" element={<Tuitionfee />} />
        <Route path="/registerform" element={<RegisterForm />} />
        <Route path="/tutorzone" element={<Tutorzone />} />
        <Route path="/tutorfee" element={<Tutorfee />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/tutorinfo" element={
          <PrivateRouteLogin>
            <TutorForm />

          </PrivateRouteLogin>
        } />
        <Route path="/profile" element={
          <PrivateRouteLogin>
            <Profile />

          </PrivateRouteLogin>
        } />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
