import React from 'react'
import whatsappIcon from './../images/WTS-V1.png'
import whatsappIcon2 from './../images/WTS-V2.png'
import igIcon from './../images/IG-V1.png'
import fbIcon from './../images/FB-V1.png'
import { Helmet } from "react-helmet";

export default function contact() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://www.tutorplushk.com/contactus" />
            </Helmet>
            <section className=' max-w-5xl mx-auto px-7 py-14'>
                <div className='py-8 border-b-2 border-grey'>
                    <h2 className='font-bold text-[#333333] text-3xl'>聯絡我們</h2>
                </div>
                <div className='py-20 text-center'>
                    <div className='pb-20'>
                        <div className='text-xl mb-5'>WhatsApp／熱線</div>
                        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send/?phone=85256295964&text&type=phone_number&app_absent=0">
                        <div className='text-lg inline-flex items-center justify-center'>

                            <img className='w-[25px] h-[25px] mr-3' src={whatsappIcon} alt="Tutor Plus 香港首席導師協會 Whatsapp" />
                            5629 - 5964
                        </div>
                        </a>

                    </div>
                    <div className=''>
                        <div className='text-xl mb-5'>社交平台</div>
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/hktutorplus">
                            <div className='text-lg mb-3 inline-flex items-center justify-center'>
                                <img className='w-[25px] h-[25px] mr-3' src={fbIcon} alt="Tutor Plus 香港首席導師協會 Facebook" />
                                Tutor Plus香港首席導師協會
                            </div>
                        </a>
                    </div>

                    <div className='pb-20'>
                        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/tutor_plus_hk/">
                            <div className='text-lg inline-flex items-center justify-center'>
                                <img className='w-[25px] h-[25px] mr-3' src={igIcon} alt="Tutor Plus 香港首席導師協會 Instagram" />
                                tutor_plus_hk
                            </div>
                        </a>
                    </div>
                    <div className='pb-20'>
                        <div className='text-xl mb-5'>群組</div>
                        <div className='text-lg mb-3 flex items-center justify-center'>
                            <img className='w-[18px] h-[18px] mr-3' src={whatsappIcon2} alt="Tutor Plus 香港首席導師協會 Whatsapp Group" />
                            WhatsApp Group
                            </div>
                        <div className='text-sm'>(用於發放最新補習個案資訊)</div>
                    </div>

                    <div className='pb-20'>
                        <div className='text-xl mb-5'>辦公時間</div>
                        <div className='text-lg mb-3'>星期一至五<span className='ml-2'> 9:00-23:00</span></div>
                        <div className='text-lg'>星期六／日<span className='ml-2'> 9:00-20:00</span></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
