import React from 'react'
import Carousel from '../components/Carousel/Slider'
import { Helmet } from "react-helmet";

import SmallCard from '../components/SmallCard';
import logo_1 from './../images/收費透明.png'
import logo_2 from './../images/時間彈性.png'
import logo_3 from './../images/大量補習個案.png'
import logo_4 from './../images/極速配對.png'

import CasePreview from '../components/CasePreview';

export default function home() {


  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.tutorplushk.com/" />
      </Helmet>
      <Carousel />

      <section className='section-padding'>
        <div className='section-container'>
          <h2 className='heading py-4 text-[#333333]'>為何選擇我們</h2>
          <SmallCard />
          <a href="/registerForm" className='home-cta bg-[#053e68] mb-8 mt-12 hover:opacity-80 hover:bg-[#053e68] text-xl px-10 py-4'>立即配對</a>
        </div>
      </section>

      <section className='section-padding bg-[#efefef]'>
        <CasePreview />
      </section>

      <section className='section-padding'>
        <div className='section-container'>
          <h2 className='heading text-[#333333] pt-4'>加入我們成為導師</h2>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-10 mt-20 mx-auto'>
            <div className='flex flex-col items-center'>
              <div className='bg-[#efefef] rounded-full flex justify-center items-center w-24 aspect-[1/1] md:aspect-auto md:w-auto md:p-6 lg:p-12 '>
                <img className='aspect-[1/1] absolute md:relative w-20 p-4 md:w-auto min-w-[80px] md:min-w-[100px] max-w-[120px]' src={logo_1} alt="Tutor Plus 香港首席導師協會" />
              </div>
              <h3 className='text-xl md:text-2xl mt-4 text-[#333333] text-center'>收費透明</h3>
            </div>
            <div className='flex flex-col items-center'>
              <div className='bg-[#efefef] rounded-full flex justify-center items-center w-24 aspect-[1/1] md:aspect-auto md:w-auto md:p-6 lg:p-12 '>
                <img className='aspect-[1/1] absolute md:relative w-20 p-4 md:w-auto min-w-[80px] md:min-w-[100px] max-w-[120px]' src={logo_2} alt="Tutor Plus 香港首席導師協會" />
              </div>
              <h3 className='text-xl md:text-2xl  mt-4 text-[#333333] text-center'>時間彈性</h3>
            </div>
            <div className='flex flex-col items-center'>
              <div className='bg-[#efefef] rounded-full flex justify-center items-center w-24 aspect-[1/1] md:aspect-auto md:w-auto md:p-6 lg:p-12 '>
                <img className='aspect-[1/1] absolute md:relative w-20 p-4 md:w-auto min-w-[80px] md:min-w-[100px] max-w-[120px]' src={logo_3} alt="Tutor Plus 香港首席導師協會" />
              </div>
              <h3 className='text-xl md:text-2xl  mt-4 text-[#333333] text-center'>大量補習個案</h3>
            </div>
            <div className='flex flex-col items-center'>
              <div className='bg-[#efefef] rounded-full flex justify-center items-center w-24 aspect-[1/1] md:aspect-auto md:w-auto md:p-6 lg:p-12 '>
                <img className='aspect-[1/1] absolute md:relative w-20 p-4 md:w-auto min-w-[80px] md:min-w-[100px] max-w-[120px]' src={logo_4} alt="Tutor Plus 香港首席導師協會" />
              </div>
              <h3 className='text-xl md:text-2xl  mt-4 text-[#333333] text-center'>極速配對</h3>
            </div>
          </div>
          <a href="/registerForm" className='home-cta bg-[#053e68] mb-8 mt-24 hover:opacity-80 hover:bg-[#053e68] text-xl px-10 py-4'>立即登記</a>
        </div>
      </section>


    </div>
  )
}
