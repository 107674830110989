import React from 'react'
import icon1 from './../images/快速配對.jpg'
import icon2 from './../images/星級導師.jpg'
import icon3 from './../images/免中介費.jpg'
import icon4 from './../images/專人跟進.jpg'
import icon5 from './../images/多元化課程.jpg'
import icon6 from './../images/提供筆記.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SmallCard(props) {
  return (
    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 gap-y-6 md:gap-y-16 mx-auto`}>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon1} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>快速配對</h3>
                <div className='text-lg px-4'>本平台設有即時配對系統，最快即日可完成配對，立即開始課程。</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon2} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>星級導師</h3>
                <div className='text-lg px-4'>本平台有大量5**導師、現職教師、全
職以及海外回流導師，能為學生配對
最適合的導師人選。
</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon3} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>免中介費</h3>
                <div className='text-lg px-4'>家長無需繳付任何中介費用，學費
亦會根據協議而定，絕無隱藏和附
加收費。</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon4} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>專人跟進</h3>
                <div className='text-lg px-4'>設有專員線上解答家長/學生的問題
，並會跟進個案，務求提供最優質
的服務。</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon5} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>多元化課程 </h3>
                <div className='text-lg px-4'>我們除了上門補習外，還提供各類證
書考試輔導、樂器/繪畫課程、小組
補習、視像補習等服務。</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className=''>
                <LazyLoadImage className='aspect-[16/10] object-cover' src={icon6} alt='Tutor Plus 香港首席導師協會'></LazyLoadImage>
              </div>
              <div className='p-4'>
                <h3 className='text-dark-blue font-bold text-center mb-4 mt-2 text-2xl'>提供筆記</h3>
                <div className='text-lg px-4'>我們提供大量筆記，學生成功配對後
可向我們免費索取。
</div>
              </div>
            </div>

          </div>
  )
}
