import React from 'react'
import RegisterForm from '../components/StepForm/RegisterForm'
import { Helmet } from "react-helmet";

export default function registerForm() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.tutorplushk.com/registerform" />
      </Helmet>
      <RegisterForm />
    </div>
  )
}
